import { MdEdit } from 'react-icons/md';
import { Card, Col, Row } from 'reactstrap';

type Props = {
  vmrDetails: {
    [key: string]: any;
  };
  handleIdToggle: VoidFunction;
};

const VehicleIdentificationInfo = (props: Props) => {
  const { vmrDetails, handleIdToggle } = props;

  return (
    <div>
      <Card body className=' p-2 '>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='text-blue fw-semibold'>Descriptions</div>
                <div
                  className='me-3 d-flex align-items-center cursor-pointer px-2 py-1 rounded gap-1'
                  style={{
                    color: '#04a7a7',
                    backgroundColor: 'rgba(4, 167, 167, 0.1)',
                  }}
                  onClick={handleIdToggle}
                >
                  <div>
                    <MdEdit size={15} />
                  </div>{' '}
                  <div>Edit</div>
                </div>
              </div>
              <Row className='w-100 mt-2'>
                <Col className='mt-2' md={6}>
                  {vmrDetails.description && (
                    <div className='mt-1 fw-semibold border p-2 rounded '>
                      {vmrDetails?.description}
                    </div>
                  )}
                </Col>
                <Col className='mt-2' md={3}>
                  <div>
                    <small className='text-grey'>Urgency</small>
                  </div>
                  <div className='mt-1 fw-semibold capitalize'>
                    {vmrDetails?.urgency}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default VehicleIdentificationInfo;
