import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { Modal, ModalBody, Table } from 'reactstrap';
import EmptyPage from '../../../components/EmptyPage';
import NewFilter from '../../../components/filter/NewFilter';
import ExportComponent from '../../../components/shared/exportComponent';
import FilterComponent from '../../../components/shared/filterComponent';
import WaitingLoader from '../../../components/shared/waitingLoader';
import PaginationComponent from '../../../components/tablesComponents/paginationComponent';
import SeearchComponent from '../../../components/tablesComponents/searchComponent';
import TableDataCounter from '../../../components/tablesComponents/tableDataCounter';
import queryKeys from '../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';
import { calculatePostDate } from '../../../helpers/dateTimeHelper';
import { usePaginationHelper } from '../../../helpers/paginationHelper';
import { useQueryStringHelper } from '../../../helpers/queryStringHelper';
import { useUsersApiService } from '../../../services/usersService';
import { ObjectType } from '../../../types/common';
import { getStatusFromReferralType } from '../users/helpers/strings';
import ReferralModalBody from './components/ReferralModalBody';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setReferralType: Dispatch<SetStateAction<string>>;
  activeTab: string;
  referralType: string;
};
const ReferralLog = (props: Props) => {
  const { activeTab,  referralType } = props;
  const [toggled, setToggled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { getPageNoFromQueryString } = useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const [keyword, setKeyword] = useState('');
  const [viewedReferral, setViewedReferral] = useState<
    ObjectType | undefined
  >();
  const [status, setStatus] = useState('');
  const { getReferralToast } = useUsersApiService();
  const { setBreadCrumbData, } =
    useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData(['Referral', `${activeTab}`]);
  }, [activeTab]);

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
    status,
  });

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const handleModalToggle = () => {
    setModalOpen((prev) => !prev);
  };
  const handleToggle = () => {
    setToggled(!toggled);
  };

  const { isLoading, data: referral } = useQuery(
    [queryKeys.referral, keyword, currentPage, filterState],
    () =>
      getReferralToast({
        component: '',
        q: keyword,
        start_date: startDate,
        page: currentPage,
        end_date: endDate,
        status: status || getStatusFromReferralType(referralType),
      }),
    {
      select: (data) => data?.data || [],
      // enabled: false,
      keepPreviousData: true,
    }
  );
  const { data: referralCount } = useQuery(
    [queryKeys.referralCount, keyword, currentPage, filterState],
    () =>
      getReferralToast({
        component: 'count',
        q: keyword,
        page: currentPage,
        start_date: startDate,
        end_date: endDate,
        status: status || getStatusFromReferralType(referralType),
      }),
    {
      select: (data) => data?.data?.total || 0,
      // enabled: false,
      keepPreviousData: true,
    }
  );
  const referralTypeOptions = [
    { label: 'All', value: '' },
    { label: 'Confirmed', value: '0' },
    { label: 'Pending', value: '1' },
  ];
  const filterOptions = [
    {
      title: 'Status',
      options: referralTypeOptions,
      currentValue: status,
      onChange: setStatus,
      name: 'statusType',
    },
  ];

  const onApply = () => {
    setFilterState({ startDate, endDate, status });
    handleToggle();
  };

  if (isLoading && !referral) {
    return <WaitingLoader />;
  }
  return (
    <>
      <div className='d-flex justify-content-between text-nowrap flex-wrap'>
        <div className='d-flex align-items-center'>
          <div className=''>
            <div className='d-flex align-items-center  '>
              <div className='fw-bolder fs-5 capitalize'>{activeTab}</div>
            </div>
          </div>
          <div className='ms-4'>
            {<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='me-3'>
            {referral?.length > 0 && <ExportComponent />}
          </div>

          <div className='me-3'>
            <NewFilter
              direction={'start'}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              options={filterOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />
          </div>
        </div>
      </div>

      <div className='mt-2'>
        {referral?.length === 0 && <EmptyPage text='No Referral' />}
        {referral?.length > 0 && (
          <Table className='table-hover'>
            <thead className=''>
              <tr className="">
                <th className=''>Date and Time</th>
                <th className=''>Referrer Name</th>
                <th className=''>Referrer Phone No.</th>
                <th className=''>Referee Name</th>
                <th className=''>Referee Phone No.</th>
                <th className=''>Status</th>
              </tr>
            </thead>

            <tbody>
              {referral?.map((item: any, index: number) => (
                <tr
                  className='cursor-pointer capitalize trhover'
                  key={index}
                  onClick={() => {
                    setViewedReferral(item);
                    handleModalToggle();
                  }}
                >
                  <td>{calculatePostDate(item?.createdAt)}</td>
                  <td>
                    {item?.referrer_data?.first_name}{' '}
                    {item?.referrer_data?.last_name}
                  </td>
                  <td>{item?.referrer_data?.phone_number || 'N|A'}</td>
                  <td>
                    {item?.first_name} {item?.last_name}
                  </td>
                  <td>{item?.phone_number || 'N|A'}</td>
                  <td
                    className={
                      item?.credited
                        ? 'fw-bold text-success'
                        : 'fw-bold text-warning'
                    }
                  >
                    {item?.credited ? 'Confirmed' : 'Pending'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {referral?.length > 0 && (
          <div className='d-flex justify-content-between align-items-center mt-4 flex-wrap'>
            <div className='mt-2'>
              <TableDataCounter
                item={referral}
                currentPage={currentPage}
                totalCount={referralCount}
              />
            </div>
            <div className='mt-2'>
              <PaginationComponent
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={referralCount}
                onChange={onPaginate}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        size='md'
        isOpen={modalOpen}
        toggle={handleToggle}
        className='modal-dialog-centered'
        style={{ width: '90%' }}
        backdrop='static'
      >
        <ModalBody className='p-4'>
          <ReferralModalBody
            handleToggle={handleModalToggle}
            viewedReferral={viewedReferral}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReferralLog;
