// import { lazy } from "react";
import OperationsOverview from "../../Pages/others/operations/overview";
// import BusinessPerformance from "../../Pages/others/operations/businessPerformance";
// import TripPerformance from "../../Pages/others/operations/tripPerformance";
// import VehiclePerformance from "../../Pages/others/operations/vehiclePerformance";
// import DriverPerformance from "../../Pages/others/operations/driverPerformance";
import DriverWatchlist from "../../Pages/others/operations/driverWatchlis";
// import RevenuePerformance from "../../Pages/others/operations/revenuePerformance";

//wallet

export default {
  path: "/operations",
  title: "Operations",
  children: [
    {
      path: "",
      component: OperationsOverview,
      title: "Operations",
      permission: "Wallet",
    },
    // {
    //   path: "/business-performance",
    //   component: BusinessPerformance,
    //   title: "Operations",
    //   permission: "Business Performance",
    // },
    // {
    //   path: "/trip-performance",
    //   component: TripPerformance,
    //   title: "Operations",
    //   permission: "Trip Performance",
    // },
    // {
    //   path: "/vehicle-performance",
    //   component: VehiclePerformance,
    //   title: "Operations",
    //   permission: "Vehicle Performance",
    // },
    // {
    //   path: "/revenue-performance",
    //   component: RevenuePerformance,
    //   title: "Operations",
    //   permission: "Revenue Performance",
    // },
    // {
    //   path: "/driver-performance",
    //   component: DriverPerformance,
    //   title: "Operations",
    //   permission: "Vehicle Performance",
    // },
    {
      path: "/driver-wathclist",
      component: DriverWatchlist,
      title: "Operations",
      permission: "Driver Watchlist",
    },
  ],
};

