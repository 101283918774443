import { ObjectType } from '../types/common';

export const getDataFromDateType = ({
  dateType = '',
  startDate = '',
  endDate = '',
  data,
}: ObjectType) => {
  const dailyInitial = new Array(31).fill(0);
  const monthlyInitial = new Array(12).fill(0);

  if (dateType === 'daily') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.date_value.split('-')[2];
        dailyInitial[Number(usedIndex) - 1] = item.total;
      });
    return dailyInitial;
  }

  if (dateType === 'monthly') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.date_value.split('-')[1];
        dailyInitial[Number(usedIndex) - 1] = item.total;
      });
    return dailyInitial;
  }

  if (dateType === 'yearly') {
    const startYear = startDate.split('-')[0];
    const endYear = endDate.split('-')[0];
    const years = Array.from(
      { length: parseInt(endYear) + 1 - Number(startYear) },
      (val, index) => Number(startYear) + index
    );
    const yearlyInitial = new Array(years.length).fill(0);

    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const indexInYears = years.indexOf(
          Number(item.date_value.split('-')[0])
        );
        yearlyInitial[indexInYears] = item.total;
      });
    return yearlyInitial;
  }
};

export const getDataFromDataType = ({ dateType = '', data }: ObjectType) => {
  const dailyInitial = new Array(31).fill(0);
  const monthlyInitial = new Array(12).fill(0);

  if (dateType === 'monthly') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date.split('-')[1];
        dailyInitial[Number(usedIndex) - 1] = item?.total;
      });
    return dailyInitial;
  }
};
export const getDataFromRevenueDataType = ({
  dateType = '',
  startDate = '',
  endDate = '',
  data,
}: ObjectType) => {
  const dailyInitial = new Array(31).fill(0);

  if (dateType === 'daily') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date.split('-')[2];
        const total = Object.keys(item).reduce((acc, key) => {
          if (key !== 'created' && key !== 'group_date') {
            acc += Math.round(item[key]);
          }
          return acc;
        }, 0);
        dailyInitial[Number(usedIndex) - 1] = total;
      });
    return dailyInitial;
  }

  if (dateType === 'monthly') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date.split('-')[1];
        const total = Object.keys(item).reduce((acc, key) => {
          if (key !== 'created' && key !== 'group_date') {
            acc += Math.round(item[key]);
          }
          return acc;
        }, 0);
        dailyInitial[Number(usedIndex) - 1] = total;
      });
    return dailyInitial;
  }

  if (dateType === 'yearly') {
    const startYear = startDate.split('-')[0];
    const endYear = endDate.split('-')[0];
    const years = Array.from(
      { length: parseInt(endYear) + 1 - Number(startYear) },
      (val, index) => Number(startYear) + index
    );
    const yearlyInitial = new Array(years.length).fill(0);

    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const indexInYears = years.indexOf(
          Number(item.gorup_date.split('-')[0])
        );
        yearlyInitial[indexInYears] = item.total;
      });
    return yearlyInitial;
  }
};

export const getDataFromStakeholderDataType = ({
  dateType = '',
  data,
}: ObjectType) => {
  const dailyInitial = new Array(31).fill(0);
  const monthlyInitial = new Array(12).fill(0);

  if (dateType === 'monthly') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date;
        dailyInitial[Number(usedIndex) - 1] = item?.total;
      });
    return dailyInitial;
  }
};
export const getDataFromDriverDataType = ({
  dateType = '',
  data,
}: ObjectType) => {
  const dailyInitial = new Array(31).fill(0);
  const monthlyInitial = new Array(12).fill(0);

  if (dateType === 'monthly') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?._id?.month;
        dailyInitial[Number(usedIndex) - 1] = item?.total;
      });
    return dailyInitial;
  }
};

export const getDataFromReferralDataType = ({
  dateType = '',
  data,
  type = '',
}: ObjectType) => {
  const dailyInitial = new Array(31).fill(0);
  const monthlyInitial = new Array(12).fill(0);

  if (dateType === 'monthly' && type === 'total') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date;
        dailyInitial[Number(usedIndex) - 1] = item?.total_count;
      });
    return dailyInitial;
  } else if (dateType === 'monthly' && type === 'pending') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date;
        dailyInitial[Number(usedIndex) - 1] = item?.total_pending;
      });
    return dailyInitial;
  } else if (dateType === 'monthly' && type === 'confirmed') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date;
        dailyInitial[Number(usedIndex) - 1] = item?.total_confirmed;
      });
    return dailyInitial;
  }
};
export const getDataFromEmergencyDataType = ({
  dateType = '',
  data,
  type = '',
}: ObjectType) => {
  const dailyInitial = new Array(31).fill(0);
  const monthlyInitial = new Array(12).fill(0);

  if (dateType === 'monthly' && type === 'total') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.month;
        dailyInitial[Number(usedIndex) - 1] = item?.total;
      });
    return dailyInitial;
  } else if (dateType === 'monthly' && type === 'resolved') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.month;
        dailyInitial[Number(usedIndex) - 1] = item?.total_resolved;
      });
    return dailyInitial;
  } else if (dateType === 'monthly' && type === 'unresolved') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.month;
        dailyInitial[Number(usedIndex) - 1] = item?.total_unresolved;
      });
    return dailyInitial;
  }
};

export const getDataFromServicePaymentDataType = ({
  dateType = '',
  data,
  type = '',
}: ObjectType) => {
  const dailyInitial = new Array(31).fill(0);
  const monthlyInitial = new Array(12).fill(0);

  if (dateType === 'monthly' && type === 'dVerification') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date;
        dailyInitial[Number(usedIndex) - 1] = item?.total_dVerification;
      });
    return dailyInitial;
  } else if (dateType === 'monthly' && type === 'pending') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date;
        dailyInitial[Number(usedIndex) - 1] = item?.pending_amount;
      });
    return dailyInitial;
  } else if (dateType === 'monthly' && type === 'unsuccessful') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date;
        dailyInitial[Number(usedIndex) - 1] = item?.failed_amount;
      });
    return dailyInitial;
  } else if (dateType === 'monthly' && type === 'successful') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date;
        dailyInitial[Number(usedIndex) - 1] = item?.success_amount;
      });
    return dailyInitial;
  } else if (dateType === 'monthly' && type === 'equity') {
    data &&
      data.length > 0 &&
      data.forEach((item: ObjectType) => {
        const usedIndex = item?.group_date;
        dailyInitial[Number(usedIndex) - 1] = item?.total_equity;
      });
    return dailyInitial;
  }
};
