import useOperationsSettings from "../../../../../../constants/operations.tsx";
import { ObjectType } from "../../../../../../types/common.tsx";
import OneColumn from "../OneColumn.tsx";
import Title from "../title";

type Props = {
  tripOperationsPerformance: ObjectType;
};

const TripPerformance = (props: Props) => {
  const { tripOperationsPerformance } = props;
  const getItems = (key: string) => tripOperationsPerformance?.map((item: ObjectType) => item[key]) || "";

  const { vehicleDailyExp, tripsDailyExp, minumExpectation } = useOperationsSettings();

  const calcPerc = () =>
    tripOperationsPerformance?.map((item: ObjectType) =>
      item?.total_revenue && item?.vehicle_log?.total_assigned ? ((item?.total_revenue / (vehicleDailyExp * minumExpectation * item?.vehicle_log?.total_assigned)) * 100).toFixed(1) + "%" : 0 + "%"
    );

  return (
    <div className="px-4 " style={{ minWidth: 600, background: "#f7f4f4" }}>
      <Title text="Trips Performance" />
      {/* Next lne points to each row */}
      <div className="d-flex text-nowrap justify-content-between">
        <OneColumn subTitle={"Requested"} items={getItems("total_req")} />

        <OneColumn subTitle={"Completed"} items={getItems("total_completed")} />

        <OneColumn subTitle={"Cancelled"} items={getItems("total_cancel")} />

        <OneColumn subTitle={"Driv. Not Found"} items={getItems("total_nodriver")} />

        <OneColumn subTitle={"Target Grad"} items={calcPerc()} />
      </div>
    </div>
  );
};

export default TripPerformance;
