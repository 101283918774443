import axios from "axios";
import { api } from "../constants/urls";
import { useToastifyPromises } from "../helpers/toastifyHelper";

// import {AuthActionSuccess, LogoutAction} from "../actions/AuthActions";

export const useSetupApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //   1.get fees
  const getFeesReq = ({ name = "", tag = "", screen_name = "", component = "" }: any) => {
    return axios.get(api.feeSetup + `/admin/fees?name=${name}&tag=${tag}&screen_name=${screen_name}&component=${component}`);
  };
  const getFeesToast = (body: any) => toastifyPromises.get({ asyncFunction: getFeesReq(body) });

  //2. create fees
  const createFeeReq = (body: any) => axios.post(api.feeSetup + `/admin/fees`, body);
  const createFeeToast = (body: any) => toastifyPromises.post({ asyncFunction: createFeeReq(body), pendingMsg: "Creating Fee", SuccessMsg: "Fee Creation Successful" });

  //3. update fees
  const updateFeeReq = (body: any) => axios.put(api.feeSetup + `/admin/fees/${body?.fee_id}`, body);
  const updateFeeToast = (body: any) => toastifyPromises.post({ asyncFunction: updateFeeReq(body), pendingMsg: "Updating Fee", SuccessMsg: "Fee Update Successful" });

  //4. delete fees
  const deleteFeeReq = (body: any) => axios.delete(api.feeSetup + `/admin/fees/${body?.fee_id}`, body);
  const deleteFeeToast = (body: any) => toastifyPromises.post({ asyncFunction: deleteFeeReq(body), pendingMsg: "Deleting...", SuccessMsg: "Deleted" });

  return {
    getFeesToast,
    createFeeToast,
    updateFeeToast,
    deleteFeeToast,
  };
};
