import axios from "axios";
import { useContext } from "react";
// import {AuthActionSuccess, LogoutAction} from "../actions/AuthActions";
import { useNavigate } from "react-router";
import { AuthContext } from "../contexts/AuthContext";
import { useToastifyPromises } from "../helpers/toastifyHelper";

export const useAuthApiServices = () => {
  // api
  const navigate = useNavigate();
  const { logoutUserAction } = useContext(AuthContext);
  const toastifyPromises = useToastifyPromises();

  // const userService = "https://staging-server.lagosride.com/user-service2/v2";
  const userService = "https://user-service.apps.microservices.apxxup.lagosride.com/v2";

  //1. login user
  const loginReq = (body: any) => axios.post(`${userService}/auth/login-admin`, body);
  const loginToast = (body: any) => toastifyPromises.post({ asyncFunction: loginReq(body), pendingMsg: "Login in", SuccessMsg: "Successful" });

  //2. reset password
  const resetPasswordReq = (body: any) => axios.post(`${userService}/auth/reset-password-admin`, body);
  const resetPasswordToast = (body: any) => toastifyPromises.post({ asyncFunction: resetPasswordReq(body), pendingMsg: "Reseting Password", SuccessMsg: "Password Reset Successful, Proceed to Login" });

  //3. reset password
  const initiatePasswordResetReq = (body: any) => axios.post(`${userService}/auth/forgot-password-admin`, body);
  const initiatePasswordResetToast = (body: any) => toastifyPromises.post({ asyncFunction: initiatePasswordResetReq(body), pendingMsg: "Sending Password", SuccessMsg: "Password Sent, Check your email" });

  const logoutUser = () => {
    logoutUserAction();
    navigate("/signin");
  };

  return {
    loginToast,
    logoutUser,
    resetPasswordToast,
    initiatePasswordResetToast,
  };
};
