import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import EmptyPage from "../../../../../components/EmptyPage";
import GradientButton from "../../../../../components/shared/gradidentButton";
import WaitingLoader from "../../../../../components/shared/waitingLoader";
import { useSetupApiService } from "../../../../../services/setupApiServices";
import { ObjectType, ReactChangeEventType } from "../../../../../types/common";
import LabelComponent from "../labelComponent";
import queryKeys from "../../../../../constants/queryKeys";

const RiderAppSettings = () => {
  const { getFeesToast, createFeeToast, updateFeeToast, deleteFeeToast } = useSetupApiService();
  const [hoveredItem, setHoveredItem] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [fares, setFares] = useState<ObjectType[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const queryClient = useQueryClient();

  const { isLoading, isError, error, data } = useQuery("feesSetup", () => getFeesToast({ screen_name: "others" }), {
    select: (data) => data?.data,
  });

  const createFeeMutation = useMutation(createFeeToast, {
    onSuccess: async (res) => {
      if (res.data.status !== "error") {
        setModalOpen(false);
        queryClient.invalidateQueries("feesSetup");
      }
    },
    // onError: (err) => {},
  });

  const deleteFeeMutation = useMutation(deleteFeeToast, {
    onSuccess: async (res) => {
      if (res.data.status !== "error") {
        queryClient.invalidateQueries(queryKeys.feesSetup);
        // setBreakDownModalOpen(false);
      }
    },
    // onError: (err) => {},
  });

  useEffect(() => {
    setFares(data);
  }, [data]);

  const handleChange = (e: ReactChangeEventType, index: number) => {
    const copiedItem = fares.slice();
    copiedItem[index] = { ...copiedItem[index], amount: e.target.value };
    setFares(copiedItem);
    setIsEdited(true);
  };

  const updateFeeMutation = useMutation(updateFeeToast, {
    onSuccess: async (res) => {},
    // onError: (err) => {},
  });

  const updateFee = (index: any) => {
    const data = fares?.find((item, id) => id === index);
    const payload: ObjectType = {};
    const keysToRemove = ["updatedAt", "system_generated", "createdAt"];
    for (const key in data) {
      // Check if the current key is not in the keysToRemove array
      if (!keysToRemove.includes(key)) {
        // If it's not in the keysToRemove, add the key-value pair to the filtered object
        payload[key] = data[key];
      }
    }

    updateFeeMutation.mutate(payload);
  };

  const addFeesModalToggle = () => {
    setModalOpen(false);
  };

  const addFee = (e: any) => {
    e.preventDefault();
    createFeeMutation.mutate({
      name,
      amount,
      tag: "ASSET_COST_FEE",
      desc: "placeholder text",
      data: [],
      screen_name: "others",
    });
  };

  const deleteFee = (index: number | string) => {
    const data: any = fares?.find((item, id) => id === index);

    deleteFeeMutation.mutate({ fee_id: data.fee_id });
  };

  if (isLoading) return <WaitingLoader />;
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <div className="fw-bold">Rider App Settings</div>
          <div className="mt-1" style={{ fontSize: 14 }}>
            Hover over each item to edit
          </div>
        </div>
        {/* <div className="d-flex align-items-center">
          <div className="me-3">
            <Button className="border-0 text-nowrap text-success fw-bold" style={{ backgroundColor: "#e6f8f7", padding: "13px 18px" }}>
              Edit Breakdown
            </Button>
          </div>
          <GradientButton text="Add New Item" onClick={() => setModalOpen(true)} />
        </div> */}
      </div>

      {(!fares || fares?.length === 0) && (
        <div className="mt-2">
          <EmptyPage text="No fee has been set" />
        </div>
      )}

      <div className="mt-1" style={{ width: 750 }}>
        {fares?.map((item: ObjectType, index: number) => (
          <div
            key={item.label}
            className="d-flex w-100 align-items-center"
            onMouseOver={() => {
              setHoveredItem(item.name);
              // setIsEdited(false);
            }}
            onMouseLeave={() => setIsEdited(false)}
          >
            <div className={` d-flex justify-content-between align-items-center p-2 rounded ${hoveredItem === item?.name && "list-hover-bg"}`} style={{ marginBottom: 12, width: "85%" }}>
              <div className="text-capitalize" style={{ width: "60%" }}>
                {item.name}
              </div>
              <div className="fw-bold d-flex align-items-center " style={{ width: "34%" }}>
                <LabelComponent item={item} index={index} isEdited={isEdited} setIsEdited={setIsEdited} handleChange={handleChange} hoveredItem={hoveredItem} deleteFee={deleteFee} />
              </div>
            </div>
            <div className={`ms-3 ${(!isEdited || item.name !== hoveredItem) && "d-none"}`} style={{ marginBottom: 13 }}>
              <Button className="border-0 text-success fw-bold" style={{ backgroundColor: "#e6f8f7" }} onClick={() => updateFee(index)}>
                Save
              </Button>
            </div>
          </div>
        ))}
      </div>

      <Modal size="md" centered isOpen={modalOpen} toggle={addFeesModalToggle} backdrop="static">
        <ModalBody className="py-4">
          <form onSubmit={addFee}>
            <div className="d-flex justify-content-between">
              <div className="fw-bold">Add New Fee</div>
              <AiOutlineClose size={20} onClick={() => setModalOpen(false)} />
            </div>
            <FormGroup className="bg-white mt-3">
              <Label for="name">Name</Label>
              <Input id="name" name="name" placeholder="Enter fee name" type="text" className="bg-transparent" value={name} onChange={(e: ReactChangeEventType) => setName(e.target.value)} />
            </FormGroup>
            <FormGroup className="bg-white mt-4">
              <Label for="amount">Amount</Label>
              <Input id="amount" name="amount" placeholder="Enter Amount" type="number" className="bg-transparent" value={amount} onChange={(e: ReactChangeEventType) => setAmount(e.target.value)} />
            </FormGroup>
            <div style={{ width: 170, marginTop: 80 }}>
              <GradientButton text="Add Item" />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RiderAppSettings;
