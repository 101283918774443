import { Card } from "reactstrap";
import RedButton from "../../../../../components/shared/redButton";
import { calculatePostDate, formatTime } from "../../../../../helpers/dateTimeHelper";
import { ObjectType } from "../../../../../types/common";
import Prototype from "../../../users/drivers/components/driverDetailsComponents/driverDetailsComponents/profileCards/protoType";
import { geScheduleStatus } from "../../data";
type Props = {
  scheduleObject?: ObjectType;
};

const ScheduleTripsDetails = (props: Props) => {
  const { scheduleObject } = props;

  return (
    <div>
      <Card body className=" p-2 " style={{ backgroundColor: "#f5fbfd" }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1">
            <div className="flex-grow-1">
              <div className="text-blue fw-semibold">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.92018 2.56453C4.38685 0.257865 0.913513 0.251199 0.380179 2.56453C0.0668461 3.91787 0.926846 5.06453 1.67351 5.77787C2.22018 6.2912 3.08018 6.2912 3.62685 5.77787C4.37351 5.06453 5.22685 3.91787 4.92018 2.56453ZM2.67351 3.6312C2.30685 3.6312 2.00685 3.3312 2.00685 2.96453C2.00685 2.59787 2.30018 2.29787 2.66685 2.29787H2.67351C3.04685 2.29787 3.34018 2.59787 3.34018 2.96453C3.34018 3.3312 3.04685 3.6312 2.67351 3.6312ZM13.6068 10.5645C13.0735 8.25787 9.58685 8.2512 9.04685 10.5645C8.73351 11.9179 9.59351 13.0645 10.3468 13.7779C10.8935 14.2912 11.7602 14.2912 12.3068 13.7779C13.0602 13.0645 13.9202 11.9179 13.6068 10.5645ZM11.3535 11.6312C10.9868 11.6312 10.6868 11.3312 10.6868 10.9645C10.6868 10.5979 10.9802 10.2979 11.3468 10.2979H11.3535C11.7202 10.2979 12.0202 10.5979 12.0202 10.9645C12.0202 11.3312 11.7202 11.6312 11.3535 11.6312ZM7.00018 12.6652H5.21351C4.44018 12.6652 3.76685 12.1985 3.50018 11.4785C3.22685 10.7585 3.42685 9.9652 4.00685 9.45187L9.33351 4.79187C9.65351 4.51187 9.66018 4.13187 9.56685 3.87187C9.46685 3.61187 9.21351 3.33187 8.78685 3.33187H7.00018C6.72685 3.33187 6.50018 3.1052 6.50018 2.83187C6.50018 2.55853 6.72685 2.33187 7.00018 2.33187H8.78685C9.56018 2.33187 10.2335 2.79853 10.5002 3.51853C10.7735 4.23853 10.5735 5.03187 9.99351 5.5452L4.66685 10.2052C4.34685 10.4852 4.34018 10.8652 4.43351 11.1252C4.53351 11.3852 4.78685 11.6652 5.21351 11.6652H7.00018C7.27351 11.6652 7.50018 11.8919 7.50018 12.1652C7.50018 12.4385 7.27351 12.6652 7.00018 12.6652Z"
                    fill="#2FB5D9"
                  />
                </svg>
                <span className="mt-1 ms-1"> Trip Details</span>
              </div>
              <div className="row">
                <Prototype cols={2} label="Created At" value={calculatePostDate(scheduleObject?.createdAt)} />
                <Prototype cols={2} label="Ride Date" value={formatTime(scheduleObject?.ride_schedule).fullDateTime} />
                <Prototype cols={2} label="Schedule Reference" value={scheduleObject?.schedule_ref} />
                <Prototype cols={2} label="Created By" value={<div className="text-capitalize">{scheduleObject?.created_by}</div>} />
                {/* <Prototype cols={3} label="Payment ID" value={scheduleObject?.payment_id} /> */}
                <Prototype
                  cols={2}
                  label="Status"
                  value={
                    <div style={{ width: 100 }}>
                      <RedButton className="py-1 px-2 rounded-pill bg-warning text-white" text={geScheduleStatus(scheduleObject?.request_status) || ""} />
                    </div>
                  }
                />
                {/* <Prototype cols={2} label="Promo" value={`₦${scheduleObject?.fare_params?.promo_value}`} /> */}
                {/* <Prototype cols={2} label="No. of trials" value={scheduleObject?.trials?.toLocaleString()} /> */}
                <Prototype
                  cols={2}
                  label="Trip class"
                  value={
                    <div style={{ width: 100 }}>
                      <div className="py-1 px-2 rounded-pill bg-warning text-white text-center">{scheduleObject?.ride_class === "A" ? "Solo ride" : "Shared ride"}</div>
                    </div>
                  }
                />
                {/* <Prototype
              cols={3}
              label="Trip type"
              value={
                <div style={{ width: 100 }}>
                  <GradientButton
                    className="py-1 px-2 rounded-pill"
                    text={scheduleObject?.ride_type || "."}
                  />
                </div>
              }
            /> */}
                {/* <Prototype cols={3} label="Trip subclass" value={`Promo`} /> */}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ScheduleTripsDetails;
