import { Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import queryKeys from '../../../../constants/queryKeys';
import { useSchedulesApiService } from '../../../../services/schedulesApiServices';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setDate: (clear: boolean) => void;
  setStatus: Dispatch<SetStateAction<string>>;
};

const OverviewSumaryCards = (props: Props) => {
  const { setActiveTab, setDate, setStatus } = props;

  const { getSchedulesToast } = useSchedulesApiService();

  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: schedulesCountToday,
  } = useQuery(
    [queryKeys.schedulesCountToday],
    () => getSchedulesToast({ component: 'count-status-today' }),
    {
      select: (data) => (data?.data?.length > 0 ? data?.data[0] : {}),
    }
  );
  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: schedulesCountAll,
  } = useQuery(
    [queryKeys.schedulesCountAll],
    () =>
      getSchedulesToast({
        component: ' count-status-all',
        // user_type: 'rider',
      }),
    {
      select: (data) => (data?.data?.length > 0 ? data?.data[0] : {}),
    }
  );

  const itemsToday = [
    {
      title: 'Fulfilled Schedules',
      value: Math.floor(schedulesCountToday?.fulfilled || 0).toLocaleString(),
      cardBgColor: '#e9fbf8',
      icon: 'completed-trips.svg',
      iconCardColor: '#d5f7f2',
      status: '1',
    },
    {
      title: 'Unfulfilled Schedules',
      value: Math.floor(schedulesCountToday?.unfullfiled || 0).toLocaleString(),
      cardBgColor: '#fff5f5',
      icon: 'canceled-trips.svg',
      iconCardColor: '#ffeced',
      status: '2',
    },
    {
      title: 'Assigned Schedules',
      value: Math.floor(schedulesCountToday?.assigned || 0).toLocaleString(),
      cardBgColor: '#e9f4fb',
      icon: 'total-trips.svg',
      iconCardColor: '#d5e7f7',
      status: '3',
    },
    {
      title: 'Unassigned Schedules',
      value: Math.floor(schedulesCountToday?.unassigned || 0).toLocaleString(),
      cardBgColor: '#fff5f5',
      icon: 'canceled-trips.svg',
      iconCardColor: '#ffeced',
      status: '4',
    },
  ];
  
  const itemsAll = [
    {
      title: 'Fulfilled Schedules',
      value: Math.floor(schedulesCountAll?.fulfilled || 0).toLocaleString(),
      cardBgColor: '#e9fbf8',
      icon: 'completed-trips.svg',
      iconCardColor: '#d5f7f2',
      status: '1',
    },
    {
      title: 'Unfulfilled Schedules',
      value: Math.floor(schedulesCountAll?.unfullfiled || 0).toLocaleString(),
      cardBgColor: '#fff5f5',
      icon: 'canceled-trips.svg',
      iconCardColor: '#ffeced',
      status: '2',
    },
    {
      title: 'Pending Schedules',
      value: Math.floor(schedulesCountAll?.pending || 0).toLocaleString(),
      cardBgColor: '#fcf7e9',
      icon: 'ongoing-trips.svg',
      iconCardColor: '#fdf0c1',
      status: '0',
    },
  ];

  return (
    <Row>
      <h6 className='fw-bold'>Today's schedules</h6>
      {schedulesCountToday &&
        itemsToday.map((item) => (
          <Col md={3} key={item.title} className='mt-3'>
            <div
              className='rounded py-3 ps-3 d-flex align-items-center justify-content-between cursor-pointer'
              style={{ backgroundColor: item.cardBgColor, height: 92 }}
              onClick={() => {
                setActiveTab('Schedules Log');
                setDate(false);
                setStatus(item.status);
              }}
            >
              <div className='d-flex align-items-center'>
                <div
                  className='p-2 rounded'
                  style={{ background: item.iconCardColor }}
                >
                  <img
                    alt='icon'
                    src={`/svgs/icons/${item.icon}`}
                    height={19}
                    width={25}
                  />
                </div>
                <div className='ms-3'>
                  <div style={{ color: '#71939C', fontSize: 14 }}>
                    {' '}
                    {item?.title}
                  </div>
                  <h6 className='text-blue-black fw-bold mt-2'>
                    {item?.value}
                  </h6>
                </div>
              </div>
            </div>
          </Col>
        ))}

      <h6 className='fw-bold mt-5'>All Scheduled Trips</h6>
      {schedulesCountToday &&
        itemsAll.map((item) => (
          <Col md={4} key={item.title} className='mt-3'>
            <div
              className='rounded py-3 ps-3 d-flex align-items-center justify-content-between cursor-pointer'
              style={{ backgroundColor: item.cardBgColor, height: 92 }}
              onClick={() => {
                setActiveTab('Schedules Log');
                setStatus(item.status);
                setDate(true);
              }}
            >
              <div className='d-flex align-items-center'>
                <div
                  className='p-2 rounded'
                  style={{ background: item.iconCardColor }}
                >
                  <img
                    alt='icon'
                    src={`/svgs/icons/${item.icon}`}
                    height={19}
                    width={25}
                  />
                </div>
                <div className='ms-3'>
                  <div style={{ color: '#71939C', fontSize: 14 }}>
                    {' '}
                    {item?.title}
                  </div>
                  <h6 className='text-blue-black fw-bold mt-2'>
                    {item?.value}
                  </h6>
                </div>
              </div>
            </div>
          </Col>
        ))}
    </Row>
  );
};

export default OverviewSumaryCards;
