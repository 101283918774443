import { Dispatch, SetStateAction } from 'react';
import { FaCarAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import queryKeys from '../../../../constants/queryKeys';
import { useUsersApiService } from '../../../../services/usersService';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setReferralType: Dispatch<SetStateAction<string>>;
};

const OverviewSumaryCards = (props: Props) => {
  const { getReferralToast } = useUsersApiService();
  const { setReferralType, setActiveTab } = props;

  const { data: referral } = useQuery(
    [queryKeys.referral],
    () =>
      getReferralToast({
        component: 'count-status',
      }),
    {
      select: (data) => data?.data,
      // enabled: false,
      keepPreviousData: true,
    }
  );

  const itemsTrips = [
    {
      title: 'No. of Referrals',
      value: Math.floor(referral?.total_count || 0).toLocaleString(),
      cardBgColor: '#0079D016',
      icon: <FaCarAlt size={20} color='#002ED0' />,
      iconCardColor: '#002DE016',
      tag: 'total',
    },

    {
      title: 'Confirmed Ref.',
      value: Math.floor(referral?.total_confirmed || 0).toLocaleString(),
      cardBgColor: '#00D0A816',
      iconCardColor: '#00D0A816',
      icon: <FaCarAlt size={20} color='#00D2AB' />,
      tag: 'confirmed',
    },

    {
      title: 'Pending Ref.',
      value: Math.floor(referral?.total_pending || 0).toLocaleString(),
      iconCardColor: '#FDF0C1',
      cardBgColor: '#D0A30016',
      icon: <FaCarAlt size={20} color='#FE9603' />,
      tag: 'pending',
    },
  ];

  return (
    <div>
      <h5 className='fw-bold'>Referral Overview</h5>

      <Row>
        {itemsTrips.map((item) => (
          <Col key={item.title} className='mt-4' md={4}>
            <div
              className='rounded py-3 ps-3 d-flex align-items-center justify-content-between cursor-pointer'
              style={{ backgroundColor: item.cardBgColor, height: 92 }}
              onClick={() => {
                setReferralType(item.tag);
                setActiveTab("Referral's Log");
              }}
            >
              <div className='d-flex align-items-center'>
                <div
                  className='p-2 rounded'
                  style={{ background: item.iconCardColor }}
                >
                  {item.icon}
                </div>
                <div className='ms-3'>
                  <div style={{ color: '#71939C', fontSize: 15 }}>
                    {' '}
                    {item.title}
                  </div>
                  <h5 className='text-blue-black fw-bold mt-2'>{item.value}</h5>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default OverviewSumaryCards;
