import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CusCard from "../../../components/shared/cusCard";
import { useQueryStringHelper } from "../../../helpers/queryStringHelper";
import TripsLog from "./tripsLog";
import TripsOverview from "./tripsOverview";

const Trips = () => {
  const { getIdFromQueryString, getQueryStringValue } = useQueryStringHelper();
  const tabs = ["Overview", "Trip's Log"];
  const [tripsType, setTripsType] = useState("");
  const [tripsStatus, setTripsStatus] = useState("");
  const [activeId, setActiveId] = useState("");
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [location, setLocation] = useState({ area: "", lga: "" });

  const navigate = useNavigate();
  useEffect(() => {
    if (getQueryStringValue("trip-id")) setActiveTab("Trip's Log");
  }, []);

  return (
    <CusCard className="p-0" style={{ minHeight: "70vh" }}>
      <div className="d-flex">
        {tabs.map((item) => (
          <div
            className={`px-3 bg-none cursor-pointer ${activeTab === item && "gradient-btn text-white fw-bold"} ${item === "Profile" && activeTab !== "Profile" && "d-none"}`}
            key={item}
            style={{ paddingTop: "11px", paddingBottom: "11px" }}
            onClick={() => {
              item !== "Trip's Log" && navigate("/trips");
              setActiveTab(item);
              setLocation({ area: "", lga: "" });
              setTripsStatus("");
              setTripsType("");
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="p-3 p-sticky" style={{ top: 0 }}>
        {activeTab === "Overview" && <TripsOverview setTripsType={setTripsType} setTripsStatus={setTripsStatus} setActiveTab={setActiveTab} setLocation={setLocation} location={location} />}
        {activeTab === "Trip's Log" && <TripsLog tripsType={tripsType} tripsStatus={tripsStatus} setActiveTab={setActiveTab} setActiveId={setActiveId} location={location} />}
      </div>
    </CusCard>
  );
};

export default Trips;
