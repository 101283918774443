import { HiUsers } from "react-icons/hi";

export const usersLink = {
  title: "User",
  icon: <HiUsers size={20} />,
//   link: "users",
  subNavs: [
    {
      title: "Riders",
      link: "/user/riders",
    },
    {
      title: "Business acct",
      link: "/user/businesses",
    },
    {
      title: "Drivers",
      link: "/user/drivers",
    },
    {
      title: "Partners",
      link: "/user/partners",
    },
  ],
};
