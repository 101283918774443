import { Dispatch, SetStateAction, useContext, useEffect } from 'react';

import OverviewSumaryCards from './components/overviewSummaryCards';
import PromoTrend from './components/emergencyTrend';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setEmergencyType: Dispatch<SetStateAction<string>>;
};
const EmergencyOverview = (props: Props) => {
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const { setEmergencyType, setActiveTab } = props;

  useEffect(() => {
    setBreadCrumbData(['Overview']);
  }, []);
  return (
    <div>
      <div>
        <OverviewSumaryCards
          setActiveTab={setActiveTab}
          setEmergencyType={setEmergencyType}
        />
      </div>

      <div className='mt-5'>
        <PromoTrend />
      </div>
    </div>
  );
};

export default EmergencyOverview;
