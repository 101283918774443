import { useState } from 'react';
import { useNavigate } from 'react-router';
import CusCard from '../../../components/shared/cusCard';
import { useQueryStringHelper } from '../../../helpers/queryStringHelper';
import PromoLog from './emergencyLog';
import PromoOverview from './emergencyOverview';
import EmailLog from './emailLog';
import PhoneLog from './phoneLog';
import EmergencyOverview from './emergencyOverview';

const Emergency = () => {
  const { getIdFromQueryString } = useQueryStringHelper();
  const tabs = [
    'Overview',
    'Emergency Request Log',
    `Emergency Email`,
    `Emergency Phone Number`,
  ];
  const [emergencyType, setEmergencyType] = useState('total');
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate();

  return (
    <CusCard className='p-0' style={{ minHeight: '70vh' }}>
      <div className='d-flex'>
        {tabs.map((item) => (
          <div
            className={`px-3 bg-none cursor-pointer ${
              activeTab === item && 'gradient-btn text-white fw-bold'
            } ${item === 'Profile' && activeTab !== 'Profile' && 'd-none'}`}
            key={item}
            style={{ paddingTop: '11px', paddingBottom: '11px' }}
            onClick={() => {
              item !== 'Profile' && navigate('/emergency');
              setActiveTab(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className='p-3 p-sticky' style={{ top: 0 }}>
        {activeTab === 'Overview' && (
          <div>
            <EmergencyOverview
              setActiveTab={setActiveTab}
              setEmergencyType={setEmergencyType}
            />
          </div>
        )}

        {activeTab === 'Emergency Request Log' && (
          <PromoLog
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setEmergencyType={setEmergencyType}
            emergencyType={emergencyType}
          />
        )}
        {activeTab === 'Emergency Email' && (
          <EmailLog setActiveTab={setActiveTab} activeTab={activeTab} />
        )}
        {activeTab === 'Emergency Phone Number' && (
          <PhoneLog setActiveTab={setActiveTab} activeTab={activeTab} />
        )}
      </div>
    </CusCard>
  );
};

export default Emergency;
