import { Dispatch, SetStateAction, useContext, useEffect } from 'react';

import OverviewSumaryCards from './components/overviewSummaryCards';
import PromoTrend from './components/promoTrend';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setPromoType: Dispatch<SetStateAction<string>>;
};
const PromoOverview = (props: Props) => {
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const { setPromoType, setActiveTab, } = props;


  useEffect(() => {
    setBreadCrumbData(['Promo', 'Overview']);
  }, []);
  return (
    <div>
      <div>
        <OverviewSumaryCards
          setActiveTab={setActiveTab}
          setPromoType={setPromoType}
        />
      </div>

      <div className='mt-5'>
        <PromoTrend />
      </div>
    </div>
  );
};

export default PromoOverview;
