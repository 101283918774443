import { Col, Row } from "reactstrap";
import GradientButton from "../shared/gradidentButton";
import { Dispatch, SetStateAction, useState } from "react";
import { months, years } from "../../Pages/others/users/helpers/strings";
import { ObjectType } from "../../types/common";

type Props = {
  month: string;
  setYear: (year: string) => void;
  setYearEnd?: Dispatch<SetStateAction<string>>;
  handleToggle: VoidFunction;
};

const YearsCalender = (props: Props) => {
  const { month, setYear, handleToggle, setYearEnd } = props;
  const [selectedMonth, setSelectedMonth] = useState(month);

  return (
    <div>
      <div className="fw-bold">Select year</div>
      <Row className="p-2 mt-2 fw-normal">
        {years.map((item, index) => (
          <Col className="mb-4 text-nowrap" xs={4} key={item.label}>
            <div className={`cursor-pointer ${selectedMonth.split("-")[0] === item.value.split("-")[0] && "gradient-text fw-bold"}`} onClick={() => setSelectedMonth(item.value)}>
              {item.label}
            </div>
          </Col>
        ))}
        <div style={{ width: 100 }}>
          <GradientButton
            text="Apply"
            className="btn-sm py-1"
            onClick={() => {
              setYear(selectedMonth + "-01" + "-01");
              setYearEnd ? setYearEnd(selectedMonth + "-12" + "-31") : "";
              handleToggle();
            }}
          />
        </div>
      </Row>
    </div>
  );
};

export default YearsCalender;
