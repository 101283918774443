import { Col, Row } from 'reactstrap';
import GradientButton from '../shared/gradidentButton';
import { Dispatch, SetStateAction, useState } from 'react';
import { months } from '../../Pages/others/users/helpers/strings';

type Props = {
  month: string;
  setMonth: Dispatch<SetStateAction<string>>;
  handleToggle: VoidFunction;
};

const MonthsCalender = (props: Props) => {
  const { month, setMonth,handleToggle } = props;
  const [selectedMonth, setSelectedMonth] = useState(month);
 
  return (
    <div>
      <div className='fw-bold'>Select month</div>
      <Row className='p-2 mt-2 fw-normal'>
        {months.map((item) => (
          <Col className='mb-4' col={2} key={item.label}>
            <div
              className={`cursor-pointer ${
                selectedMonth === item.value && 'gradient-text fw-bold'
              }`}
              onClick={() => setSelectedMonth(item.value)}
            >
              {item.label}
            </div>
          </Col>
        ))}
        <div style={{ width: 100 }}>
          <GradientButton
            text='Apply'
            className='btn-sm py-1'
            onClick={() => {
              setMonth(selectedMonth);
              handleToggle();
            }}
          />
        </div>
      </Row>
    </div>
  );
};

export default MonthsCalender;
