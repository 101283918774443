import { Dispatch, SetStateAction, useState } from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import NewFilter from '../../../../components/filter/NewFilter';
import FilterComponent from '../../../../components/shared/filterComponent';
import queryKeys from '../../../../constants/queryKeys';
import { useTripsApiService } from '../../../../services/tripsApiService';
import { getTodayDate } from '../../../../helpers/dateTimeHelper';

type Props = {
  setTripsType: Dispatch<SetStateAction<string>>;
  setTripsStatus: Dispatch<SetStateAction<string>>;
  setActiveTab: Dispatch<SetStateAction<string>>;
};

const OverviewSumaryCards = (props: Props) => {
  const { setTripsType, setTripsStatus, setActiveTab } = props;
  const { getTripsToast } = useTripsApiService();
  const [startDate, setStartDate] = useState(getTodayDate());
  const [endDate, setEndDate] = useState(getTodayDate());
  const [toggled, setToggled] = useState(false);
  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });

  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: driverTripsCount,
  } = useQuery(
    [queryKeys.userTripsSummary, filterState],
    () =>
      getTripsToast({
        component: 'count-status',
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data,
    }
  );

  const items = [
    {
      title: 'Total Trips',
      value: Math.floor(driverTripsCount?.total_count || 0).toLocaleString(),
      cardBgColor: '#e9f4fb',
      icon: 'total-trips.svg',
      iconCardColor: '#d5e7f7',
    },
    {
      title: 'Driver Enroute',
      value: Math.floor(driverTripsCount?.enroute_count || 0).toLocaleString(),
      cardBgColor: '#e9edfb',
      icon: 'enroute-trips.svg',
      iconCardColor: '#d5ddf7',
      tag: 'on_pickup',
      tagType: 'status',
    },

    {
      title: 'Ongoing',
      value: Math.floor(driverTripsCount?.ongoing_count || 0).toLocaleString(),
      cardBgColor: '#fcf7e9',
      icon: 'ongoing-trips.svg',
      iconCardColor: '#fdf0c1',
      tag: 'on_ride',
      tagType: 'status',
    },
    {
      title: 'Completed',
      value: Math.floor(
        driverTripsCount?.completed_count || 0
      ).toLocaleString(),
      cardBgColor: '#f2fcfa',
      icon: 'completed-trips.svg',
      iconCardColor: '#e6f9f6',
      tag: 'completed',
      tagType: 'status',
    },
    // {
    //   title: "Drupz",
    //   value: driverTripsCount?.drupz_count || 0,
    //   cardBgColor: "#e9fbf8",
    //   icon: "completed-trips.svg",
    //   iconCardColor: "#d5f7f2",
    //   tag: "dropz",
    //   tagType: "type",
    // },
    {
      title: 'Driver not found',
      value: Math.floor(
        driverTripsCount?.driver_not_found_count || 0
      ).toLocaleString(),
      cardBgColor: '#e9f4fb',
      icon: 'total-trips.svg',
      iconCardColor: '#d5e7f7',
      tag: 'driver-not-found',
      tagType: 'type',
    },
    {
      title: 'Canceled By Riders',
      value: Math.floor(
        driverTripsCount?.rider_cancel_count || 0
      ).toLocaleString(),
      cardBgColor: '#fff5f5',
      icon: 'canceled-trips.svg',
      iconCardColor: '#ffeced',
      tag: 'cancel-rider',
      tagType: 'status',
    },
    {
      title: 'Canceled By Drivers',
      value: Math.floor(
        driverTripsCount?.driver_cancel_count || 0
      ).toLocaleString(),
      cardBgColor: '#fff5f5',
      icon: 'canceled-trips.svg',
      iconCardColor: '#ffeced',
      tag: 'cancel-driver',
      tagType: 'status',
    },
  ];

  const handleToggle = () => {
    setToggled(!toggled);
  };
  const onApply = () => {
    setFilterState({ startDate, endDate });
    handleToggle();
  };

  return (
    <div>
      <div className='d-flex justify-content-end'>
        <NewFilter
          direction={'start'}
          toggleComponent={<FilterComponent />}
          toggled={toggled}
          handleToggle={handleToggle}
          // options={filterOptions}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onApply={onApply}
        />
      </div>
      <Row>
        {items.map((item) => (
          <Col md={3} key={item.title} className='mt-3'>
            <div
              className='rounded py-3 ps-3 d-flex align-items-center justify-content-between cursor-pointer'
              style={{ backgroundColor: item.cardBgColor, height: 92 }}
              onClick={() => {
                if (item.title === 'Total Trips') {
                  setTripsStatus('');
                  setTripsType('');
                } else if (item.tagType === 'status') {
                  setTripsStatus(item.tag);
                  setTripsType('');
                } else if (item.tagType === 'type') {
                  setTripsStatus('');
                  setTripsType(item.tag);
                }
                // i am exempting "driver not found" because it shouldn't be part of the trips log.. so it shouldnt link to the log.
                // item.title !== "Driver not found" &&
                setActiveTab("Trip's Log");
              }}
            >
              <div className='d-flex align-items-center'>
                <div
                  className='p-2 rounded'
                  style={{ background: item.iconCardColor }}
                >
                  <img
                    alt='icon'
                    src={`/svgs/icons/${item.icon}`}
                    height={19}
                    width={25}
                  />
                </div>
                <div className='ms-3'>
                  <div style={{ color: '#71939C', fontSize: 15 }}>
                    {' '}
                    {item.title}
                  </div>
                  <h5 className='text-blue-black fw-bold mt-2'>
                    {item.value.toLocaleString()}
                  </h5>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default OverviewSumaryCards;
