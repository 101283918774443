import { useContext, useEffect } from "react";
import "../../assets/scss/pages.scss";
import { AppPrefrenceContext } from "../../contexts/AppPrefrenceContext";
import SideBar from "../SideBar";
import TopNav from "../TopNav";
import { useSocket } from "../../services/socketService";

const StandardLayout = ({ children, title }: any) => {
  const { expanded, setExpanded, toggle } = useContext(AppPrefrenceContext);

  const { createConnection } = useSocket();

  useEffect(() => {
    // createConnection(); temporaririly commented out
  });

  return (
    <div className="s-layout">
      <SideBar />
      <div className="page-body w-100 ">
        <div className="">
          <TopNav title={title} />
        </div>
        <div className={`container-fluid mb-5 ${expanded && "position-fixed"}`} style={{ marginTop: -46 }}>
          {children}
        </div>
        {expanded && <div className="position-fixed h-100 w-100 top-0 start-0 pages-overlay d-md-none" onClick={toggle}></div>}
      </div>
    </div>
  );
};

export default StandardLayout;
