import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

type Props = {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  onChange: any;
};

const PaginationComponent = (props: Props) => {
  const { itemsCountPerPage, activePage, totalItemsCount, onChange } = props;
  const pageCount = Math.ceil(totalItemsCount / itemsCountPerPage);
  const numberofPagesShownAtOnce = 5;


  const ranges: any = () => {
    if (pageCount <= numberofPagesShownAtOnce) {
      return [1, pageCount];
    } else {
      if (activePage <= Math.ceil(numberofPagesShownAtOnce / 2)) {
        return [1, numberofPagesShownAtOnce];
      } else {
        if (pageCount - activePage <= Math.floor(numberofPagesShownAtOnce / 2)) {
          return [pageCount - numberofPagesShownAtOnce + 1, pageCount];
        } else {
          return [activePage + Math.floor(numberofPagesShownAtOnce / 2) - numberofPagesShownAtOnce + 1, activePage + Math.floor(numberofPagesShownAtOnce / 2)];
        }
      }
    }
  };

  const list = [];
  for (let i = ranges()[0]; i <= ranges()[1]; i++) {
    list.push(i);
  }
  return (
    <div className="text-center">
      <Pagination className="d-inline-block">
        <PaginationItem onClick={() => onChange(1)}>
          {/* <PaginationLink first className="border border-0 p-0"> */}
          <div className="mt-1 me-2 cursor-pointer">
            <img alt="icon" src="/svgs/paginate-backward.svg" />
          </div>
          {/* </PaginationLink> */}
        </PaginationItem>
        {/* <PaginationItem>
          <PaginationLink href="#" previous />
        </PaginationItem> */}

        {list.map((item) => (
          <PaginationItem key={item} active={item === activePage} className="mx-0" onClick={() => onChange(item)}>
            <PaginationLink className="border-0 text-black mx-0">{item}</PaginationLink>
          </PaginationItem>
        ))}
        {/* <PaginationItem>
          <PaginationLink href="#" next />
        </PaginationItem> */}
        <PaginationItem onClick={() => onChange(pageCount)} className="cursor-pointer border-none outline-none">
          {/* <PaginationLink className="p-0 border-0" last > */}
          <div className="mt-1 ms-2">
            <img alt="icon" src="/svgs/paginate-forward.svg" />
          </div>
          {/* </PaginationLink> */}
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
