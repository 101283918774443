export const getIconSrc = (userType: string) => {
  if (userType === "Riders") return "rider-wallet.svg";
  if (userType === "Drivers") return "driver-wallet.svg";
  if (userType === "Partners") return "partner-wallet.svg";
  if (userType === "Vehicles") return "vehicle-wallet.svg";
};

export const getWalletStatus = (status: number) => {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Completed";
    case 2:
      return "Failed";
    case 3:
      return "Abandoned";
    case -1:
      return "Debit";
    default:
      break;
  }
};

export const getWalletStatusColor = (status: number) => {
  switch (status) {
    case 0:
      return "text-warning";
    case 1:
      return "text-success";
    case 2:
      return "text-danger";
    case 3:
      return "text-secondary";
    case -1:
      return "text-danger";
    default:
      break;
  }
};

export const getPaymentStatus = (status: number) => {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Paid";
    case 2:
      return "Failed";
    case 3:
      return "Processing";
    case 4:
      return "Reviewed";
    case 5:
      return "Reversed";
    default:
      break;
  }
};

export const getPaymentStatusColor = (status: number) => {
  switch (status) {
    case 0:
      return "text-warning";
    case 1:
      return "text-success";
    case 2:
      return "text-danger";
    case 3:
      return "text-secondary";
    case 4:
      return "text-deep-blue";
    case 5:
      return "text-deep-purple";
    default:
      break;
  }
};

export const getDebtServiceStatus = (status: number) => {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Successful";
    case 2:
      return "Debt Cleared";
    default:
      break;
  }
};

export const getDebtServiceStatusColor = (status: number) => {
  switch (status) {
    case 0:
      return "text-warning";
    case 1:
      return "text-success";
    case 2:
      return "text-danger";
    default:
      break;
  }
};

export const isPreFix = (unit: string) => {
  if (unit === "₦" || !unit) return true;
  return false;
};
