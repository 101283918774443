import { lazy } from "react";

//drivers
const Drivers = lazy(() => import("../../Pages/others/users/drivers"));

//riders
const Riders = lazy(() => import("../../Pages/others/users/riders"));

//partners
const Partners = lazy(() => import("../../Pages/others/users/partners"));
const Businesses = lazy(() => import("../../Pages/others/users/businesses"));

export default {
  path: "/user",
  title: "Users",
  children: [
    {
      path: "/drivers",
      component: Drivers,
      title: "Drivers",
      permission: "Driver",
    },
    {
      path: "/businesses",
      component: Businesses,
      title: "Businesses",
      permission: "Business",
    },
    {
      path: "/riders",
      component: Riders,
      title: "Riders",
      permission: "Rider",
    },
    {
      path: "/partners",
      component: Partners,
      title: "Partners",
      permission: "Partner",
    },
  ],
};
