import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Card, Col, Modal, ModalBody, Row } from 'reactstrap';
import GradientButton from '../../../../components/shared/gradidentButton';
import queryKeys from '../../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../../contexts/AppPrefrenceContext';
import { useQueryStringHelper } from '../../../../helpers/queryStringHelper';
import { useEmergencyApiService } from '../../../../services/emergencyApiServices';
import { ObjectType, ReactChangeEventType } from '../../../../types/common';
import DriverInfo from './driverInfo';
import EmergencyModalBody from './emergencyModalBody';
import Location from './location';

type Props = {
  emergencyId: string;
  emergencyDetails: ObjectType;
  setEmergencyId: Dispatch<SetStateAction<string>>;
};

const EmergencyDetails = (props: Props) => {
  const { removeAllQueryStringsFromURL } = useQueryStringHelper();
  const { deleteModalConfig } = useContext(AppPrefrenceContext);
  const { emergencyDetails, setEmergencyId } = props;
  const queryClient = useQueryClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState<ObjectType | []>([]);
  const { updateEmergencyToast } = useEmergencyApiService();

  const handleModalToggle = () => {
    setModalOpen((prev) => !prev);
  };

  const handleChange = (e: ReactChangeEventType) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const updateEmergencyMutation = useMutation(updateEmergencyToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        queryClient.invalidateQueries(queryKeys.emergency);
        queryClient.invalidateQueries(queryKeys.emergencyCount);
      }
    },
    // onError: (err) => {},
  });

  const text = emergencyDetails?.status === 0 ? 'Unresolved' : 'Resolved';
  const color = emergencyDetails?.status !== 0 ? '#00d2a8' : '#ff3739';

  return (
    <div style={{ minHeight: '100vh' }}>
      <div className='d-flex justify-content-between align-items-center text-nowrap flex-wrap'>
        <div className='d-flex align-items-center '>
          <BsArrowLeft
            size={30}
            className=''
            onClick={() => {
              setEmergencyId('');
              removeAllQueryStringsFromURL();
            }}
          />
          <div className='fw-bolder ms-3 fs-5'>
            {' '}
            {emergencyDetails?.user_data?.first_name}{' '}
            {emergencyDetails?.user_data?.last_name}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          {emergencyDetails.status === 0 && (
            <div
              className='me-3 cursor-pointer p-2 rounded'
              style={{
                backgroundColor: 'rgba(4, 167, 167, 0.1)',
                color: '#04a7a7',
              }}
              onClick={() => {
                deleteModalConfig({
                  type: 'emergency',
                  actionButton: 'blue',
                  firstText: ` Resolve Request?`,
                  secondText: `This action would resolve this emergency request `,
                  callback: () =>
                    updateEmergencyMutation.mutate({
                      contact: emergencyDetails._id,
                      status: '1',
                    }),
                });
              }}
            >
              Make as Resolved
            </div>
          )}
          <div>
            <GradientButton
              text={'Assign Request'}
              className='py-2'
              onClick={handleModalToggle}
            />
          </div>
        </div>
      </div>

      <div>
        <Card body className='mt-5 p-2'>
          <div className='d-flex justify-content-between capitalize'>
            <div className='d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1'>
              <div className='flex-grow-1'>
                <div className=''>
                  <div className='text-blue fw-semibold'>About</div>
                </div>
                <Row className='w-100'>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Trip ID</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {emergencyDetails?.trip_data?.trip_id}
                    </div>
                  </Col>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>User Type</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {emergencyDetails?.user_data?.user_type}
                    </div>
                  </Col>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Class of Ride</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {emergencyDetails?.trip_data?.ride_class}
                    </div>
                  </Col>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Booking Type</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {emergencyDetails?.trip_data?.ride_type}
                    </div>
                  </Col>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Rider Name</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {emergencyDetails?.user_data?.first_name}{' '}
                      {emergencyDetails?.user_data?.last_name}
                    </div>
                  </Col>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Status</small>
                    </div>
                    <Button
                      className={` border-0 font-krub-semibold text-nowrap rounded fw-bold py-1 px-3 rounded-pill mt-1 text-capitalize`}
                      style={{
                        padding: '13px 18px',
                        fontSize: 14,
                        backgroundColor: color,
                      }}
                    >
                      {text}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <DriverInfo emergencyDetails={emergencyDetails} />
      <Location emergencyDetails={emergencyDetails} />

      <Modal
        size='lg'
        isOpen={modalOpen}
        toggle={handleModalToggle}
        backdrop='static'
        centered
        // style={{ width: 400 }}
      >
        <ModalBody className='p-4'>
          <div className='d-flex justify-content-between'>
            <div className='fw-bold'>Assign Request</div>
            <AiOutlineClose size={20} onClick={handleModalToggle} />
          </div>
          <EmergencyModalBody
            handleToggle={handleModalToggle}
            // emergencyDetails={emergencyDetails}
            type='mark'
            handleChange={handleChange}
            data={data}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EmergencyDetails;
