import { Card, Col, Row } from 'reactstrap';
import { ObjectType } from '../../../../types/common';

type Props = {
  emergencyDetails: ObjectType;
};

const DriverInfo = (props: Props) => {
  const { emergencyDetails } = props;

  return (
    <div>
      <Card body className='mt-3 p-2'>
        <div className='d-flex justify-content-between capitalize'>
          <div className='d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1'>
            <div className='flex-grow-1'>
              <div className=''>
                <div className='text-blue fw-semibold'>Driver and Vehicle</div>
              </div>
              <Row className='w-100'>
                <Col className='mt-2' md={2}>
                  <div>
                    <small className='text-grey'>Driver Name</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {emergencyDetails?.trip_data_all?.driver_data?.name}
                  </div>
                </Col>
                <Col className='mt-2' md={2}>
                  <div>
                    <small className='text-grey'>Vehicle Details</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {emergencyDetails?.vehicle_data?.car_color},{' '}
                    {emergencyDetails?.vehicle_data?.car_make} {''}
                    {emergencyDetails?.vehicle_data?.car_model}
                  </div>
                </Col>
                <Col className='mt-2' md={2}>
                  <div>
                    <small className='text-grey'>Clip of Incident</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {/* {emergencyDetails?.channel} */}
                  </div>
                </Col>
                <Col className='mt-2 offset-md-1' md={2}>
                  <div>
                    <small className='text-grey'>Channel of Report</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {emergencyDetails?.channel}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DriverInfo;
