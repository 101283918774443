import axios from "axios";
import { api } from "../constants/urls";
import { useToastifyPromises } from "../helpers/toastifyHelper";

// import {AuthActionSuccess, LogoutAction} from "../actions/AuthActions";

export const useSchedulesApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //1. create schedules
  const createScheduleReq = (body: any) => axios.post(api.schedules + `/admin/schedules`, body);
  const createScheduleToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: createScheduleReq(body),
      pendingMsg: "Scheduling Trip",
      SuccessMsg: "Trip Scheduled Successful",
    });

  //   2.get schedules
  const getSchedulesReq = ({ user_type = "", ride_status = "", ride_class = "", ride_type = "", page = 1, item_per_page = 50, start_date = "", end_date = "", component = "", q = "" }: any) => {
    return axios.get(
      api.schedules +
        `/admin/schedules/?q=${q}&user_type=${user_type}&ride_type=${ride_type}&ride_class=${ride_class}&ride_status=${ride_status}&start_date=${start_date}&end_date=${end_date}&page=${page}&item_per_page=${item_per_page}&component=${component}`
    );
  };
  const getSchedulesToast = (body?: any) => toastifyPromises.get({ asyncFunction: getSchedulesReq(body) });

  //   2.get schedules details
  const getSchedulesDetailsReq = ({ schedules_id = "", status = "" }: any) => {
    return axios.get(api.schedules + `/admin/schedules/${schedules_id}/?ride_status=${status}`);
  };
  const getSchedulesDetailsToast = (body?: any) => toastifyPromises.get({ asyncFunction: getSchedulesDetailsReq(body) });

  //   3.delete a schedule
  const deleteScheduleReq = ({ schedule_id = "" }: any) => axios.delete(api.schedules + `/admin/schedules/${schedule_id}`);
  const deleteScheduleToast = (body?: any) => toastifyPromises.delete({ asyncFunction: deleteScheduleReq(body), pendingMsg: "Deleting Schedule", SuccessMsg: "Schedule deleted successfully" });

  return {
    getSchedulesToast,
    getSchedulesDetailsToast,
    deleteScheduleToast,
    createScheduleToast,
  };
};
