import { HiUsers } from 'react-icons/hi';

export const financeLink = {
  title: 'Finance',
  icon: <HiUsers size={20} />,
  link: 'finance',
  subNavs: [
    {
      title: 'Wallet',
      link: '/finance/wallet',
    },
    {
      title: 'Expected Revenue',
      link: '/finance/expected-revenue',
    },
    {
      title: 'Remitted Revenue',
      link: '/finance/remitted-revenue',
    },
    {
      title: 'Receivables',
      link: '/finance/receivables',
    },
    {
      title: 'Reconciliation',
      link: '/finance/reconciliation',
    },
    // {
    //   title: 'Revenue',
    //   link: '/finance/revenue',
    // },
    {
      title: 'Disbursement',
      link: '/finance/disbursement',
    },
    {
      title: 'Service Payment',
      link: '/finance/service-payment',
    },
  ],
};
