import { ReactNode } from "react";
import { Button } from "reactstrap";

type Props = {
  text?: string | ReactNode;
  onClick?: any;
  forwardArrow?: boolean;
  disabled?: boolean;
  size?: string;
  className?: string;
  type?: string;
};

const GradientButton = (props: Props) => {
  const { text, onClick, forwardArrow, disabled, size, className, type } = props;
  return (
    <Button
      type={type ? (type as "button") : "submit"}
      size={size || "sm"}
      className={`w-100 gradient-btn border-0 font-krub-semibold text-nowrap rounded fw-bold ${className && className}`}
      onClick={onClick}
      disabled={disabled}
      style={{
        padding: "13px 18px",
        fontSize: 14,
      }}
    >
      {text}

      {forwardArrow && (
        <span className="ms-1 ">
          <img alt="arrow" src="/svgs/fwd-arrow.svg" />
        </span>
      )}
    </Button>
  );
};

export default GradientButton;
