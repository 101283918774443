import { Col, Row } from 'reactstrap';

type Props = {
  responseTime: number;
};

const ServiceCards = (props: Props) => {
  const { responseTime } = props;

  // Convert milliseconds to seconds
  const totalSeconds = responseTime ? responseTime / 1000 : 0;

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(totalSeconds / 86400);
  const remainingSecondsAfterDays = totalSeconds % 86400;

  const hours = Math.floor(remainingSecondsAfterDays / 3600);
  const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;

  const minutes = Math.floor(remainingSecondsAfterHours / 60);
  const seconds = Math.floor(remainingSecondsAfterHours % 60);

  const items = [
    {
      title: 'Average assignment time (Internal)',
      subTitle: 'Time it takes to assign a pending request to a service center',
      value: 78,
      timezone: 'Days Hrs Mins Sec',
    },
    {
      title: 'Average response time (Service Center)',
      value: 78,
      subTitle:
        'Time it takes for an assigned request to be accepted by a service center',
      timezone: 'Days Hrs Mins Sec',
    },
    {
      title: 'Request approval time (Ibile)',
      subTitle: 'Time it takes for a service request to be approved',
      value: 78,
      timezone: 'Days Hrs Mins Sec',
    },
    {
      title: 'Request completion time',
      subTitle: 'Time it takes for a pending request to be completed',
      value: 78,
      timezone: 'Days Hrs Mins Sec',
    },
  ];

  return (
    <Row>
      {items.map((item) => (
        <Col key={item.title} className='mt-4 border rounded mx-2'>
          <div
            className='rounded p-3'
            // style={{ backgroundColor: item.cardBgColor, height: 92 }}
          >
            <div className='fs-6 fw-semibold capitalize'>{item.title}</div>
            <div className='mt-1 text-muted'>
              <small>{item.subTitle}</small>
            </div>
            <Row className='rounded py-3 px-1 border mt-3 text-center'>
              <Col md={3}>
                <div className='rounded p-1 border'>
                  <small>{days}</small>
                </div>
                <small className='text-grey'>Days</small>
              </Col>
              <Col md={3}>
                <div className='rounded p-1 border'>
                  <small>{hours}</small>
                </div>
                <small className='text-grey'>Hrs</small>
              </Col>
              <Col md={3}>
                <div className='rounded p-1 border'>
                  <small>{minutes}</small>
                </div>
                <small className='text-grey'>Mins</small>
              </Col>
              <Col md={3}>
                <div className='rounded p-1 border'>
                  <small>{seconds}</small>
                </div>
                <small className='text-grey'>Secs</small>
              </Col>
            </Row>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default ServiceCards;
