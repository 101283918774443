import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { AppPrefrenceContext } from "../../../contexts/AppPrefrenceContext";
import FulfilledAnfUnfulfilled from "./components/funfilledAndUnfulfilled";
import OverviewSumaryCards from "./components/overviewSummaryCards";
import UnasignedAndAssigned from "./components/unassignedAndAssigned";

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setDate: (clear: boolean) => void;
  setStatus: Dispatch<SetStateAction<string>>;
};

const SchedulesOverview = (props: Props) => {
  const { setActiveTab, setDate, setStatus } = props;
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData(["Schedules", "Overview"]);
  }, []);

  return (
    <div>
      {/* <h5 className="fw-bold">Rider Overview</h5> */}

      <div className="">
        <OverviewSumaryCards setActiveTab={setActiveTab} setStatus={setStatus} setDate={setDate} />
      </div>
      <Row className="mt-md-3">
        <Col md={6} className="mt-3">
          <FulfilledAnfUnfulfilled />
        </Col>
        <Col md={6} className="mt-3">
          <UnasignedAndAssigned />
        </Col>
      </Row>
    </div>
  );
};

export default SchedulesOverview;
