const EmptyPage = (props: any) => {
  return (
    <div className="h-100 w-100 text-center">
      <img className="mt-4 mb-2" src="/svgs/empty.svg" height={"auto"} width={"60%"} style={{ maxWidth: 500 }} />
      <p>{props.text}</p>
      {props.button}
    </div>
  );
};

export default EmptyPage;
