import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import { ObjectType } from '../../../../../../types/common';
import {
  calculatePostDate,
  formatTime,
} from '../../../../../../helpers/dateTimeHelper';

type Props = {
  vmrDetails: {
    [key: string]: any;
  };
};

const AboutInfo = (props: Props) => {
  const { vmrDetails } = props;
  const [data, setData] = useState<ObjectType>([]);
  useEffect(() => {
    setData({
      phoneNumber: vmrDetails?.phone_number,
      emailAddress: vmrDetails?.email,
    });
  }, [vmrDetails]);

  const text =
    vmrDetails?.status === 'completed'
      ? 'Completed'
      : vmrDetails?.status === 'ongoing'
      ? 'Ongoing'
      : vmrDetails?.status === 'pending'
      ? 'Pending'
      : vmrDetails?.status === 'accepted'
      ? 'Accepted'
      : vmrDetails?.status === 'rejected'
      ? 'Rejected'
      : vmrDetails?.status === 'awaiting-approval'
      ? 'Awaiting-approval'
      : vmrDetails?.status === 'approved'
      ? 'Approved'
      : 'Operation time set';

  const color =
    vmrDetails?.status === 'completed' || vmrDetails?.status === 'ongoing'
      ? '#00d2a8'
      : vmrDetails?.status === 'pending'
      ? '#fe9603'
      : vmrDetails?.status === 'rejected'
      ? '#ff3739'
      : vmrDetails?.status === 'awaiting-approval' ||
        vmrDetails?.status === 'approved'
      ? '#6212b1'
      : vmrDetails?.status === 'accepted'
      ? '#2fb5d9'
      : '#002ed0';
  return (
    <div>
      <Card body className=' p-2 capitalize'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-vmrDetailss-center px-3 pb-3 mt-1 flex-grow-1'>
            <div className='flex-grow-1'>
              <div className=''>
                <div className='text-blue fw-semibold'>About</div>
              </div>
              <Row className='w-100'>
                <Col className='mt-2' >
                  <div>
                    <small className='text-grey'>Request Type</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {vmrDetails?.service_type}
                  </div>
                </Col>
                <Col className='mt-2' >
                  <div>
                    <small className='text-grey'>Vehicle Model</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {vmrDetails?.vehicle_model}
                  </div>
                </Col>
                <Col className='mt-2' >
                  <div>
                    <small className='text-grey'>Request Status</small>
                  </div>
                  <div className='mt-1 fw-semibold'>{vmrDetails.status}</div>
                </Col>
                {vmrDetails?.status !== 'completed' && (
                  <Col className='mt-2' >
                    <div>
                      <small className='text-grey'>Date Of Request</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {calculatePostDate(vmrDetails?.createdAt)}
                    </div>
                  </Col>
                )}
                {vmrDetails?.status === 'completed' && (
                  <>
                    <Col className='mt-2' >
                      <div>
                        <small className='text-grey'>Start Date</small>
                      </div>
                      <div className='mt-1 fw-semibold'>
                        {calculatePostDate(vmrDetails?.start_time)}
                      </div>
                    </Col>
                    <Col className='mt-2' >
                      <div>
                        <small className='text-grey'>End Date</small>
                      </div>
                      <div className='mt-1 fw-semibold'>
                        {calculatePostDate(vmrDetails?.completion_time)}
                      </div>
                    </Col>
                  </>
                )}
                <Col className='mt-2' >
                  <div>
                    <small className='text-grey'>Status</small>
                  </div>

                  <Button
                    className={` border-0 font-krub-semibold text-nowrap rounded fw-bold py-1 px-3 rounded-pill mt-1 text-capitalize`}
                    style={{
                      padding: '13px 18px',
                      fontSize: 14,
                      backgroundColor: color,
                    }}
                  >
                    {text}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AboutInfo;
