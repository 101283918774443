import { useQuery } from 'react-query';
import { useSetupApiService } from '../services/setupApiServices';
import queryKeys from './queryKeys';
import { ObjectType } from '../types/common';

const useOperationsSettings = () => {
  [
    {
      name: 'Daily Target Amount',
      tag: 'DAILY_TARGET',
      slug: 'DRIVER_PERFORMANCE',
      screen_name: 'driver_performance',
      amount: 344444,
      desc: 'placeholder text',
      system_generated: false,
      data: [],
      createdAt: '2024-05-13T16:38:13.329Z',
      updatedAt: '2024-05-13T16:39:38.887Z',
      fee_id: '664241f5e0f17fd7d8f5e09a',
    },
    {
      name: 'Daily Kilometres',
      tag: 'DAILY_KILOMETRE',
      slug: 'DRIVER_PERFORMANCE',
      screen_name: 'driver_performance',
      amount: 237,
      desc: 'placeholder text',
      system_generated: false,
      data: [],
      createdAt: '2024-05-13T17:13:53.630Z',
      updatedAt: '2024-05-13T17:16:10.422Z',
      fee_id: '66424a51e0f17fd7d8f5e0da',
    },
    {
      name: 'Physical cash limit',
      tag: 'TRIP_CASH_LIMIT',
      slug: 'TRIP_CASH_LIMIT',
      screen_name: 'driver_performance',
      amount: 23,
      desc: 'placeholder text',
      system_generated: false,
      data: [],
      createdAt: '2024-05-13T17:16:19.183Z',
      updatedAt: '2024-05-13T17:16:19.183Z',
      fee_id: '66424ae3e0f17fd7d8f5e0fb',
    },
  ];

  const { getFeesToast, updateFeeToast, deleteFeeToast } = useSetupApiService();

  const { data } = useQuery(
    queryKeys.feesSetup,
    () => getFeesToast({ screen_name: 'driver_performance' }),
    {
      select: (data) => data?.data,
    }
  );

  const findItem = (tag: string) =>
    data?.find((item: ObjectType) => item.tag === tag)?.amount || 0;

  const vehicleDailyExp = findItem('DRIVER_PERFORMANCE_DAILY_TARGET');
  console.log(vehicleDailyExp);
  const tripsDailyExp = 10;
  //this marks the minimum expectation in percentage
  const minumExpectation = 0.7; //currently 70%
  return {
    vehicleDailyExp,
    tripsDailyExp,
    minumExpectation,
  };
};

export default useOperationsSettings;
