import "chart.js/auto";
import { useState } from "react";

import { useQuery } from "react-query";
import { Card, CardTitle } from "reactstrap";
import NewFilter from "../../../../components/filter/NewFilter";
import FilterComponent from "../../../../components/shared/filterComponent";
import queryKeys from "../../../../constants/queryKeys";
import { getFirstDayOfMonth, getLastDayOfMonth } from "../../../../helpers/dateTimeHelper";
import { useWalletApiService } from "../../../../services/walletApiServices";

// import DateTypeFilter from "./filters/DateTypeFilter";

const PaymentsType = () => {
  // const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  // const [endDate, setEndDate] = useState(getTodayDate());
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const [toggled, setToggled] = useState(false);
  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });

  const { getTripPaymentToast } = useWalletApiService();

  const handleToggle = () => {
    setToggled((prevStatus) => !prevStatus);
  };

  const onApply = () => {
    setFilterState({ startDate, endDate });
    handleToggle();
  };

  const { data: tripPaymentTypes } = useQuery([queryKeys.tripsPaymentTypes, filterState], () => getTripPaymentToast({ component: "count-payment-type", start_date: startDate, end_date: endDate }), {
    select: (data) => data?.data,
  });

  const paymentTypes = [
    { type: "Cash", amount: tripPaymentTypes?.amount_cash || 0, number: tripPaymentTypes?.total_cash || 0 },
    { type: "Wallet", amount: tripPaymentTypes?.amount_wallet || 0, number: tripPaymentTypes?.total_wallet || 0 },
    { type: "Debit Card", amount: tripPaymentTypes?.amount_card || 0, number: tripPaymentTypes?.total_card || 0 },
    { type: "Bank Transfer", amount: tripPaymentTypes?.amount_transfer || 0, number: tripPaymentTypes?.total_transfer || 0 },
  ];

  const headings = [
    { title: "Payment type", width: "50%" },
    { title: "Amount", width: "25%" },
    { title: "No. of Trips", width: "25%" },
  ];

  //   {
  //     "_id": null,
  //     "total_cash": 8,
  //     "total_wallet": 60,
  //     "total_transfer": 5,
  //     "total_card": 0,
  //     "amount_cash": 6850,
  //     "amount_wallet": 77150,
  //     "amount_transfer": 3550,
  //     "amount_card": 0
  // }

  return (
    <Card body className="h-100 p-3">
      <CardTitle className=" justify-content-between">
        <div className="justify-content-end d-flex w-100 mb-1">
          <NewFilter
            direction={"start"}
            toggleComponent={<FilterComponent />}
            toggled={toggled}
            handleToggle={handleToggle}
            // options={filterOptions}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            onApply={onApply}
          />
        </div>
      </CardTitle>

      <div className="mb-2 d-flex">
        {headings.map((item, index) => (
          <div className={`${index === headings.length - 1 && "text-end"} fw-semibold`} style={{ width: item.width }}>
            {item.title}
          </div>
        ))}
      </div>
      {paymentTypes.map((item, index) => (
        <div key={item.type} className={`d-flex justify-content-between pt-4 pb-2 ${index !== paymentTypes.length - 1 && "border-bottom"}`}>
          <div style={{ width: headings[0].width }}>{item.type}</div>
          <div className="fw-semibold" style={{ width: headings[1].width }}>
            ₦{item.amount.toLocaleString()}
          </div>
          <div className="text-end fw-semibold" style={{ width: headings[2].width }}>
            {item.number.toLocaleString()}
          </div>
        </div>
      ))}

      {/* <div>
        <div className="d-flex">
          {["Payment type", "Amount", "No. of Trips"].map((item) => (
            <div className="flex-fill">{item}</div>
          ))}
        </div>
        {paymentTypes.map((item) => (
          <div key={item.type} className="d-flex justify-content-between mb-5">
              <div className="flex-fill">₦{item.amount.toLocaleString()}</div>
              <div className="flex-fill">{item.amount}</div>
              <div className="flex-fill">{item.number}</div>
          </div>
        ))}
      </div> */}
    </Card>
  );
};

export default PaymentsType;
