import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CusCard from "../../../components/shared/cusCard";
import { useQueryStringHelper } from "../../../helpers/queryStringHelper";

import TripsPaymentLog from "./tripsPaymentLog";
import TripsPaymentOverview from "./tripsPaymentOverview";

const TripPayments = () => {
  const { getQueryStringValue } = useQueryStringHelper();
  const tabs = ["Overview", "Trips Payment Log"];
  const [status, setStatus] = useState("");
  const [requestType, setRequestType] = useState("");
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate();

  useEffect(() => {
    if (getQueryStringValue("payment-id")) setActiveTab("Trips Payment Log");
  }, []);

  return (
    <CusCard className="p-0" style={{ minHeight: "70vh" }}>
      <div className="d-flex">
        {tabs.map((item) => (
          <div
            className={`px-3 bg-none cursor-pointer ${activeTab === item && "gradient-btn text-white fw-bold"} ${item === "Profile" && activeTab !== "Profile" && "d-none"}`}
            key={item}
            style={{ paddingTop: "11px", paddingBottom: "11px" }}
            onClick={() => {
              item !== "Trips Payment Log" && navigate("/trips-payment");
              setActiveTab(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="p-3 p-sticky" style={{ top: 0 }}>
        {activeTab === "Overview" && <TripsPaymentOverview setStatus={setStatus} setRequestType={setRequestType} setActiveTab={setActiveTab} />}
        {activeTab === "Trips Payment Log" && <TripsPaymentLog status={status} requestType={requestType} setActiveTab={setActiveTab} />}
      </div>
    </CusCard>
  );
};

export default TripPayments;
