import { Dispatch, SetStateAction, useContext, useEffect } from 'react';

import OverviewSumaryCards from './components/overviewSummaryCards';
import PromoTrend from './components/referralTrend';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setReferralType: Dispatch<SetStateAction<string>>;
};
const ReferralOverview = (props: Props) => {
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const { setReferralType, setActiveTab, } = props;


  useEffect(() => {
    setBreadCrumbData(['Referral', 'Overview']);
  }, []);
  return (
    <div>
      <div>
        <OverviewSumaryCards
          setActiveTab={setActiveTab}
          setReferralType={setReferralType}

        />
      </div>

      <div className='mt-5'>
        <PromoTrend />
      </div>
    </div>
  );
};

export default ReferralOverview;
