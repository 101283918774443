import { ObjectType } from "../../../../../../types/common.tsx";
import OneColumn from "../OneColumn.tsx.tsx";
import Title from "../title.tsx";

type Props = {
  tripOperationsPerformance: ObjectType;
};

// {
//   "total_assigned": 62,
//   "total_rest": 2,
//   "total_suspended": 8,
//   "total_online": 0,
//   "created": "2024-02-21"
// }

const VehiclePerformance = (props: Props) => {
  const { tripOperationsPerformance } = props;
  const getItems = (key: string) => tripOperationsPerformance?.map((item: ObjectType) => item?.vehicle_log?.[key] || 0);
  const getItemsOfflineCount = () => tripOperationsPerformance?.map((item: ObjectType) => (item?.vehicle_log?.["total_online"] !== undefined ? item?.vehicle_log?.["total_assigned"] - item?.vehicle_log?.["total_online"] : ""));
  const calcPercOnline = () => tripOperationsPerformance?.map((item: ObjectType) => (item?.vehicle_log?.total_online !== undefined ? ((item?.vehicle_log?.total_online / item?.vehicle_log?.total_assigned) * 100).toFixed(1) + "%" : "0%"));
  const calcPercOffline = () =>
    tripOperationsPerformance?.map((item: ObjectType) => (item?.vehicle_log?.total_online !== undefined ? (((item?.vehicle_log?.total_assigned - item?.vehicle_log?.total_online) / item?.vehicle_log?.total_assigned) * 100).toFixed(1) + "%" : ""));
  return (
    <div className="px-4 ms-1" style={{ minWidth: 840, background: "#f1f2f6" }}>
      <Title text="Vehicle Performance" />
      {/* Next lne points to each row */}
      <div className="d-flex  text-nowrap justify-content-around">
        <OneColumn subTitle={"Assigned Veh."} items={getItems("total_assigned") || ""} />
        <OneColumn subTitle={"Rest Day"} items={getItems("total_rest") || ""} />
        <OneColumn subTitle={"Suspended Veh."} items={getItems("total_suspended") || ""} />
        <OneColumn subTitle={"Active by No."} items={getItems("total_online") || ""} />
        <OneColumn subTitle={"Active by %"} items={calcPercOnline()} />
        <OneColumn subTitle={"Inactive by No."} items={getItemsOfflineCount()} />
        <OneColumn subTitle={"Inactive by %"} items={calcPercOffline()} />
      </div>
    </div>
  );
};

export default VehiclePerformance;
