import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Modal, ModalBody, Table } from "reactstrap";
import EmptyPage from "../../../components/EmptyPage";
import NewFilter from "../../../components/filter/NewFilter";
import ExportComponent from "../../../components/shared/exportComponent";
import FilterComponent from "../../../components/shared/filterComponent";
import GradientButton from "../../../components/shared/gradidentButton";
import WaitingLoader from "../../../components/shared/waitingLoader";
import PaginationComponent from "../../../components/tablesComponents/paginationComponent";
import SeearchComponent from "../../../components/tablesComponents/searchComponent";
import TableDataCounter from "../../../components/tablesComponents/tableDataCounter";
import queryKeys from "../../../constants/queryKeys";
import { AppPrefrenceContext } from "../../../contexts/AppPrefrenceContext";
import { calculatePostDate, formatByDateType, formatTime } from "../../../helpers/dateTimeHelper";
import { usePaginationHelper } from "../../../helpers/paginationHelper";
import { useQueryStringHelper } from "../../../helpers/queryStringHelper";
import { useSchedulesApiService } from "../../../services/schedulesApiServices";
import { ObjectType, ReactChangeEventType } from "../../../types/common";
import DriverTripsDetails from "../users/drivers/components/driverDetailsComponents/driverDetailsComponents/driverTripsDetails";
import CreateScehduleModalBody from "./components/createSchduleModalBody";
import SchedulesDetails from "./components/scheduleDetails";
import { geScheduleStatus, geScheduleStatusColor, scheduleStatusOptions } from "./data";

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  startDate: string;
  endDate: string;
  status: string;
};
const SchedulesLog = (props: Props) => {
  const { startDate: propsStartDate, endDate: propsEndDate, status: propsStatus } = props;

  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState(propsStartDate || "");
  const [endDate, setEndDate] = useState(propsEndDate || "");
  const { getPageNoFromQueryString, getQueryStringValue, attachQueryStringToUrl } = useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const [keyword, setKeyword] = useState("");
  const [scheduleId, setScheduleId] = useState("");
  const [status, setStatus] = useState(propsStatus || "0");
  const [statusHolder, setStatusHolder] = useState(status);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [editingId, setEditingId] = useState("");
  const [data, setData] = useState<ObjectType | {}>({});
  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
    status,
  });

  const { setBreadCrumbData, deleteModalConfig } = useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData(["Schedules", "Schedules Log"]);
  }, []);

  const { getSchedulesToast, deleteScheduleToast } = useSchedulesApiService();

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const { isLoading, data: schedules } = useQuery(
    [queryKeys.schedules, currentPage, keyword, filterState],
    () =>
      getSchedulesToast({
        start_date: startDate,
        end_date: endDate,
        page: currentPage,
        ride_status: status,
        q: keyword,
      }),
    {
      select: (data) => data?.data || [],
      keepPreviousData: true,
    }
  );

  const { data: schedulesCount } = useQuery(
    [queryKeys.schedulesCount, currentPage, keyword, filterState],
    () =>
      getSchedulesToast({
        start_date: startDate,
        end_date: endDate,
        ride_status: status,
        // ride_type: "schedule",
        // q: keyword,
        component: "count",
      }),
    {
      select: (data) => data?.data[0]?.total || 0,
      keepPreviousData: true,
    }
  );

  const { refetch: exportSchedule } = useQuery(
    [queryKeys.schedulesExport],
    () =>
      getSchedulesToast({
        start_date: startDate,
        end_date: endDate,
        ride_status: status,
        // ride_type: "schedule",
        // q: keyword,
        component: "export",
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
      enabled: false,
    }
  );

  const deleteScheduleMutation = useMutation(deleteScheduleToast, {
    onSuccess: async (res) => {
      if (res.data.status !== "error") {
        handleToggle();
        queryClient.invalidateQueries(queryKeys.schedules);
      }
    },
    // onError: (err) => {},
  });

  const filterOptions = [
    {
      title: "Status",
      options: scheduleStatusOptions,
      currentValue: status,
      onChange: setStatus,
      name: "scheduleStatus",
    },
  ];

  const onApply = () => {
    setFilterState({ startDate, endDate, status });
    // setStatusHolder(status);
    setStatus(status);
    handleToggle();
  };

  const handleChange = (e: ReactChangeEventType) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleEdit = (data: ObjectType) => {
    setEditingId(data.area_id);
    setData({
      name: data?.area_name,
      lga: data?.lga,
      long: data?.location?.coordinates[0],
      lat: data?.location?.coordinates[0],
    });
    setModalOpen(true);
  };

  if (isLoading) {
    return <WaitingLoader />;
  } else if (scheduleId || getQueryStringValue("schedule-id")) {
    return (
      <div>
        {status !== "0" ? (
          <DriverTripsDetails
            tripId={getQueryStringValue("schedule-id") || scheduleId}
            // viewedTrip={viewedTrip}
            setTripId={setScheduleId}
            scheduleStatus={status}
          />
        ) : (
          <SchedulesDetails
            // driverDetails={driverDetails}
            schedule={schedules}
            scheduleId={getQueryStringValue("schedule-id") || scheduleId}
            setScheduleId={setScheduleId}
            statusHolder={status}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between text-nowrap flex-wrap">
        <div className="d-flex align-items-center">
          {/* <div className="fw-bold">{getRiderType(riderType)} Riders</div> */}
          <div className="">
            <div className="d-flex align-items-center  ">
              <div className="fw-bolder fs-5">{`Schedules Trips`}</div>
            </div>
          </div>
          <div className="ms-4">{<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}</div>
        </div>
        <div className="d-flex align-items-center">
          {/* <div className="me-3">
            <DateRangeComponent direction={"start"} filterToggle={filterToggle} handleFilterToggle={handleFilterToggle} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />
          </div> */}
          <div className="me-3" style={{ width: 150 }}>
            <GradientButton text="Schedule a Trip" className="p-1" onClick={() => setModalOpen(true)} />
          </div>
          <div className="me-3">
            <NewFilter
              direction={"start"}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              options={filterOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />

            {/* <FilterOptions width={200} direction={"start"} toggleComponent={<FilterComponent />} toggled={toggled} handleToggle={handleToggle} options={riderTripsFilterOptions} selectedOption={tripsType} optionChange={handleTypeChange} name="trips_table_filter" />{" "} */}
          </div>

          <div>{schedules?.length > 0 && <ExportComponent onClick={exportSchedule} />}</div>
        </div>
      </div>

      <div className="mt-2">
        {schedules?.length === 0 && <EmptyPage text="No Trip Payments" />}
        {schedules?.length > 0 && (
          <Table responsive className="table-hover">
            <thead className="">
              <tr className="">
                <th className="">Ride Date</th>
                <th className="">Created By</th>
                {/* {statusHolder !== "0" && <th className="">Reference</th>} */}
                {statusHolder === "0" && <th className="">Rider Name</th>}

                <th className="">Pick-up</th>
                <th className="">Drop-off</th>
                {statusHolder === "3" && <th className="">Assigned Driver</th>}

                <th className="">Pickup LGA</th>
                <th className="">Status</th>
                <th className=""></th>
              </tr>
            </thead>

            <tbody>
              {schedules.map((item: any, index: number) => (
                <Fragment key={index}>
                  <tr
                    className="cursor-pointer"
                    onMouseOver={() => setHoveredItem(index)}
                    onClick={() => {
                      const usedId = item?.request_status === "1" ? item?.trip_id : item?.request_status === "0" ? item?.schedule_id : item?.schedule_group_id;
                      setScheduleId(usedId);
                      attachQueryStringToUrl("schedule-id", usedId);
                    }}
                  >
                    <td>{formatTime(item?.ride_schedule).fullDateTime}</td>
                    <td className="text-capitalize">{item.created_by}</td>
                    {/* {statusHolder !== "0" && <td>{item.schedule_ref}</td>} */}
                    {statusHolder === "0" && (
                      <td>
                        {item?.rider_data?.first_name} {item?.rider_data?.last_name}
                      </td>
                    )}

                    <td>{item?.start_address.length > 30 ? item?.start_address.slice(0, 30) + " ..." : item?.start_address}</td>
                    <td>{item?.end_address.length > 30 ? item?.end_address.slice(0, 30) + " ..." : item?.end_address}</td>

                    {statusHolder === "3" && (
                      <th className="">
                        {item?.driver_data?.first_name} {item?.driver_data?.last_name}
                      </th>
                    )}
                    <td className="text-capitalize">{item?.request_area_data?.lga}</td>

                    <td className={`fw-bold ${geScheduleStatusColor(item?.request_status)}`}>{geScheduleStatus(item?.request_status)}</td>
                    <td>
                      <div className={`d-flex ${index !== hoveredItem && "d-none"}`}>
                        {/* <span
                          className={`cursor-pointer`}
                          style={{ zIndex: 5 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(item);
                          }}
                        >
                          <img src="/svgs/edit.svg" alt="" />
                        </span> */}
                        <span
                          className="ms-3 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteModalConfig({
                              firstText: `Delete This Schedule`,
                              secondText: `This action would remove this schedule from the portal and is irreversible.`,
                              callback: () =>
                                deleteScheduleMutation.mutate({
                                  schedule_id: item.schedule_id,
                                }),
                            });
                          }}
                        >
                          <img src="/svgs/delete.svg" alt="delete svg" />
                        </span>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        )}
        {schedules?.length > 0 && (
          <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
            <div className="mt-2">
              <TableDataCounter item={schedules} currentPage={currentPage} totalCount={schedulesCount} />
            </div>

            <div className="mt-2">
              <PaginationComponent activePage={currentPage} itemsCountPerPage={50} totalItemsCount={schedulesCount} onChange={onPaginate} />
            </div>
          </div>
        )}
      </div>
      <Modal size="lg" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} backdrop="static">
        <ModalBody className="py-4 px-4">
          <div className="d-flex justify-content-between mb-3">
            <div className="fw-bold">{`${!editingId ? "Schedule a Trip" : "Update a Schedule"}`}</div>
            <AiOutlineClose size={20} onClick={() => setModalOpen(false)} />
          </div>
          <CreateScehduleModalBody data={data} handleChange={handleChange} refresh={() => setData({})} handleToggle={() => setModalOpen(!modalOpen)} editingId={editingId} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default SchedulesLog;
