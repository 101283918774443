import useOperationsSettings from '../../../../../../constants/operations.tsx';
import { ObjectType } from '../../../../../../types/common.tsx';
import OneColumn from '../OneColumn.tsx';
import Title from '../title';

type Props = {
  tripOperationsPerformance: ObjectType;
};

const RevenuePerformance = (props: Props) => {
  const { tripOperationsPerformance } = props;
  const { vehicleDailyExp, minumExpectation } =
    useOperationsSettings();

  const getRevenue = () =>
    tripOperationsPerformance?.map((item: ObjectType) =>
      item?.['total_revenue']?.toLocaleString()
    ) || '';

  const calcPerc = () =>
    tripOperationsPerformance?.map((item: ObjectType) =>
      item?.total_revenue !== undefined &&
      item?.vehicle_log?.total_assigned !== undefined
        ? (
            (item?.total_revenue /
              (item?.vehicle_log?.total_assigned *
                vehicleDailyExp *
                minumExpectation)) *
            100
          ).toFixed(1) + '%'
        : ''
    );

  console.log(minumExpectation);

  return (
    <div className='px-4 ms-1' style={{ minWidth: 340, background: '#f5fff8' }}>
      <Title text='Revenue Performance' />
      {/* Next lne points to each row */}
      <div style={{ width: 240 }}>
        <div className='d-flex text-nowrap justify-content-between'>
          <OneColumn subTitle={'Actual Revenue'} items={getRevenue()} />
          <OneColumn subTitle={'Target'} items={calcPerc()} />
        </div>
      </div>
    </div>
  );
};

export default RevenuePerformance;
