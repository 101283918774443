import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { Button, Card, Form, FormGroup, Input, Label } from "reactstrap";
import { LoadingContext } from "../../contexts/LoadingContext";
import { useAuthApiServices } from "../../services/AuthApiServices";

const InitiatePasswordReset = () => {
  const { postLoading } = useContext(LoadingContext);
  const { initiatePasswordResetToast } = useAuthApiServices();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const initiateResetMutation = useMutation(initiatePasswordResetToast, {
    onSuccess: async (res) => {
      const resData = res.data.status;
      resData === "ok" && navigate("/signin");
    },
  });

  const handleSubmit = (e: { preventDefault: () => void }) => {
    const body = { email };
    e.preventDefault();
    initiateResetMutation.mutate(body);
  };

  return (
    <div className="px-2 mt-5">
      <Card style={{ width: 370 }} className="bg-white text-dark px-4">
        <h3 className="text-center my-3 fw-bolder">Forgot Password</h3>
        <Form onSubmit={handleSubmit}>
          <FormGroup className="bg-white">
            <Label for="tel">Email</Label>
            <Input id="email" name="email" placeholder="Enter email" type="email" className="bg-transparent" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormGroup>
          <Button disabled={!email || postLoading} className="w-100 gradient-btn fw-bold">
            {postLoading ? "LOADING..." : "PROCEED"}
          </Button>
          <div className="text-muted mt-1 mt-2 mb-4">
            <i>If email is valid, a default password will be sent to you.</i>
          </div>

          <div className="fw-bold text-center gradient-text my-3 cursor-pointer" onClick={() => navigate("/signin")}>
            Sign In
          </div>
        </Form>
      </Card>
    </div>
  );
};
export default InitiatePasswordReset;
