import axios from 'axios';
import { api } from '../constants/urls';
import { useToastifyPromises } from '../helpers/toastifyHelper';

// import {AuthActionSuccess, LogoutAction} from "../actions/AuthActions";

export const useActivityLogApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //get activity log list
  const getActivityLogReq = ({
    page = 1,
    item_per_page = 50,
    q = '',
    start_date = '',
    end_date = '',
    component = '',
  }: any) => {
    return axios.get(
      api.users +
        `/admin/admin-logs?page=${
          q ? '' : page
        }&item_per_page=${item_per_page}&q=${q}&start_date=${start_date}&end_date=${end_date}&component=${component}`
    );
  };
  const getActivityLogToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getActivityLogReq(body) });

  //getActivityLogDetails
  const getActivityLogDetailsReq = ({ auth_id = '', component = '' }: any) => {
    return axios.get(api.users + `/admin/admin-logs/${auth_id}?component=${component}`);
  };
  const getActivityLogDetailsToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getActivityLogDetailsReq(body) });

  return {
    getActivityLogToast,
    getActivityLogDetailsToast,
  };
};
