import "chart.js/auto";
import { useState } from "react";

import { Line } from "react-chartjs-2";
import { Card, CardTitle, Col } from "reactstrap";
import { ObjectType, ReactChangeEventType } from "../../../../types/common";

interface Props {
  tripsMonthlyCount: ObjectType[];
}
const RidersDriversCancellation = (props: Props) => {
  const { tripsMonthlyCount } = props;
  // const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  // const [endDate, setEndDate] = useState(getTodayDate());
  const [dateType, setDateType] = useState("This week");
  const [status, setStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [toggled, setToggled] = useState(false);
  const [statusToggled, setStatusToggled] = useState(false);
  const handleToggle = () => {
    setToggled((prevStatus) => !prevStatus);
  };
  const handleDateTypeChange = (e: ReactChangeEventType) => setDateType(e.target.value);

  const handleStatusToggled = () => {
    setStatusToggled((prevStatus) => !prevStatus);
  };
  const handleStatusChange = (e: ReactChangeEventType) => setStatus(e.target.value);
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: "white",
        },
        beginAtZero: true,
      },
      y: {
        grid: {
          display: false,
          borderColor: "white",
        },
        ticks: {
          min: 0,
          max: 15,
          stepSize: 5000,
        },
        beginAtZero: true,
        // steps: 4
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
  };
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Completed trips",
        data: new Array(12).fill(0).map((_, index) => {
          return tripsMonthlyCount?.find((item) => item?.month === index + 1)?.total_completed || 0;
        }), // fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#00D2A8",
        borderWidth: 1,
        pointRadius: 2,
        // hoverBorderJoinStyle: "miter",
      },

      {
        label: "Total Cancelled",
        data: new Array(12).fill(0).map((_, index) => {
          return tripsMonthlyCount?.find((item) => item?.month === index + 1)?.total_cancel || 0;
        }), // fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        borderWidth: 1,

        borderColor: "#fe4c4e",

        pointRadius: 2, // hoverBorderJoinStyle: "miter",
      },
    ],
  };
  const dateTypeOption = [
    { value: "This week", label: "This week" },
    { value: "This month", label: "This month" },
    { value: "Last 6 months", label: "Last 6 months" },
  ];
  return (
    <Col md={12} className="mt-2">
      <Card body>
        <CardTitle className=" justify-content-between">
          <div className="justify-content-between d-flex w-100 mb-3">
            <div className="fw-bold">Completed and Cancelled Trips ~ Monthly </div>
            {/* <FilterOptions toggled={toggled} handleToggle={handleToggle} options={dateTypeOption} selectedOption={dateType} optionChange={handleDateTypeChange} name="projection" /> */}
          </div>
        </CardTitle>
        <div>
          <Line data={data} options={options} />
        </div>
      </Card>
    </Col>
  );
};

export default RidersDriversCancellation;
