import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import FilterOptions from '../../../components/filter/Filter';
import MonthsCalender from '../../../components/filter/monthsCalender';
import YearsCalender from '../../../components/filter/yearsCalender';
import YearsRangeCalender from '../../../components/filter/yearsRangeCalender';
import queryKeys from '../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';
import { getCurrentMonthAndYear } from '../../../helpers/dateTimeHelper';
import { useOemApiService } from '../../../services/oemApiServices';
import { ReactChangeEventType } from '../../../types/common';
import OverviewSumaryCards from './components/overviewSumaryCards';
import ServiceCards from './components/serviceCards';
import VmrAacChart from './components/vmrAacChart';
import VmrPracChart from './components/vmrPracChart';

type Props = {
  vmrType: string;
  setVmrType: Dispatch<SetStateAction<string>>;
  setActiveTab: Dispatch<SetStateAction<string>>;
};

const VmrOverview = (props: Props) => {
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData(['Overview']);
  }, []);
  const [startDate, setStartDate] = useState(getCurrentMonthAndYear());
  const [endDate, setEndDate] = useState(getCurrentMonthAndYear());
  const [dateType, setDateType] = useState('monthly');
  const [toggled, setToggled] = useState(false);
  const handleToggle = () => {
    setToggled(!toggled);
  };
  const handleDateTypeChange = (e: ReactChangeEventType) => {
    setStartDate(getCurrentMonthAndYear());
    setEndDate(getCurrentMonthAndYear());
    setDateType(e.target.value);
  };

  const dateTypeOption = [
    {
      value: 'daily',
      label: 'Daily',
      subOptionComponent: (
        <MonthsCalender
          handleToggle={handleToggle}
          month={startDate}
          setMonth={(month) => {
            setStartDate(month);
            setEndDate(month);
          }}
        />
      ),
    },
    {
      value: 'monthly',
      label: 'Monthly',
      subOptionComponent: (
        <YearsCalender
          handleToggle={handleToggle}
          month={startDate}
          setYear={(year) => {
            setStartDate(year);
            setEndDate(year);
          }}
        />
      ),
    },
    {
      value: 'yearly',
      label: 'Yearly',
      subOptionComponent: (
        <YearsRangeCalender
          handleToggle={handleToggle}
          startYear={startDate}
          endYear={endDate}
          setYears={(start, end) => {
            setStartDate(start);
            setEndDate(end);
          }}
        />
      ),
    },
  ];

  const { getResponseTimeToast } = useOemApiService();
  const { data: responseTime } = useQuery(
    [queryKeys.responseTime, startDate, endDate],
    () =>
      getResponseTimeToast({
        start_date: startDate,
        end_date: endDate,
        group_stage: dateType,
        distribution_type: 'response_time',
      }),
    {
      select: (data) => data?.data[0]?.averageResponseTime,
    }
  );

  const { setVmrType, setActiveTab } = props;
  return (
    <div>
      <h5 className='fw-bold'>VMR Overview</h5>

      <div>
        <div className='fs-6 fw-semibold mt-3'>All Requests</div>
        <OverviewSumaryCards
          setActiveTab={setActiveTab}
          setVmrType={setVmrType}
        />

        <div className='fs-6 fw-semibold mt-5'>Request Status</div>
        <OverviewSumaryCards
          mode='status'
          setActiveTab={setActiveTab}
          setVmrType={setVmrType}
        />
      </div>
      <div>
        <div className='d-flex align-items-center mt-5'>
          <div className='fs-6 fw-semibold'>Response Time</div>
          <div className='ms-3'>
            <FilterOptions
              toggled={toggled}
              handleToggle={handleToggle}
              options={dateTypeOption}
              selectedOption={dateType}
              optionChange={handleDateTypeChange}
              name='registration-chart'
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
        <ServiceCards responseTime={responseTime} />
      </div>

      <Row className='my-5'>
        <Col md={6}>
          <VmrPracChart />
        </Col>
        <Col md={6}>
          <VmrAacChart />
        </Col>
      </Row>
    </div>
  );
};

export default VmrOverview;
