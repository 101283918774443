import { FunctionComponent, lazy } from "react";
// import oldFinanceRoutes from "./routeGroups/oldFinanceRoutes";
// import oldPerformanceRoutes from "./routeGroups/oldPerformanceRoutes";
import setupRoutes from "./routeGroups/setupRoutes";
import usersRoute from "./routeGroups/usersRoute";
import Trips from "../Pages/others/trips";
import vehicleRoutes from "./routeGroups/vehicleRoutes";
import Vmr from "../Pages/others/vmr";
import Promo from "../Pages/others/promo";
import Referrals from "../Pages/others/referrals";
import TripPayments from "../Pages/others/trip-payments";
import Schedules from "../Pages/others/schedules";
import financeRoutes from "./routeGroups/financeRoutes";
import operationsRoutes from "./routeGroups/operationsRoutes";
import Notifications from "../Pages/others/notifications";
import InitiatePasswordReset from "../Pages/auth/InitiatePasswordReset";
import Emergency from "../Pages/others/emergency";
import ActivityLog from "../Pages/others/activity-log";
import Profile from "../Pages/others/profile";
const Signin = lazy(() => import("../Pages/auth/Signin"));

//Overview
const Home = lazy(() => import("../Pages/others/home/Home"));
interface Routes {
  path?: string;
  component?: FunctionComponent;
  title?: string;
  permission?: string;
  children?: { path: string; component: FunctionComponent; title: string }[];
}

export const authRoutes = [
  { path: "/signin", component: Signin, title: "Signin", leadText: "" },
  { path: "/forgot-password", component: InitiatePasswordReset, title: "Reset Password", leadText: "" },
];

export const userRoutes: Routes[] = [
  { path: "/", component: Home, title: "Home", permission: "" },
  { path: "/profile", component: Profile, title: "Profile", permission: "" },
  { path: "/", component: Home, title: "Home", permission: "" },
  setupRoutes,
  // oldPerformanceRoutes,
  // oldFinanceRoutes,
  usersRoute,
  vehicleRoutes,
  { path: "/trips", component: Trips, title: "Trips", permission: "" },
  {
    path: "/trips-payment",
    component: TripPayments,
    title: "Trips Payment",
    permission: "",
  },
  {
    path: "/schedules",
    component: Schedules,
    title: "Schedules",
    permission: "",
  },
  financeRoutes,
  operationsRoutes,
  {
    path: "/vmr",
    component: Vmr,
    title: "Vehicle Maintenance Request",
    permission: "",
  },
  {
    path: "/referrals",
    component: Referrals,
    title: "Referrals",
    permission: "",
  },
  {
    path: "/promo",
    component: Promo,
    title: "Promo",
    permission: "",
  },
  {
    path: "/emergency",
    component: Emergency,
    title: "Emergency",
    permission: "",
  },
  {
    path: "/notification",
    component: Notifications,
    title: "Notification",
    permission: "",
  },
  {
    path: "/activity-log",
    component: ActivityLog,
    title: "Activity Log",
    permission: "",
  },
];

// TODO: 404, 500
