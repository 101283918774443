import { Dispatch, SetStateAction } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router";
// import { Card, Col, Row } from "reactstrap";
// import { calculatePostDate, formatTime } from "../../../../helpers/dateTimeHelper";
// import GradientButton from "../../../../components/shared/gradidentButton";
// import Prototype from "../../users/drivers/components/driverDetailsComponents/driverDetailsComponents/profileCards/protoType";
import ScheduleTripsDetails from "./detailsCards/scheduleTripsDetails";
import SchedulesFare from "./detailsCards/faresSchedules";
import SchedulesTime from "./detailsCards/schedulesTime";
import ScheduleDriverAndVehicle from "./detailsCards/schedulesDriverAndVehicle";
import SchedulesLocationAndDistanceDetails from "./detailsCards/schedulesLocationAndDistance";
import { useQuery } from "react-query";
import queryKeys from "../../../../constants/queryKeys";
import { useSchedulesApiService } from "../../../../services/schedulesApiServices";
import { useQueryStringHelper } from "../../../../helpers/queryStringHelper";
import WaitingLoader from "../../../../components/shared/waitingLoader";

type Props = {
  scheduleId: string;
  setScheduleId: Dispatch<SetStateAction<string>>;
  statusHolder?: string;
  driverDetails?: {
    [key: string]: any;
  };
  schedule?: {
    [key: string]: any;
  };
};

const SchedulesDetails = (props: Props) => {
  const { getSchedulesDetailsToast } = useSchedulesApiService();
  const { removeAllQueryStringsFromURL } = useQueryStringHelper();

  const { statusHolder } = props;
  const {
    isLoading,
    // isError,
    // error,
    data: scheduleObject,
    // refetch: refetchTripsCount,
  } = useQuery(
    [queryKeys.schedulesDetails],
    () =>
      getSchedulesDetailsToast({
        schedules_id: scheduleId,
        status: statusHolder,

        // getQueryStringValue("trip-id")
      }),
    {
      // select: (data) => data?.data[0]?.total || 0,
      select: (data) => data?.data[0],
      keepPreviousData: true,
    }
  );

  const { scheduleId, setScheduleId, driverDetails, schedule } = props;
  const navigate = useNavigate();
  // const scheduleObject = schedule?.find((item: any) => item.payment_id === scheduleId);

  return isLoading ? (
    <WaitingLoader />
  ) : (
    <div>
      <div className="d-flex justify-content-between text-nowrap flex-wrap">
        <div className="d-flex align-items-center">
          {/* <div className="fw-bold">{getRiderType(riderType)} Riders</div> */}
          <div>
            <div className="d-flex align-items-center  ">
              <BsArrowLeft
                size={30}
                className="cursor-pointer"
                onClick={() => {
                  setScheduleId("");
                  removeAllQueryStringsFromURL();
                }}
              />
              <div className="fw-bolder ms-3 fs-5">{`${scheduleObject?.rider_data?.first_name} ${scheduleObject?.rider_data?.last_name}`}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <ScheduleTripsDetails scheduleObject={scheduleObject} />
      </div>
      <div className="mt-3">
        <SchedulesFare scheduleObject={scheduleObject} />
      </div>
      <div className="mt-3">{/* <ScheduleDriverAndVehicle scheduleObject={scheduleObject} /> */}</div>
      <div className="mt-3">
        <SchedulesTime scheduleObject={scheduleObject} />
      </div>
      <div className="mt-3">
        <SchedulesLocationAndDistanceDetails scheduleObject={scheduleObject} />
      </div>
    </div>
  );
};

export default SchedulesDetails;
