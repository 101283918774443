import { ObjectType } from "../types/common";

export function isEmptyObject(obj: ObjectType) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}
