import AsyncSelect from "react-select/async";
import axios from "axios";
import React, { useState } from "react";
import { ObjectType } from "../../../../types/common";
import { api } from "../../../../constants/urls";
import { getAddressPredictions } from "../../../../helpers/locationHelper";
import { usePlacesWidget } from "react-google-autocomplete";

type Props = {
  onChange?: any;
  value?: any;
};
const GoogleLocationSearch = ({ value, onChange }: Props) => {
  //    const [debouncer] = useDebounce();

  const itemChange =  (item: string) => {
    onChange(item);
    // return item;
  };

  let timeOutSearch: any = React.useRef(); //for holding timeout when request is initialize
  const promiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      clearTimeout(timeOutSearch.current); //clear any pending timeout before creating a new one
      timeOutSearch.current = setTimeout(() => {
        // resolve(searchAddress(inputValue));
        resolve(getAddressPredictions(inputValue));
      }, 1000);
    });

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyD4YEt9Lbp1b1XPWlW0HYPVLzPyY2dAnYQ",
    // onPlaceSelected: (place) => console.log(place),
  });

  return (
    <div>
      <AsyncSelect ref={ref as any} value={value} cacheOptions defaultOptions loadOptions={promiseOptions as any} onChange={itemChange as any} placeholder="Search location" required />
    </div>
  );
};

export default GoogleLocationSearch;

// import { useEffect, useState } from "react";
// import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
// import { Input } from "reactstrap";

// export default () => {
//   const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesAutocompleteService({
//     apiKey: "AIzaSyD4YEt9Lbp1b1XPWlW0HYPVLzPyY2dAnYQ",
//   });
//   const [placeDetails, setPlaceDetails] = useState<any>();

//   useEffect(() => {
//     // fetch place details for the first element in placePredictions array
//     if (placePredictions.length)
//       placesService?.getDetails(
//         {
//           placeId: placePredictions[0].place_id,
//         },
//         (placeDetails: any) => setPlaceDetails(placeDetails)
//       );
//   }, [placePredictions]);

//   return (
//     <>
//       <Input
//         placeholder="Debounce 500 ms"
//         onChange={(evt) => {
//           getPlacePredictions({ input: evt.target.value });
//         }}
//         loading={isPlacePredictionsLoading}
//       />
//       {placePredictions.map((item) => (
//         <div>Places</div>
//       ))}
//     </>
//   );
// };
