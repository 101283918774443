import { Link } from "react-router-dom";

const Prototype = ({ label, value, link, cols }: { label: string; value: any; link?: string; cols?: number }) => (
  <div className={`mt-4 col ${cols ? `col-md-${cols}` : "col-md-4"}`}>
    <div>
      <small className="text-grey">{label}</small>
    </div>
    {link ? (
      <Link to={link} >
        {value}
      </Link>
    ) : (
      <div className={`mt-1 fw-semibold`}>{value}</div>
    )}
  </div>
);


export default Prototype;
