import axios from "axios";
import { api } from "../constants/urls";
import { useToastifyPromises } from "../helpers/toastifyHelper";

export const useRoleApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //   1.get roles
  const getRolesReq = ({ page = 1, item_per_page = 20, q = "", component = "" }: any) => {
    return axios.get(api.setting + `/admin/roles/?page=${q ? "" : page}&item_per_page=${item_per_page}&q=${q}&component=${component}`);
  };
  const getRolesToast = (body: any) => toastifyPromises.get({ asyncFunction: getRolesReq(body) });

  //2. create Roles
  const createRolesReq = (body: any) => axios.post(api.setting + `/admin/roles`, body);
  const createRolesToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: createRolesReq(body),
      pendingMsg: "Creating Role",
      SuccessMsg: "Role Creation Successful",
    });

  //3. update Roles
  const updateRolesReq = (body: any) => axios.put(api.setting + `/admin/roles/${body?.role_id}`, body);
  const updateRoleToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: updateRolesReq(body),
      pendingMsg: "Updating Role",
      SuccessMsg: "Role Update Successful",
    });

  //4. delete Roles
  const deleteRolesReq = (body: any) => {
    if (!Array.isArray(body?.role_id)) {
      return axios.delete(api.setting + `/admin/roles/${body?.role_id}`);
    } else {
      let hasError = false;
      const requests = body?.role_id?.map((id: string) => axios.delete(api.setting + `/admin/roles/${id}`));
      return Promise.all(requests)
        .then((responses) => {
          responses.forEach((response) => {
            if (response?.data?.status === "error") hasError = true;
          });

          return hasError;
        })
        .then((isError) => {
          return isError
            ? {
                data: {
                  status: "error",
                  msg: "At least one request failed",
                },
              }
            : {
                data: {
                  status: "ok",
                },
              };
        });
    }
  };
  const deleteRoleToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: deleteRolesReq(body),
      pendingMsg: "Deleting Role",
      SuccessMsg: "Role(s) Deleted Successfully",
    });

  //5.
  const getRolesListReq = () => {
    return axios.get(api.setting + `/admin/role-lists`);
  };
  const getRolesListToast = () => toastifyPromises.get({ asyncFunction: getRolesListReq() });

  //STAFFADMIN
  //STAFFADMIN
  //STAFFADMIN
  //STAFFADMIN
  //STAFFADMIN
  //STAFFADMIN
  //STAFFADMIN

  //   1.get staffAdmin
  const getStaffAdminReq = ({ page = 1, item_per_page = 50, q = "", component = "" }: any) => {
    return axios.get(api.users + `/admin/?page=${q ? "" : page}&item_per_page=${item_per_page}&q=${q}&component=${component}`);
  };
  const getStaffAdminToast = (body: any) => toastifyPromises.get({ asyncFunction: getStaffAdminReq(body) });

  //2. create StaffAdmin
  const createStaffAdminReq = (body: any) => axios.post(api.users + `/admin`, body);
  const createStaffAdminToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: createStaffAdminReq(body),
      pendingMsg: "Creating Staff Admin",
      SuccessMsg: "Staff Admin Creation Successful",
    });

  //3. update StaffAdmin
  const updateStaffAdminReq = (body: any) => axios.put(api.users + `/admin/${body.auth_id}`, body);
  const updateStaffAdminToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: updateStaffAdminReq(body),
      // pendingMsg: "Updating Staff Admin",
      // SuccessMsg: "Staff Admin Update Successful",
      pendingMsg: "Updating Account",
      SuccessMsg: "Account Updated",
    });

  //4. delete StaffAdmin
  const deleteStaffAdminReq = (body: any) => {
    if (!Array.isArray(body.auth_id)) {
      return axios.delete(api.users + `/admin/${body.auth_id}?user_type=admin`);
    } else {
      let hasError = false;
      const requests = body?.auth_id?.map((id: string) => axios.delete(api.users + `/admin/${id}&user_type=admin`));
      return Promise.all(requests)
        .then((responses) => {
          responses.forEach((response) => {
            if (response?.data?.status === "error") hasError = true;
          });
          return hasError;
        })
        .then((isError) => {
          return isError
            ? {
                data: {
                  status: "error",
                  msg: "At least one request failed",
                },
              }
            : {
                data: {
                  status: "ok",
                },
              };
        });
    }
  };
  const deleteStaffAdminToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: deleteStaffAdminReq(body),
      pendingMsg: "Deleting Staff Admin",
      SuccessMsg: "Staff Admin(s) Deleted Successfully",
    });

  return {
    getRolesToast,
    getRolesListToast,
    createRolesToast,
    updateRoleToast,
    deleteRoleToast,
    getStaffAdminToast,
    createStaffAdminToast,
    updateStaffAdminToast,
    deleteStaffAdminToast,
  };
};
