import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Label, Modal, ModalBody } from "reactstrap";
import EmptyPage from "../../../../../components/EmptyPage";
import GradientButton from "../../../../../components/shared/gradidentButton";
import WaitingLoader from "../../../../../components/shared/waitingLoader";
import queryKeys from "../../../../../constants/queryKeys";
import { confirmDialogue } from "../../../../../helpers/confirmationDialogue";
import { isPreFix } from "../../../../../helpers/stringsHelper";
import { useSetupApiService } from "../../../../../services/setupApiServices";
import { ObjectType, ReactChangeEventType } from "../../../../../types/common";
import AddFeeModalBody from "../addFeeConfigModalBody";
import LabelComponent from "../labelComponent";

const DriverAppSettings = () => {
  // const { deleteModalOpen, setDeleteModalOpen, deleteModalConfig, deleteModalTexts, deleteCallBack } = useContext(AppPrefrenceContext);
  const queryClient = useQueryClient();
  const { getFeesToast, updateFeeToast, deleteFeeToast } = useSetupApiService();
  const [isEdited, setIsEdited] = useState(false);
  const [fares, setFares] = useState<[] | undefined>();

  const [modalOpen, setModalOpen] = useState(false);
  const [breakDownModalOpen, setBreakDownModalOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState("");

  const { isLoading, isError, error, data } = useQuery(queryKeys.feesSetup, () => getFeesToast({ screen_name: "driver_performance" }), {
    select: (data) => data?.data,
  });
  // const { getLoading } = useContext(LoadingContext);
  useEffect(() => {
    queryClient?.invalidateQueries(queryKeys.feesSetup);
    setFares(data);
  }, [data]);

  const { data: feesList } = useQuery(queryKeys.feesList, () => getFeesToast({ screen_name: "driver_performance", component: "fee_variables" }), {
    select: (data) => data?.data,
    // enabled: false,
  });

  let totalSum = 0;

  if (fares) {
    let item: ObjectType;
    for (item of fares) {
      const value = parseFloat(item?.amount);
      totalSum += value;
    }
  }

  const handleChange = (e: ReactChangeEventType, index: number) => {
    const copiedItem: any = fares?.slice();
    copiedItem[index] = { ...(copiedItem[index] as { value: number }), amount: e.target.value };
    setFares(copiedItem);
    setIsEdited(true);
  };

  const updateFeeMutation = useMutation(updateFeeToast, {
    onSuccess: async (res) => {
      if (res.data.status !== "error") {
        queryClient.invalidateQueries(queryKeys.feesSetup);
        setBreakDownModalOpen(false);
      }
    },
    // onError: (err) => {},
  });

  const deleteFeeMutation = useMutation(deleteFeeToast, {
    onSuccess: async (res) => {
      if (res.data.status !== "error") {
        queryClient.invalidateQueries(queryKeys.feesSetup);
        setBreakDownModalOpen(false);
      }
    },
    // onError: (err) => {},
  });

  const updateFee = (index: number | string) => {
    const data: any = fares?.find((item, id) => id === index);
    const payload: ObjectType = {};
    const keysToRemove = ["updatedAt", "system_generated", "createdAt", "screen_name"];

    for (const key in data) {
      // Check if the current key is not in the keysToRemove array
      if (!keysToRemove.includes(key)) {
        // If it's not in the keysToRemove, add the key-value pair to the filtered object
        payload[key] = data[key];
      }
    }
    updateFeeMutation.mutate(payload);
  };

  const deleteFee = (index: number | string) => {
    const data: any = fares?.find((item, id) => id === index);

    deleteFeeMutation.mutate({ fee_id: data.fee_id });
  };

  const addFeesModalToggle = () => {
    setModalOpen(false);
  };

  if (isLoading) return <WaitingLoader />;

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <div className="fw-bold">Driver App Settings</div>
          <div className="mt-1" style={{ fontSize: 14 }}>
            Hover over each item to edit
          </div>
        </div>
        <div className="d-flex align-items-center">
          <GradientButton text="Add New Item" onClick={() => setModalOpen(true)} />
        </div>
      </div>
      {(!fares || fares?.length === 0) && (
        <div className="mt-2">
          <EmptyPage text="No fee has been set" />
        </div>
      )}

      <div className="mt-1" style={{ width: 750 }}>
        {fares?.map((item: ObjectType, index: number) => (
          <div
            key={item.label}
            className="d-flex w-100 align-items-center"
            onMouseOver={() => {
              setHoveredItem(item.name);
            }}
            onMouseLeave={() => setIsEdited(false)}
          >
            <div className={` d-flex justify-content-between align-items-center p-2 rounded ${hoveredItem === item?.name && "list-hover-bg"}`} style={{ marginBottom: 12, width: "85%" }}>
              <div className="text-capitalize" style={{ width: "60%" }}>
                {item.name}
              </div>
              <div className="fw-bold d-flex align-items-center " style={{ width: "34%" }}>
                <LabelComponent item={item} index={index} isEdited={isEdited} setIsEdited={setIsEdited} handleChange={handleChange} hoveredItem={hoveredItem} deleteFee={deleteFee} />
              </div>
            </div>
            <div className={`ms-3 ${(!isEdited || item.name !== hoveredItem) && "d-none"}`} style={{ marginBottom: 13 }}>
              <Button className="border-0 text-success fw-bold" style={{ backgroundColor: "#e6f8f7" }} onClick={() => updateFee(index)}>
                Save
              </Button>
            </div>
          </div>
        ))}
      </div>

      <Modal size="md" centered isOpen={modalOpen} toggle={addFeesModalToggle} backdrop="static">
        <ModalBody className="py-4">
          <div className="d-flex justify-content-between">
            <div className="fw-bold">Add New Item</div>
            <AiOutlineClose size={20} onClick={() => setModalOpen(false)} />
          </div>
          <AddFeeModalBody feesList={feesList} fetchedList={fares} setModalOpen={setModalOpen} />
        </ModalBody>
      </Modal>

      {/* <Modal size="lg" isOpen={breakDownModalOpen} toggle={() => setBreakDownModalOpen(false)} backdrop="static">
        <ModalBody className="py-4 px-4">
          <div className="d-flex justify-content-between">
            <div className="fw-bold">{driverType}</div>
            <AiOutlineClose size={20} onClick={() => setBreakDownModalOpen(false)} />
          </div>
          <OneOffModalBody totalComponent={totalComponent} />
          <div className="two-way-grid mt-4">
            {fares?.map((item: ObjectType) => (
              <div key={item.name}>
                <div className="d-flex mb-2 justify-content-between text-blue-black">
                  <div> {item.key}</div>
                  <div>
                    <AiFillDelete
                      color="#71939c"
                      onClick={() =>
                        deleteModalConfig({
                          firstText: `Delete ${item.key}`,
                          secondText: `This action would remove ${item.key} from the portal and is irreversible.`,
                          callback: () => updateFee(index, true),
                        })
                      }
                    />
                  </div>
                </div>
                <Input value={item.value} onChange={(e) => handleChange(e, index)} />
              </div>
            ))}
          </div>
          <div style={{ width: 150 }}>
            <GradientButton className="mt-5" text="Update" onClick={() => updateFee("", false)} />
          </div>
        </ModalBody>
      </Modal> */}
    </div>
  );
};

export default DriverAppSettings;
