import { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "react-query";
import { Col, Row } from "reactstrap";
import NewFilter from "../../../../components/filter/NewFilter";
import FilterComponent from "../../../../components/shared/filterComponent";
import queryKeys from "../../../../constants/queryKeys";
import { getTodayDate } from "../../../../helpers/dateTimeHelper";
import { useWalletApiService } from "../../../../services/walletApiServices";

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<string>>;
  setRequestType: Dispatch<SetStateAction<string>>;
};

const OverviewSumaryCards = (props: Props) => {
  const { setActiveTab, setStatus, setRequestType } = props;
  const { getTripPaymentToast } = useWalletApiService();

  const [startDate, setStartDate] = useState(getTodayDate());
  const [endDate, setEndDate] = useState(getTodayDate());
  const [toggled, setToggled] = useState(false);
  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });

  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: tripPaymentCount,
  } = useQuery([queryKeys.tripsPaymentSummary, filterState], () => getTripPaymentToast({ component: "count-status", start_date: startDate, end_date: endDate }), {
    select: (data) => data?.data,
  });
  const items = [
    {
      title: "Total Trip Payments",
      value: `₦${tripPaymentCount?.total_amount?.toLocaleString() || 0}`,
      count: `${tripPaymentCount?.total_count || 0}`,
      cardBgColor: "#6212B114",
      icon: "total-trips-payment.svg",
      iconCardColor: "#6212B114",
      tag: "",
      request_type: "",
    },
    {
      title: "Successful",
      value: `₦${tripPaymentCount?.successful_amount?.toLocaleString() || 0} `,
      count: `${tripPaymentCount?.successful_count || 0}`,
      cardBgColor: "#BAFBB916",
      icon: "successful-trips-payment.svg",
      iconCardColor: "#d5f7f2",
      tag: "1",
      request_type: "",
    },
    {
      title: "Unsuccessful",
      value: `₦${tripPaymentCount?.unsuccessful_amount?.toLocaleString() || 0} `,
      count: `${tripPaymentCount?.unsuccessful_count || 0}`,
      cardBgColor: "#FFF5F5",
      icon: "unsuccessful-trips-payment.svg",
      iconCardColor: "#ffeced",
      tag: "2",
      request_type: "",
    },

    {
      title: "Undecided",
      value: `₦${tripPaymentCount?.undecided_amount?.toLocaleString() || 0}`,
      count: `${tripPaymentCount?.undecided_count || 0}`,
      cardBgColor: "#0079D016",
      icon: "undecided-trips-payment.svg",
      iconCardColor: "#fdf0c1",
      tag: "3",
      request_type: "",
    },
  ];

  const handleToggle = () => {
    setToggled(!toggled);
  };
  const onApply = () => {
    setFilterState({ startDate, endDate });
    handleToggle();
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <NewFilter
          direction={"start"}
          toggleComponent={<FilterComponent />}
          toggled={toggled}
          handleToggle={handleToggle}
          // options={filterOptions}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onApply={onApply}
        />
      </div>
      <Row>
        {items.map((item, index) => (
          <Col md={6} key={item.title} className="mt-3">
            <div
              className="rounded py-4 ps-3 d-flex align-items-center justify-content-between cursor-pointer"
              style={{ backgroundColor: item.cardBgColor, height: 105 }}
              onClick={() => {
                setActiveTab("Trips Payment Log");
                setStatus(item.tag);
                setRequestType(item.request_type);
              }}
            >
              <div>
                <div className="mb-2">{item.title}</div>
                <div className="d-flex align-items-center">
                  {/* <div className="p-2 rounded" style={{ background: item.iconCardColor }}>
                  <img alt="icon" src={`/svgs/icons/${item.icon}`} height={19} width={25} />
                </div> */}

                  {index !== 0 && (
                    <div className="">
                      <div style={{ color: "#71939C", fontSize: 14 }}> Number of Trips</div>
                      <h6 className="text-blue-black fw-bold mt-2">{item?.count}</h6>
                    </div>
                  )}

                  <div className={`${index !== 0 && "ms-5"}`}>
                    {index !== 0 && <div style={{ color: "#71939C", fontSize: 14 }}> Amount</div>}
                    <h6 className="text-blue-black fw-bold mt-2">{item?.value}</h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default OverviewSumaryCards;
