import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Table } from "reactstrap";
import EmptyPage from "../../../components/EmptyPage";
import NewFilter from "../../../components/filter/NewFilter";
import ExportComponent from "../../../components/shared/exportComponent";
import FilterComponent from "../../../components/shared/filterComponent";
import WaitingLoader from "../../../components/shared/waitingLoader";
import PaginationComponent from "../../../components/tablesComponents/paginationComponent";
import SeearchComponent from "../../../components/tablesComponents/searchComponent";
import TableDataCounter from "../../../components/tablesComponents/tableDataCounter";
import queryKeys from "../../../constants/queryKeys";
import { AppPrefrenceContext } from "../../../contexts/AppPrefrenceContext";
import { getTripStatusColor } from "../../../helpers/colorsHelper";
import { calculatePostDate } from "../../../helpers/dateTimeHelper";
import { usePaginationHelper } from "../../../helpers/paginationHelper";
import { useQueryStringHelper } from "../../../helpers/queryStringHelper";
import { useTripsApiService } from "../../../services/tripsApiService";
import { ObjectType } from "../../../types/common";
import { getTripsStatusText, tripsStatusOptions, tripstypesOptions } from "../old-finance/revenue/data";
import DriverTripsDetails from "../users/drivers/components/driverDetailsComponents/driverDetailsComponents/driverTripsDetails";

type Props = {
  tripsType: string;
  tripsStatus: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  setActiveId?: Dispatch<SetStateAction<string>>;
  location: { lga: string; area: string };
};
const TripsLog = (props: Props) => {
  const { tripsType: tripTypeAsProps, tripsStatus: tripStatusAsProps, location } = props;
  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { getPageNoFromQueryString, getQueryStringValue, attachQueryStringToUrl } = useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const { getTripsToast } = useTripsApiService();
  const [keyword, setKeyword] = useState("");
  const [filterToggle, setFilterToggle] = useState(false);
  const [tripsType, setTripType] = useState(tripTypeAsProps);
  const [tripsStatus, setTripsStatus] = useState(tripStatusAsProps);
  const [tripsClass, setTripsClass] = useState("");
  const [tripId, setTripId] = useState("");
  const [viewedTrip, setViewedTrip] = useState<ObjectType | null>(null);

  useEffect(() => {
    setBreadCrumbData(["Trips", "Trips Log"]);
    const id = getQueryStringValue("trip-id");
    if (id) setTripId(id);
  }, []);

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
    tripsType,
    tripsStatus,
    tripsClass,
  });

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };

  const { setBreadCrumbData } = useContext(AppPrefrenceContext);

  const queryClient = useQueryClient();

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
    });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: driverTrips,
    // refetch: refetchTrips,
  } = useQuery(
    [queryKeys.userTrips, currentPage, keyword, filterState],
    () =>
      getTripsToast({
        ride_status: tripsStatus,
        ride_type: tripsType,
        ride_class: tripsClass,
        start_date: startDate,
        end_date: endDate,
        page: currentPage,
        request_lga: location.lga,
        request_area: location.area,
        q: keyword,
      }),
    {
      select: (data) => data?.data,
      keepPreviousData: true,
    }
  );

  const {
    isLoading,
    // isError,
    // error,
    data: driverTripsCount,
    // refetch: refetchTripsCount,
  } = useQuery(
    [queryKeys.userTripsCount, currentPage, keyword, filterState],
    () =>
      getTripsToast({
        ride_status: tripsStatus,
        ride_type: tripsType,
        ride_class: tripsClass,
        start_date: startDate,
        end_date: endDate,
        request_lga: location.lga,
        request_area: location.area,
        q: keyword,
        component: "count",
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
    }
  );

  const { refetch: exportTrips } = useQuery(
    [queryKeys.userTripsExport],
    () =>
      getTripsToast({
        ride_status: tripsStatus,
        ride_type: tripsType,
        ride_class: tripsClass,
        start_date: startDate,
        end_date: endDate,
        request_lga: location.lga,
        request_area: location.area,
        q: keyword,
        component: "export",
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
      enabled: false,
    }
  );

  const filterOptions = [
    {
      title: "Trips Type",
      options: tripstypesOptions,
      currentValue: tripsType,
      onChange: setTripType,
      name: "payMethod",
    },
    {
      title: "Trips Status",
      options: tripsStatusOptions,
      currentValue: tripsStatus,
      onChange: setTripsStatus,
      name: "payStatus",
    },
    // {
    //   title: "Trips Class",
    //   options: tripsClassOptions,
    //   currentValue: tripsClass,
    //   onChange: setTripsClass,
    //   name: "tripsClass",
    // },
  ];

  const onApply = () => {
    setFilterState({ startDate, endDate, tripsType, tripsStatus, tripsClass });
    handleToggle();
  };

  if (tripId || getQueryStringValue("trip-id")) {
    return <DriverTripsDetails tripId={getQueryStringValue("trip-id") || tripId} ride_status={tripsStatus} setTripId={setTripId} fromTripsModule />;
  } else {
    return (
      <>
        <div className="d-flex justify-content-between text-nowrap flex-wrap">
          <div className="d-flex align-items-center">
            {/* <div className="fw-bold">{getRiderType(riderType)} Riders</div> */}
            <div className="">
              <div className="d-flex align-items-center  ">
                <div className="fw-bolder fs-5">{`Trip History`}</div>
              </div>
            </div>
            <div className="ms-4">{<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="me-3">{/* <DateRangeComponent direction={"start"} filterToggle={filterToggle} handleFilterToggle={handleFilterToggle} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} /> */}</div>
            <div className="me-3">
              <NewFilter
                direction={"start"}
                toggleComponent={<FilterComponent />}
                toggled={toggled}
                handleToggle={handleToggle}
                options={filterOptions}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                onApply={onApply}
              />
            </div>
            <div>{driverTrips?.length > 0 && <ExportComponent onClick={exportTrips} />}</div>
          </div>
        </div>

        {isLoading && !driverTrips && <WaitingLoader />}
        {!isLoading && (
          <div className="mt-2 ">
            {driverTrips?.length === 0 && <EmptyPage text="No Trips" />}
            {driverTrips?.length > 0 && (
              <Table responsive className="table-hover">
                <thead className="">
                  <tr>
                    <th className=""> Date/Time</th>

                    {tripsType !== "driver-not-found" ? (
                      <>
                        <th className=""> Trip Ref.</th>
                        <th className="">Trip Type</th>
                        <th className=""> Status</th>
                        <th className="">Pickup LGA</th>
                      </>
                    ) : (
                      <>
                        <th className=""> Request Area</th>
                        <th className=""> No. of Attempts</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {driverTrips.map((item: ObjectType, index: number) => (
                    <tr
                      className="cursor-pointer"
                      onClick={() => {
                        setTripId(item.trip_id);
                        setViewedTrip(item);
                        attachQueryStringToUrl("trip-id", item.trip_id);
                      }}
                    >
                      <td className="d-flex align-items-center">{calculatePostDate(item.createdAt)}</td>

                      {tripsType !== "driver-not-found" ? (
                        <>
                          <td>{item.trip_ref}</td>
                          <td className="text-capitalize">
                            <small className="table-pill "> {item.ride_type === "regular" ? "On-demand" : item.ride_type}</small>
                          </td>
                          <td className={`fw-bold text-success text-capitalize ${getTripStatusColor(item.ride_status)}`}>{getTripsStatusText(item?.ride_status)}</td>
                          <td className="text-capitalize">{item?.request_area_data?.lga}</td>
                        </>
                      ) : (
                        <>
                          <td className="text-capitalize">{item.request_area_data.area_name}</td>
                          <td>{item.trails}</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {driverTrips?.length > 0 && (
              <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
                <div className="mt-2">
                  <TableDataCounter item={driverTrips} currentPage={currentPage} totalCount={driverTripsCount} />
                </div>
                <div className="mt-2">
                  <PaginationComponent activePage={currentPage} itemsCountPerPage={50} totalItemsCount={driverTripsCount} onChange={onPaginate} />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
};

export default TripsLog;
