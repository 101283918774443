import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ObjectType, ReactChangeEventType } from '../../../../types/common';

type Props = {
  data: ObjectType | null;
  editorTextChange: (message: string) => void;
};

const TextEditor = (props: Props) => {
  const { data, editorTextChange } = props;
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={data?.message}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          editorTextChange(data);
        }}
      />
    </div>
  );
};

export default TextEditor;
