import React, { useState } from "react";
import { BsEyeFill } from "react-icons/bs";
import { getActualAddress } from "../../../../../../../../helpers/locationHelper";

type Props = {
  lat: string;
  lon: string;
};

const ActualLoactionUI = (props: Props) => {
  const { lat, lon } = props;

  const [actualLocation, setActualLocation] = useState("");
  const getAddress = async (lat: string, lon: string) => {
    const res = await getActualAddress(lat, lon);
    setActualLocation(res);
  };
  return <div> {actualLocation ? actualLocation : <BsEyeFill onClick={() => getAddress(lat, lon)} />}</div>;
};

export default ActualLoactionUI;
