import { Button, Modal } from 'reactstrap';
import GradientButton from '../shared/gradidentButton';
import { useContext } from 'react';
import { AppPrefrenceContext } from '../../contexts/AppPrefrenceContext';

const DeleteDialogue = () => {
  const {
    deleteModalOpen,
    setDeleteModalOpen,
    deleteModalTexts,
    deleteCallBack,
    type,
    actionButton,
  } = useContext(AppPrefrenceContext);

  const bodyElement = document.querySelector('body');
  if (bodyElement !== null) {
    bodyElement.style.overflow = 'scroll';
  }

  // modalopen, setmodalopen,mainText, secondText

  const getIcon = () => {
    if (type === 'suspend') {
      return <img alt='icon' src='/svgs/suspend-modal-icon.svg' />;
    } else if (type === 're-activate') {
      return <img alt='icon' src='/svgs/alert-modal-icon.svg' />;
    } else if (type === 'id-verification') {
      return <img alt='icon' src='/svgs/alert-modal-icon.svg' />;
    } else if (type === 'maintenance') {
      return <img alt='icon' src='/svgs/alert-modal-icon.svg' />;
    } else if (type === 'payout') {
      return <img alt='icon' src='/svgs/alert-modal-icon.svg' />;
    } else if (type === 'emergency') {
      return <img alt='icon' src='/svgs/icons/emergency_modal.svg' />;
    }else if (type === 'assign') {
      return <img alt='icon' src='/svgs/alert-modal-icon.svg' />;
    } else {
      return <img alt='icon' src='/svgs/delete-modal-icon.svg' />;
    }
  };
  const getTextRed = () => {
    if (type === 'suspend') {
      return 'Suspend';
    } else if (type === 're-activate') {
      return 'Cancel';
    } else if (type === 'id-verification') {
      return 'No, Cancel ';
    } else if (type === 'maintenance') {
      return 'Cancel';
    } else if (type === 'payout') {
      return 'Cancel';
    } else if (type === 'emergency') {
      return 'Cancel';
    } else if (type === 'assign') {
      return 'Cancel';
    } else {
      return 'Delete';
    }
  };

  const getTextBlue = () => {
    if (type === 'suspend') {
      return "Cancel";
    } else if (type === 're-activate') {
      return "Re-activate";
    } else if (type === 'id-verification') {
      return '👌 Yes Proceed';
    } else if (type === 'maintenance') {
      return 'Initiate';
    } else if (type === 'payout') {
      return 'Approve';
    } else if (type === 'assign') {
      return 'Assign';
    }else if (type === 'emergency') {
      return "👌 Resolve";
    } else {
      return "Cancel";
    }
  };

  return (
    <Modal
      centered
      isOpen={deleteModalOpen}
      toggle={() => setDeleteModalOpen((prev: boolean) => !prev)}
      backdrop='static'
      style={{ width: 390 }}
    >
      <div className=''>{getIcon()}</div>
      <div className='px-3' style={{ marginTop: -17 }}>
        <h4>{deleteModalTexts.first}</h4>

        <div className='mt-3 mb-5'>{deleteModalTexts.second}</div>

        <div className='d-flex justify-content-center  mb-4'>
          <div style={{ width: '45%' }}>
            <Button
              size={'sm'}
              className={`w-100 me-3  border-0 font-krub-semibold text-nowrap rounded fw-bold text-danger py-2 `}
              onClick={() => {
                if (actionButton === 'blue') {
                  setDeleteModalOpen(false);
                } else {
                  deleteCallBack();
                  setDeleteModalOpen(false);
                  if (bodyElement !== null) {
                    bodyElement.style.overflow = 'scroll';
                  }
                }
              }}
              // disabled={disabled}
              style={{
                padding: '13px 18px',
                fontSize: 16,
                backgroundColor: '#ffebeb',
              }}
            >
              {getTextRed()}
            </Button>
          </div>
          <div>
            <GradientButton
              className='py-2 ms-3'
              text={`${getTextBlue()}`}
              onClick={() => {
                if (actionButton !== 'blue') {
                  setDeleteModalOpen(false);
                } else {
                  deleteCallBack();
                  setDeleteModalOpen(false);
                  if (bodyElement !== null) {
                    bodyElement.style.overflow = 'scroll';
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDialogue;
