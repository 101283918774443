import { useState } from 'react';
import { useNavigate } from 'react-router';
import CusCard from '../../../components/shared/cusCard';
import PromoLog from './promoLog';
import PromoOverview from './promoOverview';


const Promo = () => {
  const tabs = ['Overview', "Promo's Log"];
  const [promoType, setPromoType] = useState('total');
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate();

  return (
    <CusCard className='p-0' style={{ minHeight: '70vh' }}>
      <div className='d-flex'>
        {tabs.map((item) => (
          <div
            className={`px-3 bg-none cursor-pointer ${
              activeTab === item && 'gradient-btn text-white fw-bold'
            } ${item === 'Profile' && activeTab !== 'Profile' && 'd-none'}`}
            key={item}
            style={{ paddingTop: '11px', paddingBottom: '11px' }}
            onClick={() => {
              item !== 'Profile' && navigate('/promo');
              setActiveTab(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className='p-3 p-sticky' style={{ top: 0 }}>
        {activeTab === 'Overview' && (
          <div>
            <PromoOverview
              setActiveTab={setActiveTab}
              setPromoType={setPromoType}
            />
          </div>
        )}

        {activeTab === "Promo's Log" && (
          <PromoLog
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setPromoType={setPromoType}
            promoType={promoType}
          />
        )}
      </div>
    </CusCard>
  );
};

export default Promo;
