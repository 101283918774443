import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Card, Col, Row } from "reactstrap";
import FilterOptions from "../../../components/filter/Filter";
import YearsCalender from "../../../components/filter/yearsCalender";
import queryKeys from "../../../constants/queryKeys";
import { AppPrefrenceContext } from "../../../contexts/AppPrefrenceContext";
import { getCurrentMonthAndYear, getFirstDayOfTheYear, getLastDayOfTheYear } from "../../../helpers/dateTimeHelper";
import { useTripsApiService } from "../../../services/tripsApiService";
import { ReactChangeEventType } from "../../../types/common";
import OverviewSumaryCards from "./components/overviewSummaryCards";
import RidersDriversCancellation from "./components/ridersDriversCancellation";
import TripsMonthly from "./components/tripsMonthly";
import TripSegmentByLga from "./components/tripsSegmentByLga";
import UniqueFeatures from "./components/uniqueFeature";

type Props = {
  setTripsType: Dispatch<SetStateAction<string>>;
  setTripsStatus: Dispatch<SetStateAction<string>>;
  setActiveTab: Dispatch<SetStateAction<string>>;
  setLocation: Dispatch<SetStateAction<{ area: string; lga: string }>>;
  location: { area: string; lga: string };
};

const TripsOverview = (props: Props) => {
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const [startDate, setStartDate] = useState(getFirstDayOfTheYear());
  const [endDate, setEndDate] = useState(getLastDayOfTheYear());
  const [dateType, setDateType] = useState("monthly");
  const [toggled, setToggled] = useState(false);
  const { getTripsToast } = useTripsApiService();

  const handleToggle = () => {
    setToggled(!toggled);
  };

  useEffect(() => {
    setBreadCrumbData(["All Trips", "Overview"]);
  }, []);

  const datyTypeOptions = [
    {
      value: "monthly",
      label: "Monthly",
      subOptionComponent: (
        <YearsCalender
          handleToggle={handleToggle}
          month={startDate}
          setYear={(year) => {
            setStartDate(year);
            setEndDate(year);
          }}
        />
      ),
    },
  ];

  const handleDateTypeChange = (e: ReactChangeEventType) => {
    setStartDate(getCurrentMonthAndYear());
    setEndDate(getCurrentMonthAndYear());
    setDateType(e.target.value);
  };

  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: tripsMonthlyCount,
  } = useQuery([queryKeys.tripsCharts, startDate, endDate], () => getTripsToast({ component: "count-monthly", start_date: `${startDate.split("-")[0]}-01-01`, end_date: `${endDate.split("-")[0]}-12-31` }), {
    select: (data) => data?.data,
  });

  return (
    <div>
      {/* <h5 className="fw-bold">Rider Overview</h5> */}

      <div className="">
        <OverviewSumaryCards {...props} />
      </div>

      <Row className="mt-md-3">
        <Col md={6} className="mt-3">
          <UniqueFeatures />
        </Col>
        <Col md={6} className="mt-3">
          <TripSegmentByLga {...props} />
        </Col>
      </Row>

      <Card body className="mt-4 px-4">
        <div className="d-flex justify-content-end">
          <FilterOptions direction="left" toggled={toggled} handleToggle={handleToggle} options={datyTypeOptions} selectedOption={dateType} optionChange={handleDateTypeChange} name="registration-chart" startDate={startDate} endDate={endDate} />
        </div>
        <Row className="mt-md-3">
          <Col md={6} className="mt-3">
            <TripsMonthly tripsMonthlyCount={tripsMonthlyCount} />
          </Col>
          <Col md={6} className="mt-3">
            <RidersDriversCancellation tripsMonthlyCount={tripsMonthlyCount} />
          </Col>
          {/* <Col md={6} className="mt-3">
            <AllTripsCat tripsMonthlyCount={tripsMonthlyCount} />
          </Col>
          <Col md={6} className="mt-3">
            <OnDemandAndSchedules tripsMonthlyCount={tripsMonthlyCount} />
          </Col> */}
        </Row>
      </Card>
    </div>
  );
};

export default TripsOverview;
