import { lazy } from 'react';

//Vehicle
const Vehicle = lazy(() => import('../../Pages/others/vehicle'));

export default {
  path: '/vehicle',
  title: 'Vehicle',
  children: [
    {
      path: '/',
      component: Vehicle,
      title: 'Vehicle',
      permission: 'Vehicle',
    },
  ],
};
