import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { Table } from 'reactstrap';
import EmptyPage from '../../../../components/EmptyPage';
import NewFilter from '../../../../components/filter/NewFilter';
import ExportComponent from '../../../../components/shared/exportComponent';
import FilterComponent from '../../../../components/shared/filterComponent';
import WaitingLoader from '../../../../components/shared/waitingLoader';
import PaginationComponent from '../../../../components/tablesComponents/paginationComponent';
import SeearchComponent from '../../../../components/tablesComponents/searchComponent';
import TableDataCounter from '../../../../components/tablesComponents/tableDataCounter';
import queryKeys from '../../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../../contexts/AppPrefrenceContext';
import { usePaginationHelper } from '../../../../helpers/paginationHelper';
import { useQueryStringHelper } from '../../../../helpers/queryStringHelper';
import { useUsersApiService } from '../../../../services/usersService';
import { promoCodeType } from '../../old-finance/revenue/data';

type Props = {
  setBenId: Dispatch<SetStateAction<string>>;
  benId: string;
};
const BeneficiaryLog = (props: Props) => {
  const { benId, setBenId } = props;
  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [codeType, setCodeType] = useState('');
  const { getPageNoFromQueryString } = useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const [keyword, setKeyword] = useState('');
  const { getPromoDetailsToast } = useUsersApiService();
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData(['Promo', `Beneficiaries`]);
  }, []);

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
    codeType,
  });

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const { isLoading, data: ben } = useQuery(
    [queryKeys.beneficiary, codeType, filterState, startDate, endDate],
    () =>
      getPromoDetailsToast({
        promo_id: benId,
      }),
    {
      select: (data) => data?.data || [],
      keepPreviousData: true,
    }
  );
  const { data: benCount } = useQuery(
    [queryKeys.beneficiaryCount, codeType, filterState, startDate, endDate],
    () =>
      getPromoDetailsToast({
        component: 'count',
        promo_id: benId,
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
    }
  );

  const filterOptions = [
    {
      title: 'Promo Type',
      options: promoCodeType,
      currentValue: codeType,
      onChange: setCodeType,
      name: 'promoType',
    },
  ];

  const onApply = () => {
    setFilterState({ startDate, endDate, codeType });
    handleToggle();
  };

  if (isLoading && !ben) {
    return <WaitingLoader />;
  }
  return (
    <>
      <div className='d-flex justify-content-between text-nowrap flex-wrap'>
        <div className='d-flex align-items-center'>
          <div className=''>
            <div className='d-flex align-items-center  '>
              <BsArrowLeft
                size={30}
                className='cursor-pointer'
                onClick={() => {
                  setBenId('');
                  // removeAllQueryStringsFromURL();
                }}
              />
              <div className='fw-bolder fs-5  ms-3 capitalize'>Beneficiaries</div>
            </div>
          </div>
          <div className='ms-4'>
            {<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='me-3'>{ben?.length > 0 && <ExportComponent />}</div>

          <div className='me-3'>
            <NewFilter
              direction={'start'}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              options={filterOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />
          </div>
        </div>
      </div>

      <div className='mt-2'>
        {ben?.length === 0 && <EmptyPage text='No Beneficiary' />}
        {ben?.length > 0 && (
          <Table responsive className='table-hover'>
            <thead className=''>
              <tr>
                <th className=''>Name</th>
                <th className=''>Email Address</th>
                <th className=''>Phone Number</th>
                <th className=''>Number Of Rides</th>
              </tr>
            </thead>

            <tbody>
              {ben.map((item: any) => (
                <Fragment key={item?._id}>
                  <tr className='cursor-pointer capitalize'>
                    <td>
                      {item?.rider_data?.last_name || 'N|A'}{' '}
                      {item?.rider_data?.first_name}
                    </td>
                    <td>{item?.rider_data?.email || 'N|A'}</td>
                    <td>{item?.rider_data?.phone_number || 'N|A'}</td>
                    <td>{item?.rider_data?.num_of_rides || 0}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        )}
        {ben.length > 0 && (
          <div className='d-flex justify-content-between align-items-center mt-4 flex-wrap'>
            <div className='mt-2'>
              <TableDataCounter
                item={ben}
                currentPage={currentPage}
                totalCount={benCount}
              />
            </div>
            <div className='mt-2'>
              <PaginationComponent
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={benCount}
                onChange={onPaginate}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BeneficiaryLog;
