import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useQueryStringHelper } from '../../../helpers/queryStringHelper';
import CusCard from '../../../components/shared/cusCard';
import VmrOverview from './vmrOverview';
import VmrLog from './vmrLog';

const Vmr = () => {
  const { getIdFromQueryString } = useQueryStringHelper();
  const tabs = ['Overview', "Vmr's Log"];
  const [vmrType, setVmrType] = useState('total');
  const [activeId, setActiveId] = useState('');
  const [activeTab, setActiveTab] = useState(
    getIdFromQueryString('vmr-id') ? "Vmr's Log" : tabs[0]
  );
  const [addVehicle, setAddVehicle] = useState(false);
  const navigate = useNavigate();

  return (
    <CusCard className='p-0' style={{ minHeight: '70vh' }}>
      {!addVehicle && (
        <div className='d-flex'>
          {tabs.map((item) => (
            <div
              className={`px-3 bg-none cursor-pointer ${
                activeTab === item && 'gradient-btn text-white fw-bold'
              } ${
                item === "Vehicle's Profile" &&
                activeTab !== "Vehicle's Profile" &&
                'd-none'
              }`}
              key={item}
              style={{ paddingTop: '11px', paddingBottom: '11px' }}
              onClick={() => {
                item !== "Vehicle's Profile" && navigate('/vmr');
                setActiveTab(item);
              }}
            >
              {item}
            </div>
          ))}
        </div>
      )}
      <div className='p-3 p-sticky' style={{ top: 0 }}>
        {activeTab === 'Overview' && (
          <VmrOverview
            vmrType={vmrType}
            setActiveTab={setActiveTab}
            setVmrType={setVmrType}
          />
        )}
        {activeTab === "Vmr's Log" && (
          <VmrLog
            vmrType={vmrType}
            setActiveTab={setActiveTab}
            setActiveId={setActiveId}
            activeId={getIdFromQueryString('vmr-id') || activeId}

          />
        )}
        
      </div>
    </CusCard>
  );
};

export default Vmr;
