import { useMutation, useQueryClient } from 'react-query';
import { Card, Col, Row, Table } from 'reactstrap';
import GradientButton from '../../../../../../components/shared/gradidentButton';
import queryKeys from '../../../../../../constants/queryKeys';
import { useOemApiService } from '../../../../../../services/oemApiServices';

type Props = {
  vmrDetails: {
    [key: string]: any;
  };
};

const VehicleIdentificationInfo = (props: Props) => {
  const { vmrDetails } = props;
  const queryClient = useQueryClient();
  const { updateVmrRequestStatusToast } = useOemApiService();
  function downloadImage() {
    const imageUrl = vmrDetails?.invoice;
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = 'invoice.png';
    downloadLink.click();
  }

  const handleApproval = (e: any) => {
    e.preventDefault();
    approveQuotationMutation.mutate({
      request_id: vmrDetails?._id,
      status: 'approved',
    });
  };
  const approveQuotationMutation = useMutation(updateVmrRequestStatusToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        queryClient.invalidateQueries(queryKeys.vmr);
        queryClient.invalidateQueries(queryKeys.vmrCount);
        // setActiveTab && setActiveTab("Partner's Log");
      }
    },
  });

  return (
    <div>
      <Card body className='p-2'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1'>
            <div className='flex-grow-1'>
              <div className='text-blue fw-semibold'>
                Diagnostic Information
              </div>

              <Row className='w-100 mt-2'>
                <Col className='mt-2'>
                  <div>
                    <small className='text-grey'>Diagnostic Activities</small>
                  </div>
                  {vmrDetails.description && (
                    <div className='mt-1 border p-2 rounded '>
                      {vmrDetails?.diagnostics_data?.diagnostics_act}
                    </div>
                  )}
                </Col>
                <Col className='mt-2'>
                  <div>
                    <small className='text-grey'>Pictures Taken</small>
                  </div>
                  <Row className='mt-1'>
                    <Col>
                      {vmrDetails?.diagnostics_data?.before_images?.map(
                        (img: string) => (
                          <img
                            src={img}
                            alt='before_pictures'
                            width={100}
                            height={80}
                            className='rounded'
                          />
                        )
                      )}
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col>
                      {vmrDetails?.diagnostics_data?.after_images?.map(
                        (img: string) => (
                          <img
                            src={img}
                            alt='after_pictures'
                            width={100}
                            height={80}
                            className='rounded'
                          />
                        )
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='w-100 mt-4'>
                <Col className='mt-2' md={6}>
                  <div>
                    <small className='text-grey'>Recommendation</small>
                  </div>
                  <div className='mt-1  border p-2 rounded '>
                    {vmrDetails?.diagnostics_data?.recommendation}
                  </div>
                </Col>
                <Col className='mt-2' md={3}>
                  <div>
                    <small className='text-grey'>Service Center Rep</small>
                  </div>
                  <div className='mt-1 fw-semibold capitalize'>
                    {vmrDetails?.service_center_name}
                  </div>
                </Col>
              </Row>
              <Row className='w-100 mt-4'>
                <Col className='mt-2' md={8}>
                  <div>
                    <small className='text-grey'>Quotations</small>
                  </div>

                  {vmrDetails?.diagnostics_data?.quotations.length > 0 && (
                    <Table responsive className='table-hover'>
                      <thead className=''>
                        <tr className='bordered-tr'>
                          <th className=''>S/N</th>
                          <th className=''>Item</th>
                          <th className=''>Description</th>
                          <th className=''>Quantity</th>
                          <th className=''>Amount</th>
                        </tr>
                      </thead>
                      <tbody className='capitalize'>
                        {vmrDetails?.diagnostics_data?.quotations.map(
                          (item: any, index: number) => (
                            <tr className='cursor-pointer' key={index}>
                              <td>{index + 1}</td>
                              <td>{item?.item}</td>
                              <td>{item?.description}</td>
                              <td>{item?.quantity}</td>
                              <td>#{item?.amount?.toLocaleString()}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  )}
                  {vmrDetails.status === 'awaiting-approval' ? (
                    <div className='py-3' style={{ width: 150 }}>
                      <GradientButton
                        onClick={handleApproval}
                        text='Approve Quotation'
                        className='py-2'
                      />
                    </div>
                  ) : (
                    vmrDetails.status === 'completed' && (
                      <div className='py-3' style={{ width: 150 }}>
                        <GradientButton
                          onClick={downloadImage}
                          text='Download Invoice'
                          className='py-2'
                        />
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default VehicleIdentificationInfo;
