import { useState } from 'react';
import { useNavigate } from 'react-router';
import CusCard from '../../../../components/shared/cusCard';
import { useQueryStringHelper } from '../../../../helpers/queryStringHelper';
import Overview from './overview';

const OperationsOverview = () => {
  const { getIdFromQueryString } = useQueryStringHelper();
  const tabs = ['Overview'];
  const [driverType, setDriverType] = useState('total');
  const [activeId, setActiveId] = useState('');
  const [activeTab, setActiveTab] = useState(
    getIdFromQueryString('user-id') ? 'Profile' : tabs[0]
  );
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (getIdFromQueryString("user-id")) {
  //     setActiveTab("Profile");
  //   }
  // }, []);

  return (
    <CusCard className='p-0 ' style={{ minHeight: '70vh' }}>
      <div className='d-flex'>
        {tabs.map((item) => (
          <div
            className={`px-3 bg-none cursor-pointer ${
              activeTab === item && 'gradient-btn text-white fw-bold'
            } ${item === 'Profile' && activeTab !== 'Profile' && 'd-none'}`}
            key={item}
            style={{ paddingTop: '11px', paddingBottom: '11px' }}
            onClick={() => {
              // item !== "Profile" && navigate("/user/drivers");
              setActiveTab(item);
            }}
          >
            {item === 'Profile' ? "Driver's Profile" : item}
          </div>
        ))}
      </div>
      <div className='p-3 px-0'>{activeTab === 'Overview' && <Overview />}</div>
    </CusCard>
  );
};

export default OperationsOverview;
