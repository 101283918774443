import { lazy } from "react";
import AppSetting from "../../Pages/others/setup/appSetting";

const Areas = lazy(() => import("../../Pages/others/setup/areas"));
const Fees = lazy(() => import("../../Pages/others/setup/fees"));
const GeoFencing = lazy(() => import("../../Pages/others/setup/geoFencing"));
const IdVerification = lazy(() => import("../../Pages/others/setup/idVerification"));
const Oems = lazy(() => import("../../Pages/others/setup/oems"));
const revenueSplit = lazy(() => import("../../Pages/others/setup/revenueSplit"));
const Roles = lazy(() => import("../../Pages/others/setup/roles"));
const StaffAdmin = lazy(() => import("../../Pages/others/setup/staffAdmin"));

export default {
  path: "/setup",
  title: "Setup",
  children: [
    {
      path: "/id-verification",
      component: IdVerification,
      title: "Setup",
      permission: "ID Verification",
    },
    {
      path: "/app-setting",
      component: AppSetting,
      title: "Setup",
      permission: "App Setting",
    },
    {
      path: "/fees",
      component: Fees,
      title: "Setup",
      permission: "Fees",
    },
    {
      path: "/areas",
      component: Areas,
      title: "Setup",
      permission: "Areas",
    },
    {
      path: "/geo-fencing",
      component: GeoFencing,
      title: "Setup",
      permission: "Geo-Fencing",
    },
    {
      path: "/staff-admin",
      component: StaffAdmin,
      title: "Setup",
      permission: "Staff Admin",
    },
    {
      path: "/roles",
      component: Roles,
      title: "Setup",
      permission: "Roles",
    },
    {
      path: "/oems",
      component: Oems,
      title: "Setup",
      permission: "OEMs",
    },
    {
      path: "/revenue-split",
      component: revenueSplit,
      title: "Setup",
      permission: "Revenue Split",
    },
  ],
};
