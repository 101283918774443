import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import GradientButton from '../../../../../../components/shared/gradidentButton';
import { ObjectType } from '../../../../../../types/common';
import { MdEdit } from 'react-icons/md';
import { formatTime } from '../../../../../../helpers/dateTimeHelper';

type Props = {
  vmrDetails: {
    [key: string]: any;
  };
};

const VehicleInfo = (props: Props) => {
  const { vmrDetails } = props;
  const [data, setData] = useState<ObjectType>([]);
  useEffect(() => {
    setData({
      mileage: vmrDetails?.mileage,
      restDay: vmrDetails?.rest_day?.day,
    });
  }, [vmrDetails]);

  return (
    <div>
      <Card body className=' p-2'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1'>
            <div className='flex-grow-1'>
              <div className=''>
                <div className='text-blue fw-semibold'>Driver Information</div>
                
              </div>
              <Row className='w-100 mt-2'>
                <Col className='mt-2' md={2}>
                  <div>
                    <small className='text-grey'>Driver Name</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {vmrDetails?.driver_name}
                  </div>
                </Col>
                <Col className='mt-2' md={2}>
                  <div>
                    <small className='text-grey'>Phone Number</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {vmrDetails?.driver_phone_number}
                  </div>
                </Col>
                
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default VehicleInfo;
