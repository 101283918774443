import { Col, Row } from 'reactstrap';
import { calculatePostDate } from '../../../../helpers/dateTimeHelper';
import GradientButton from '../../../../components/shared/gradidentButton';
type Props = {
  handleToggle?: VoidFunction;
  viewedReferral?: {
    [key: string]: any;
  };
};

const ReferralModalBody = ({ handleToggle, viewedReferral }: Props) => {
  return (
    <div>
      <div className='fw-bold fs-5'>Transaction Details</div>
      <Row className='mt-3'>
        <Col>
          <div>
            <small className='text-grey'>Amount</small>
          </div>
          <div className='mt-1 fw-semibold'>
            ₦
            {Math.floor(
              viewedReferral?.trip_data?.amount || 0
            )?.toLocaleString()}
          </div>
        </Col>
        <Col>
          <div>
            <small className='text-grey'>Referee Number</small>
          </div>
          <div className='mt-1 fw-semibold'>
            {viewedReferral?.phone_number || 'N|A'}
          </div>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <div>
            <small className='text-grey'>Referee Trip Amount</small>
          </div>
          <div className='mt-1 fw-semibold'>
            ₦
            {Math.floor(
              viewedReferral?.trip_data?.referee_trip_data?.amount || 0
            )?.toLocaleString()}
          </div>
        </Col>
        <Col>
          <div>
            <small className='text-grey'>Referee Trip Ref</small>
          </div>
          <div className='mt-1 fw-semibold'>
            {viewedReferral?.trip_data?.referee_trip_data?.trip_ref || 'N|A'}
          </div>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <div>
            <small className='text-grey'>Referee Trip Reference</small>
          </div>
          <div className='mt-1 fw-semibold'>
            {viewedReferral?.trip_data?.referee_trip_data?.trip_ref || 'N|A'}
          </div>
        </Col>
      </Row>

      <div className='d-flex justify-content-end'>
        <div className='mt-5' style={{ width: 120, marginTop: 40 }}>
          <GradientButton
            className='py-2'
            text='Close'
            onClick={handleToggle}
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralModalBody;
