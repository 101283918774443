import { useState } from "react";
import { BsEyeFill } from "react-icons/bs";
import { Card } from "reactstrap";
import { getActualAddress } from "../../../../../../../../helpers/locationHelper";
import { ObjectType } from "../../../../../../../../types/common";

type Props = {
  viewedTrip?: { [key: string]: any } | null;
  focusedUser: ObjectType | null;
};
const LocationAndDistanceDetails = (props: Props) => {
  const { viewedTrip, focusedUser } = props;
  const [actualLocation, setActualLocation] = useState("");

  const focusedUserObject = viewedTrip?.riders.find((item: ObjectType) => item.rider_id === focusedUser);

  const Prototype = ({ label, value }: { label: string; value: any }) => (
    <div className="mt-4 col col-md-4">
      <div>
        <small className="text-grey">{label}</small>
      </div>
      <div className="mt-1 fw-semibold">{value}</div>
    </div>
  );

  const getAddress = async (lat: string, lon: string) => {
    const res = await getActualAddress(lat, lon);
    setActualLocation(res);
  };

  // const ActualLocationUI = ({ lat, lon }) => <div>{actualLocation ? "empp qwjoresdlkvnqer efdjlvkerds" : <div>{actualLocation}</div>}</div>;
  const ActualLocationUI = ({ lat, lon }: { lat: string; lon: string }) => <div> {actualLocation ? actualLocation : <BsEyeFill onClick={() => getAddress(lat, lon)} />}</div>;

  return (
    <Card body className=" p-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1">
          <div className="flex-grow-1">
            <div className="text-blue fw-semibold">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="26" height="26" rx="5" fill="#F5FBFD" />
                <path
                  d="M18.7472 10.6337C18.0472 7.55366 15.3606 6.16699 13.0006 6.16699H12.9939C10.6406 6.16699 7.94722 7.54699 7.24722 10.627C6.46722 14.067 8.57389 16.9803 10.4806 18.8137C11.1564 19.4678 12.06 19.8335 13.0006 19.8337C13.9072 19.8337 14.8139 19.4937 15.5139 18.8137C17.4206 16.9803 19.5272 14.0737 18.7472 10.6337ZM13.0006 13.9737C12.7248 13.9737 12.4517 13.9193 12.1969 13.8138C11.9421 13.7083 11.7106 13.5536 11.5156 13.3586C11.3206 13.1636 11.1659 12.9321 11.0604 12.6773C10.9549 12.4225 10.9006 12.1494 10.9006 11.8737C10.9006 11.5979 10.9549 11.3248 11.0604 11.07C11.1659 10.8152 11.3206 10.5837 11.5156 10.3887C11.7106 10.1937 11.9421 10.039 12.1969 9.93351C12.4517 9.82798 12.7248 9.77366 13.0006 9.77366C13.5575 9.77366 14.0916 9.99491 14.4855 10.3887C14.8793 10.7826 15.1006 11.3167 15.1006 11.8737C15.1006 12.4306 14.8793 12.9648 14.4855 13.3586C14.0916 13.7524 13.5575 13.9737 13.0006 13.9737Z"
                  fill="#2FB5D9"
                />
              </svg>
              <span className="mt-1 ms-1"> Location and Distance Details</span>
            </div>
            <div className="row">
              <Prototype label="Pickup Location" value={focusedUserObject?.start_address} />
              <Prototype label="Drop off Location" value={focusedUserObject?.end_address} />
              <Prototype label="View actual Drop off Address" value={<ActualLocationUI lat={focusedUserObject?.end_lat} lon={focusedUserObject?.end_lon} />} />
              <Prototype label="Pickup Distance" value={focusedUserObject?.pickup_distance ? `${focusedUserObject?.pickup_distance || ""}km` : ""} />

              <Prototype label="Estimated Distance" value={focusedUserObject?.est_dst ? `${focusedUserObject?.est_dst || ""}km` : ""} />

              <Prototype label="Pickup Latitude" value={focusedUserObject?.start_lat} />
              <Prototype label="Drop off Latitude" value={focusedUserObject?.end_lat} />
              <Prototype label="Pickup Longitude" value={focusedUserObject?.start_lon} />
              <Prototype label="Drop off Longitude" value={focusedUserObject?.end_lon} />
              {/* <Prototype label="Updated Destination" value="Eti-Osa Lekki 1" /> */}
              <Prototype label="End distance" value={`${focusedUserObject?.end_distance}km`} />
              <Prototype label="Total Distance Covered" value={`${focusedUserObject?.total_distance}km`} />
              {/* <Prototype label="Actual map of transit" value="Lekki Phase 1 to Ajah" /> */}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default LocationAndDistanceDetails;
