import { Dispatch, SetStateAction } from 'react';
import { IoVolumeMedium } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import queryKeys from '../../../../constants/queryKeys';
import { useUsersApiService } from '../../../../services/usersService';
import { useEmergencyApiService } from '../../../../services/emergencyApiServices';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setEmergencyType: Dispatch<SetStateAction<string>>;
};

const OverviewSumaryCards = (props: Props) => {
  const { getEmergencyToast } = useEmergencyApiService();
  const { setEmergencyType, setActiveTab } = props;

  const { data: emergency } = useQuery(
    [queryKeys.emergency],
    () =>
      getEmergencyToast({
        component: 'count-status',
      }),
    {
      select: (data) => data?.data[0] || [],
      // enabled: false,
      keepPreviousData: true,
    }
  );

  const itemsTrips = [
    {
      title: 'Total Emergency',
      value: emergency?.total_count || 0,
      cardBgColor: '#0079D016',
      icon: <IoVolumeMedium size={20} color='#002ED0' />,
      iconCardColor: '#002DE016',
      tag: 'total',
    },

    {
      title: 'Resolved Emergency',
      value: emergency?.total_resolved || 0,
      cardBgColor: '#00D0A816',
      iconCardColor: '#00D0A816',
      icon: <IoVolumeMedium size={20} color='#00D2A8' />,
      tag: 'resolved',
    },
    {
      title: 'Unresolved Emergency',
      value: emergency?.total_unresolved || 0,
      cardBgColor: '#FF373916',
      iconCardColor: '#FF373933',
      icon: <IoVolumeMedium size={20} color='#FF3739' />,
      tag: 'unresolved',
    },
  ];

  return (
    <div>
      <h5 className='fw-bold'>Emergency Overview</h5>

      <Row>
        {itemsTrips.map((item) => (
          <Col key={item.title} className='mt-4' md={4}>
            <div
              className='rounded py-3 ps-3 d-flex align-items-center justify-content-between cursor-pointer'
              style={{ backgroundColor: item.cardBgColor, height: 92 }}
              onClick={() => {
                setEmergencyType(item.tag);
                setActiveTab("Emergency Request Log");
              }}
            >
              <div className='d-flex align-items-center'>
                <div
                  className='p-2 rounded'
                  style={{ background: item.iconCardColor }}
                >
                  {item.icon}
                </div>
                <div className='ms-3'>
                  <div style={{ color: '#71939C', fontSize: 15 }}>
                    {' '}
                    {item.title}
                  </div>
                  <h5 className='text-blue-black fw-bold mt-2'>{item.value}</h5>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default OverviewSumaryCards;
