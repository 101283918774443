import { useContext, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { GiCrossMark } from "react-icons/gi";
import { RiArrowDownSLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import "../assets/scss/top-nav.scss";
import { AppPrefrenceContext } from "../contexts/AppPrefrenceContext";
import { LoadingContext } from "../contexts/LoadingContext";
import { confirmDialogue } from "../helpers/confirmationDialogue";
import { useAuthApiServices } from "../services/AuthApiServices";
// import { url } from 'inspector';

const TopNav = ({ title }: { title: string }) => {
  const { logoutUser } = useAuthApiServices();
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const { expanded, toggle, breadcrumbData, setBreadCrumbData } = useContext(AppPrefrenceContext);
  const { getLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  return (
    <div className={"top-nav w-100"} style={{ backgroundColor: "#04242DF2", height: 165 }}>
      <div className="d-flex align-items-end justify-content-between px-2 pb-3">
        <div className="d-flex align-items-end mb-5 ms-2 ">
          <div className="me-2 icons-box cursor-pointer sidebar-control d-md-none" onClick={toggle}>
            {expanded ? (
              <GiCrossMark />
            ) : (
              <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5954 5.29464H1.44775M1.44775 0.875977H6.22096M10.5954 9.34066H6.22096" stroke="#082E38" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            )}
          </div>
          <div className="fs-4 fw-bold mt-5 lh-sm text-white" style={{ zIndex: 0 }}>
            {title}

            <div className="mt-1 d-flex" style={{ fontSize: 14 }}>
              {!getLoading && breadcrumbData ? (
                breadcrumbData.map((item: string, index: number) => (
                  <div key={index} className={`fw-light ${index + 1 === breadcrumbData.length && "bold-brcmb"}`}>
                    {item} {breadcrumbData.length > 1 && index !== breadcrumbData.length - 1 && <span className="mx-2"> {">"} </span>}
                  </div>
                ))
              ) : (
                <div className="invisible">.</div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-end px-2" style={{ marginBottom: 55, zIndex: 0 }}>
          <span className="icons-box me-3 cursor-pointer">
            {/* <BsSearch /> */}
            <img src="/svgs/search.svg" alt="searcg" />
          </span>
          <span className="icons-box me-3 cursor-pointer">
            {/* <MdNotificationsNone /> */}
            <img src="/svgs/notifications.svg" alt="notifications" />
          </span>
          <span className=" cursor-pointer b-3 me-2">
            <img alt="avatar " src="/svgs/avatar.svg" height={30} width={30} />
          </span>
          <span>
            <Dropdown className="" isOpen={dropdownToggle} toggle={() => setDropdownToggle(!dropdownToggle)} style={{ zIndex: 100 }}>
              <DropdownToggle className="bg-transparent border-0 p-0">
                <RiArrowDownSLine size={25} style={{ color: "white" }} onClick={() => setDropdownToggle(!dropdownToggle)} data-toggle="dropdown" />
              </DropdownToggle>
              <DropdownMenu className="mt-2">
                <div className="btn-white my-2 mx-3 cursor-pointer" onClick={() => navigate("/profile")}>
                  <span style={{ backgroundColor: "#F3E7FF" }}>
                    {/* <img src="/svgs/notification.svg" alt="notifications" /> */}
                    <CgProfile />
                  </span>
                  <span className="ms-2">Profile </span>
                </div>
                <div
                  onClick={() =>
                    confirmDialogue({
                      message: "Logout user",
                      asyncFunction: logoutUser,
                    })
                  }
                  className="btn-white my-2 mx-3 cursor-pointer "
                >
                  <span style={{ backgroundColor: "#EAFBF8" }}>
                    <img src="/svgs/logout.svg" alt="logout" />
                  </span>
                  <span className="ms-2">Logout</span>
                </div>
              </DropdownMenu>
            </Dropdown>
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
