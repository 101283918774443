import { Dispatch, ReactNode, SetStateAction, createContext, useState } from "react";

interface MyContextType {
  postLoading: boolean;
  getLoading: boolean;
  setGetLoading: Dispatch<SetStateAction<boolean>>;
  setPostLoading: Dispatch<SetStateAction<boolean>>;
}

// export const LoadingContext = createContext<MyContextType | undefined>(undefined);
export const LoadingContext = createContext<any>(undefined);

const LoadingContextProvider = ({ children }: { children: ReactNode }) => {
  const [getLoading, setGetLoading] = useState(false); //the loader whe there is a get request
  const [postLoading, setPostLoading] = useState(false); //the loader whe there is a post request
  return <LoadingContext.Provider value={{ getLoading, setGetLoading, postLoading, setPostLoading }}>{children}</LoadingContext.Provider>;
};

export default LoadingContextProvider;
