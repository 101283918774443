import 'chart.js/auto';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { Card, CardTitle } from 'reactstrap';
import FilterOptions from '../../../../components/filter/Filter';
import YearsCalender from '../../../../components/filter/yearsCalender';
import queryKeys from '../../../../constants/queryKeys';
import { getCurrentMonthAndYear } from '../../../../helpers/dateTimeHelper';
import { useWalletApiService } from '../../../../services/walletApiServices';
import { ObjectType, ReactChangeEventType } from '../../../../types/common';

const TripPaymentsTrend = () => {
  const [startDate, setStartDate] = useState(getCurrentMonthAndYear());
  const [endDate, setEndDate] = useState(getCurrentMonthAndYear());
  const [dateType, setDateType] = useState('yearly-single');
  const [toggled, setToggled] = useState(false);
  const { getTripPaymentToast } = useWalletApiService();

  const handleToggle = () => {
    setToggled((prevStatus) => !prevStatus);
  };
  const handleDateTypeChange = (e: ReactChangeEventType) =>
    setDateType(e.target.value);

  const { data: tripPaymentTrend } = useQuery(
    [queryKeys.tripsPaymentTrend, startDate, endDate],
    () =>
      getTripPaymentToast({
        component: 'count-monthly',
        start_date: `${startDate.split('-')[0]}-01-01`,
        end_date: `${endDate.split('-')[0]}-12-31`,
      }),
    {
      select: (data) => data?.data,
    }
  );

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: 'white',
        },
        beginAtZero: true,
      },
      y: {
        grid: {
          display: false,
          borderColor: 'white',
        },
        ticks: {
          min: 0,
          max: 15,
          stepSize: 20,
        },
        beginAtZero: true,
        // steps: 4
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
  };
  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Total Payments',
        data: new Array(12).fill(0).map((_, index) => {
          const month = (index + 1).toString().padStart(2, '0'); // Ensure month is two digits
          return (
            tripPaymentTrend?.find(
              (item: ObjectType) => item?.month === month
            )?.total || 0
          );
        }),
        borderWidth: 1,
        borderColor: '#05b4b0',
        pointRadius: 2,
      },
    ],
  };
  const datyTypeOptions = [
    {
      value: 'yearly-single',
      label: 'Yearly',
      subOptionComponent: (
        <YearsCalender
          handleToggle={handleToggle}
          month={startDate}
          setYear={(year) => {
            setStartDate(year);
            setEndDate(year);
          }}
        />
      ),
    },
  ];

  return (
    <Card body>
      <CardTitle className=' justify-content-between'>
        <div className='justify-content-between d-flex w-100 mb-3'>
          <div className='fw-bold'>Trip Payments Trend </div>
          <div className='d-flex justify-content-end'>
            <FilterOptions
              direction='left'
              toggled={toggled}
              handleToggle={handleToggle}
              options={datyTypeOptions}
              selectedOption={dateType}
              optionChange={handleDateTypeChange}
              name='registration-chart'
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </CardTitle>
      <div>
        <Line data={data as any} options={options} />
      </div>
    </Card>
  );
};

export default TripPaymentsTrend;
