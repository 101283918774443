type Props = {
  onClick?: VoidFunction;
};

const FilterComponent = (props: Props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="cursor-pointer">
      <img src="/svgs/filter.svg" />
    </div>
  );
};

export default FilterComponent;
