
import { Helmet } from "react-helmet";
// import { AuthContext } from "../contexts/AuthContext";
import { Navigate } from "react-router";

interface Props {
   pageTitle?: string;
   // component?: LazyExoticComponent<ComponentType<any>>;
   component?: any;
   isAuthProtected: boolean;
   leadText?: string;
   // layout: ({ children, title }: { children: any; title: any }) => ReactElement<any, any>;
   layout: any;
}

const AuthMiddleware = ({ component: Component, layout: Layout, isAuthProtected, pageTitle, leadText, ...rest }: Props) => {
   // const {auth} = useContext(AuthContext);
   const auth = localStorage.getItem("access_token");

   if (isAuthProtected && !auth) {
      return <Navigate to="/signin" />;
   }
   if (!isAuthProtected && auth) {
      return <Navigate to="/" />;
   }

   return (
      <>
         <Helmet>
            <title>{"Lagos Ride Admin - " + pageTitle}</title>
         </Helmet>
         <Layout title={pageTitle}>
            <Component
            //  {...props}
            />
         </Layout>
      </>
   );
};

// AuthMiddleware.propTypes = {
//   isAuthProtected: PropTypes.bool,
//   component: PropTypes.any,
//   location: PropTypes.object,
//   layout: PropTypes.any,
// };
export default AuthMiddleware;
