export const scheduleStatusOptions = [
  { label: "Pending", value: "0" },
  { label: "Fullfiled", value: "1" },
  { label: "Unfullfiled", value: "2" },
  { label: "Assigned", value: "3" },
  { label: "Unassigned", value: "4" },
];

export const geScheduleStatus = (status: string) => {
  switch (status) {
    case "0":
      return "Pending";
    case "1":
      return "Fullfiled";
    case "2":
      return "Unfullfiled";
    case "3":
      return "Assigned";
    case "4":
      return "Unassigned";
    default:
      break;
  }
};
export const geScheduleStatusColor = (status: string) => {
  switch (status) {
    case "0":
      return "text-warning";
    case "1":
      return "text-success";
    case "2":
      return "text-danger";
    case "3":
      return "text-blue";
    case "4":
      return "text-warning";
    default:
      break;
  }
};
