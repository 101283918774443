import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthMiddleware from '../middleware/AuthMiddleware';
// import NonLayout from "../components/layouts/NonLayout"
import { Suspense } from 'react';
import ProtectedPages from '../Pages/ProtectedPages';
import LoaderPage from '../components/LoaderPage';
import AuthLayout from '../components/layouts/AuthLayout';
import useUrlComp from '../constants/urls';
import { authRoutes } from './routesList';
// import AllModals from "../components/modals/AllModals";

const RoutesCoponent = () => {
  useUrlComp();
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <LoaderPage
          // size={100} type={"ThreeDots"}
          />
        }
      >
        {/* <AllModals /> */}
        <Routes>
          {authRoutes.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              element={
                <AuthMiddleware
                  isAuthProtected={false}
                  key={i}
                  pageTitle={route.title}
                  component={route.component}
                  layout={AuthLayout}
                  leadText={route.leadText}
                />
              }
            />
          ))}
        </Routes>
        <ProtectedPages />
      </Suspense>
    </BrowserRouter>
  );
};

export default RoutesCoponent;
