import { FormEvent, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { FormGroup, Input, Label } from "reactstrap";
import GradientButton from "../../../../components/shared/gradidentButton";
import { useSetupApiService } from "../../../../services/setupApiServices";
import { ObjectType } from "../../../../types/common";

const AddFeeConfigModalBody = ({ setModalOpen, feeName, feeTag, fetchedList, feesList, addFeesModalToggle }: any) => {
  const [name, setName] = useState(feesList[0]?.name);
  const [amount, setAmount] = useState("");
  const [unit, setUnit] = useState("");
  const queryClient = useQueryClient();
  const [selectedFee, setSelectedFee] = useState({});

  // console.log(feesList);

  const { updateFeeToast, createFeeToast } = useSetupApiService();

  const createFeeMutation = useMutation(createFeeToast, {
    onSuccess: async (res) => {
      if (res.data.status !== "error") {
        setModalOpen(false);
        queryClient.invalidateQueries("feesSetup");
      }
    },
    // onError: (err) => {},
  });

  const updateFeeMutation = useMutation(updateFeeToast, {
    onSuccess: async (res) => {
      if (res.data.status !== "error") {
        queryClient.invalidateQueries("feesSetup");
        setModalOpen(false);
      }
    },
    // onError: (err) => {},
  });

  const updateFee = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const data = fetchedList[0];
    const keysToRemove = ["updatedAt", "system_generated", "createdAt"];

    // Use object destructuring and filter to create the payload
    const payload: ObjectType = Object.keys(data)
      .filter((key) => !keysToRemove.includes(key))
      .reduce((result, key) => ({ ...result, [key]: data[key] }), {});

    // Assuming 'name' and 'amount' are defined elsewhere
    const newData = { key: name, value: amount };

    // Mutate the state with the updated payload and newData
    updateFeeMutation.mutate({ ...payload, data: [...(payload.data || []), newData] });
  };

  // const updateFee = (e: { preventDefault: () => void }) => {
  //   e.preventDefault();
  //   const data = fetchedList[0];
  //   const payload = { data: [] };
  //   const keysToRemove = ["updatedAt", "system_generated", "createdAt"];
  //   for (const key in data) {
  //     // Check if the current key is not in the keysToRemove array
  //     if (!keysToRemove.includes(key)) {
  //       // If it's not in the keysToRemove, add the key-value pair to the filtered object
  //       payload[key] = data[key];
  //     }
  //   }
  //   updateFeeMutation.mutate({ ...payload, data: [...payload?.data, { key: name, value: amount }] });
  // };

  const createFee = (e: FormEvent) => {
    e.preventDefault();

    createFeeMutation.mutate({
      name,
      amount,
      tag: feesList?.find((item: ObjectType) => item.name === name)?.tag,
      screen_name: feesList?.find((item: ObjectType) => item.name === name)?.screen_name,
      // tag: feeTag,
      desc: "placeholder text",
      amount_sufix: unit,
      // data: [{ key: name, value: amount }],
    });
    // navigate("/team/add-employees", { state: { mode } });
  };

  return (
    <div>
      {/* <form onSubmit={fetchedList?.length === 0 ? createFee : updateFee}> */}
      <form onSubmit={createFee}>
        <FormGroup className="bg-white mt-3">
          <Label for="name">Name</Label>
          <Input id="name" name="name" placeholder="Enter fee name" type="select" className="bg-transparent text-capitalize" value={name} onChange={(e) => setName(e.target.value)} required>
            {feesList.map((item: ObjectType) => (
              <option key={item.value} value={item.name}>
                <div className="">{item?.name}</div>
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup className="bg-white mt-4">
          <Label for="amount">Amount</Label>
          <Input id="amount" name="amount" placeholder="Enter Amount" type="number" className="bg-transparent" value={amount} onChange={(e) => setAmount(e.target.value)} required />
        </FormGroup>
        <FormGroup className="bg-white mt-4">
          <Label for="amount">
            Unit of Amount <small className="text-muted ms-2">(e.g ₦, %, Km, Secs)</small>
          </Label>
          <Input id="unit" name="unit" placeholder="Enter Unit" type="text" className="bg-transparent" value={unit} onChange={(e) => setUnit(e.target.value)} />
        </FormGroup>
        <div style={{ width: 170, marginTop: 80 }}>
          <GradientButton text="Add Item" />
        </div>
      </form>
    </div>
  );
};

export default AddFeeConfigModalBody;
