import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Input, Table } from "reactstrap";
import EmptyPage from "../../../../components/EmptyPage";
import NewFilter from "../../../../components/filter/NewFilter";
import CusCard from "../../../../components/shared/cusCard";
import FilterComponent from "../../../../components/shared/filterComponent";
import WaitingLoader from "../../../../components/shared/waitingLoader";
import PaginationComponent from "../../../../components/tablesComponents/paginationComponent";
import SeearchComponent from "../../../../components/tablesComponents/searchComponent";
import TableDataCounter from "../../../../components/tablesComponents/tableDataCounter";
import queryKeys from "../../../../constants/queryKeys";
import { AppPrefrenceContext } from "../../../../contexts/AppPrefrenceContext";
import { usePaginationHelper } from "../../../../helpers/paginationHelper";
import { useQueryStringHelper } from "../../../../helpers/queryStringHelper";
import { useUsersApiService } from "../../../../services/usersService";
import { ObjectType, ReactChangeEventType } from "../../../../types/common";
import GradientButton from "../../../../components/shared/gradidentButton";

type Props = {
  receivers: string;
  selectedUsers: string[];
  setSelectedUsers: Dispatch<SetStateAction<string[]>>;
};

const RecipientsLog = (props: Props) => {
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const { receivers, selectedUsers, setSelectedUsers } = props;

  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const { getPageNoFromQueryString } = useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const { deleteModalConfig } = useContext(AppPrefrenceContext);
  const [keyword, setKeyword] = useState("");
  const [editingId, setEditingId] = useState("");
  const [data, setData] = useState(Object);
  const [filterToggle, setFilterToggle] = useState(false);
  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });
  const { getUsersToast } = useUsersApiService();

  const queryClient = useQueryClient();

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    // paginate({
    //   pageNumber,
    //   setCurrentPage,
    //   // callbackFunction: null,
    // });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const {
    isLoading,
    data: riders,
    refetch: refetchRiders,
  } = useQuery(
    [queryKeys.allRiders, currentPage, keyword, filterState, receivers],
    () =>
      getUsersToast({
        page: currentPage,
        userType: receivers === "selected_drivers" ? "driver" : receivers === "selected_riders" ? "rider" : "business",
        q: keyword,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data,
      keepPreviousData: true,
    }
  );

  const { data: ridersCount } = useQuery(
    [queryKeys.allRidersCount, currentPage, keyword, filterState, receivers],
    () =>
      getUsersToast({
        page: currentPage,
        userType: receivers === "selected_drivers" ? "driver" : receivers === "selected_riders" ? "rider" : "business",
        q: keyword,
        start_date: startDate,
        end_date: endDate,
        component: "count",
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
    }
  );

  const onApply = () => {
    setFilterState({ startDate, endDate });
    handleToggle();
  };

  const allChecked = riders?.every((rider: ObjectType) => selectedUsers.includes(rider.auth_id));

  const updateList = (e: ReactChangeEventType) => {
    if (e.target.name === "all") {
      if (e.target.checked) {
        const bucket: string[] = [];
        riders.forEach((rider: ObjectType) => {
          if (!selectedUsers.includes(rider?.auth_id)) bucket.push(rider.auth_id);
        });
        setSelectedUsers([...selectedUsers, ...bucket]);
      } else {
        const filteredUserIds = selectedUsers.filter((userId) => !riders.some((rider: ObjectType) => rider.auth_id === userId));
        setSelectedUsers(filteredUserIds);
      }
    } else {
      if (e.target.checked) {
        setSelectedUsers([...selectedUsers, e.target.value]);
      } else {
        setSelectedUsers(selectedUsers.filter((item) => item !== e.target.value));
      }
    }
  };

  return (
    <CusCard className="p-3" style={{ minHeight: "70vh" }}>
      {isLoading && <WaitingLoader />}
      {!isLoading && (
        <>
          <div className="d-flex justify-content-between text-nowrap flex-wrap">
            <div className="d-flex align-items-center">
              <div className="d-flex">
                <div className="fw-bold mt-1 me-4">
                  {selectedUsers.length} {receivers === "selected_drivers" ? "Drivers" : receivers === "selected_riders" ? "Riders" : "Business Accounts"} Selected
                </div>
                <GradientButton className="py-1 me-2" text="Clear all" onClick={() => setSelectedUsers([])} />
              </div>
              <div className="ms-4">{<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="me-3">
                <NewFilter
                  direction={"start"}
                  toggleComponent={<FilterComponent />}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  // options={filterOptions}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onApply={onApply}
                />
              </div>
            </div>
          </div>
          <div className="mt-2 ">
            {riders?.length === 0 && <EmptyPage text="No Riders" />}
            {riders?.length > 0 && (
              <Table responsive>
                <thead className="fw-normal">
                  <tr className="">
                    <th>
                      <Input type="checkbox" name="all" onChange={updateList} checked={allChecked} />
                    </th>
                    <th className="fw-normal "> Name</th>
                    <th className="fw-normal">Email Address</th>
                  </tr>
                </thead>
                <tbody>
                  {riders.map((item: { [key: string]: any }, index: number) => (
                    <tr className="cursor-pointer border-bottom border-1" key={item.auth_id} style={{ borderColor: "#e6eef0" }}>
                      <td>
                        <Input type="checkbox" value={item?.auth_id} onChange={updateList} checked={(() => selectedUsers.includes(item?.auth_id))()} />
                      </td>
                      <td className="d-flex align-items-center">
                        {item?.first_name} {""}
                        {item?.last_name}
                      </td>
                      <td>{item.email}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {riders?.length > 0 && (
              <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
                <div className="mt-2">
                  <TableDataCounter item={riders} currentPage={currentPage} totalCount={ridersCount} />
                </div>
                <div className="mt-2">
                  <PaginationComponent activePage={currentPage} itemsCountPerPage={50} totalItemsCount={ridersCount} onChange={onPaginate} />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </CusCard>
  );
};
export default RecipientsLog;
