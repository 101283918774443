import axios from "axios";
import { api } from "../constants/urls";
import { useToastifyPromises } from "../helpers/toastifyHelper";

export const useMessagesApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //get Notifications
  const getNotificationsReq = ({ page = 1, item_per_page = 50, q = "", start_date = "", end_date = "", component = "" }: any) => {
    return axios.get(api.messages + `/admin/user-notification/?page=${page}&item_per_page=${item_per_page}&q=${q}&start_date=${start_date}&end_date=${end_date}&component=${component}`);
  };
  const getNotificationsToast = (body?: any) => toastifyPromises.get({ asyncFunction: getNotificationsReq(body) });

  //2. create notifications
  const createNotificationsReq = (body: any) => axios.post(api.messages + `/admin/user-notification`, body);
  const createNotificationsToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: createNotificationsReq(body),
      pendingMsg: "Creating Notification",
      SuccessMsg: "Notification Created",
    });

  return {
    getNotificationsToast,
    createNotificationsToast,
  };
};
