import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { AppPrefrenceContext } from "../../../contexts/AppPrefrenceContext";
import { FormGroup, Input, Label } from "reactstrap";
import { ObjectType, ReactChangeEventType, ReactSubmitEventType } from "../../../types/common";
import { useMutation, useQueryClient } from "react-query";
import { useUsersApiService } from "../../../services/usersService";
import TextEditor from "./components/textEditor";
import RecipientsLog from "./components/recipientsLog";
import { useMessagesApiService } from "../../../services/messagesApiServicr";
import GradientButton from "../../../components/shared/gradidentButton";
import queryKeys from "../../../constants/queryKeys";

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setPromoType: Dispatch<SetStateAction<string>>;
};
const NotificationOverview = (props: Props) => {
  const queryClient = useQueryClient();

  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const [data, setData] = useState<ObjectType | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const { createNotificationsToast } = useMessagesApiService();

  useEffect(() => {
    setBreadCrumbData(["Overview"]);
  }, []);

  const { title, type, message, receivers }: ObjectType = data || {};

  const sendNotificationMutation = useMutation(createNotificationsToast, {
    onSuccess: async (res) => {
      if (res.data.status !== "error") {
        setData(null);
        queryClient.invalidateQueries(queryKeys.notifications);
      }
    },

    // onError: (err) => {},
  });

  const types = [
    { name: "Email", value: "email" },
    { name: "Push Notification", value: "push_notification" },
  ];
  const recipients = [
    { name: "All Users", value: "users" },
    { name: "All Riders", value: "riders" },
    { name: "All Drivers", value: "drivers" },
    { name: "All Businesses", value: "businesses" },
    { name: "Selected Riders", value: "selected_riders" },
    { name: "Selected Drivers", value: "selected_drivers" },
    { name: "Selected Businesses", value: "selected_businesses" },
  ];

  const handleChange = (e: ReactChangeEventType) => {
    setData({ ...data, [e.target.name]: e.target.value });
    ["users", "riders", "drivers", "businesses"].includes(e.target.value) && setSelectedUsers([]);
  };
  const editorTextChange = (message: string) => setData({ ...data, message });

  const sendNotification = (e: ReactSubmitEventType) => {
    e.preventDefault();
    sendNotificationMutation.mutate({
      type: type === "email" ? "mail" : "app",
      subject: title,
      message,
      recipient: selectedUsers.length > 0 ? selectedUsers : data?.receivers,
    });
  };

  return (
    <div style={{ maxWidth: 700, overflow: "hidden", paddingLeft: 10 }}>
      <h5 className="fw-bold">Compose Notification </h5>

      <form onSubmit={sendNotification}>
        <FormGroup className="bg-white mt-3">
          <Label for="title">Title</Label>
          <Input id="title" name="title" placeholder="Title" type="text" className="bg-transparent" value={title} onChange={handleChange} required />
        </FormGroup>
        <FormGroup className="bg-white mt-4">
          <Label for="type">Notification Options</Label>
          <Input id="type" name="type" type="select" className="bg-transparent" value={type} onChange={handleChange} required>
            <option value="">--Select Type--</option>
            {types.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Input>
        </FormGroup>

        <FormGroup className="bg-white mt-4">
          <Label for="message">Message</Label>
          {type === "email" ? (
            <TextEditor editorTextChange={editorTextChange} data={data} />
          ) : (
            <Input resize="none" rows={7} id="message" name="message" placeholder="Message" type="textarea" className="bg-transparent" value={message} onChange={handleChange} required />
          )}
        </FormGroup>
        <FormGroup className="bg-white mt-4">
          <Label for="receivers">Select Recipient</Label>
          <Input id="receivers" name="receivers" type="select" className="bg-transparent" value={receivers} onChange={handleChange} required>
            <option>--Select Recipients--</option>
            {recipients.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <div>{recipients.slice(3).some((item) => item.value === receivers) && <RecipientsLog receivers={receivers} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />}</div>
        {data?.receivers && (
          <div className="mt-5" style={{ width: 150 }}>
            <GradientButton text="Send Notification" className="py-2" disabled={sendNotificationMutation.isLoading} />
          </div>
        )}
      </form>
    </div>
  );
};

export default NotificationOverview;
