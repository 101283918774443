import SubTitle from "./subtitle";

type Props = {
  items: string[] | number[];
  subTitle: string;
};

const OneColumn = (props: Props) => {
  const { items, subTitle } = props;
  const OneItem = ({ text }: { text: string | number }) => <div className="mb-5">{text}</div>;
  return (
    <div className="flex-grow-2 " style={{ width: 120 }}>
      <SubTitle text={subTitle} />
      {items &&
        items?.map((item, index) => (
          <div key={index}>
            <OneItem text={item} />
          </div>
        ))}
    </div>
  );
};

export default OneColumn;
