import { useMutation, useQueryClient } from "react-query";
import { FormGroup, Input, Label } from "reactstrap";
import GradientButton from "../../../../components/shared/gradidentButton";
import queryKeys from "../../../../constants/queryKeys";
import { useAreaApiService } from "../../../../services/areaApiServices";
import { ObjectType } from "../../../../types/common";
import lgaList from "../../setup/data/lgaList";
import GoogleLocationSearch from "./googleLocationSearch";
import { SetStateAction, useState } from "react";
import SelectDriver from "./selectDriver";
import { useSchedulesApiService } from "../../../../services/schedulesApiServices";
import { getPlaceDetails } from "../../../../helpers/locationHelper";

type Props = {
  data: ObjectType;
  handleChange: (e: any) => void;
  handleToggle: VoidFunction;
  editingId: string;
  refresh: VoidFunction;
};

const CreateScehduleModalBody = ({ data, handleChange, handleToggle, editingId, refresh }: Props) => {
  const [pickup, setPickup] = useState<ObjectType | null>(null);
  const [dropoff, setDropoff] = useState<ObjectType | null>(null);
  const [pickupGeometry, setPickupGeometry] = useState<ObjectType | null>(null);
  const [dropoffGeometry, setDropoffGeometry] = useState<ObjectType | null>(null);

  const [driver, setDriver] = useState<ObjectType | null>(null);
  const { date, time, notes } = data || {};
  const queryClient = useQueryClient();

  const { createScheduleToast } = useSchedulesApiService();
  

  const createScheduleMutation = useMutation(createScheduleToast, {
    onSuccess: async (res) => {
      if (res.data.status !== "error") {
        handleToggle();
        queryClient.invalidateQueries(queryKeys.schedules);
        queryClient.invalidateQueries(queryKeys.schedulesCount);
        refresh();
      }
    },
    // onError: (err) => {},
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createScheduleMutation.mutate({
      start_lon: pickupGeometry?.lng?.toString(),
      start_lat: pickupGeometry?.lat?.toString(),
      end_lat: dropoffGeometry?.lat?.toString(),
      end_lon: dropoffGeometry?.lng?.toString(),
      start_address: pickup?.label,
      end_address: dropoff?.label,
      ride_date: date,
      ride_time: time,
      driver_id: driver?.value?.auth_id,
    });
  };

  const onPickupChange = async (pickupObj: ObjectType | null) => {
    setPickup(pickupObj);
    const placeId = pickupObj?.value?.place_id;
    const detailsRes = await getPlaceDetails(placeId);
    const lngLat = detailsRes?.result?.geometry?.location;
    setPickupGeometry(lngLat);
  };

  const onDropOffChangeChange = async (dropOffObj: ObjectType | null) => {
    setDropoff(dropOffObj);
    const placeId = dropOffObj?.value?.place_id;
    const detailsRes = await getPlaceDetails(placeId);
    const lngLat = detailsRes?.result?.geometry?.location;
    setDropoffGeometry(lngLat);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="two-way-grid">
          <FormGroup className="bg-white mt-1 mb-1">
            <Label for="pickup">Pickup Location</Label>
            <GoogleLocationSearch value={pickup} onChange={onPickupChange} />
            {/* <GoogleLocationSearch   /> */}
            {/* <Input id="pickup" name="pickup" type="text" className="bg-transparent" value={pickup} onChange={handleChange} /> */}
          </FormGroup>

          <FormGroup className="bg-white mt-1 mb-1">
            <Label for="dropoff">Dropoff Location</Label>
            <GoogleLocationSearch value={dropoff} onChange={onDropOffChangeChange} />
            {/* <GoogleLocationSearch  /> */}
            {/* <Input id="dropoff" name="dropoff" type="text" className="bg-transparent" value={dropoff} onChange={handleChange} /> */}
          </FormGroup>

          <FormGroup className="bg-white mt-1 mb-1">
            <Label for="date">Date</Label>
            <Input id="date" name="date" type="date" className="bg-transparent" value={date} onChange={handleChange} required />
          </FormGroup>

          <FormGroup className="bg-white mt-1 mb-1">
            <Label for="dropoff">Time</Label>
            <Input id="time" name="time" type="time" className="bg-transparent" value={time} onChange={handleChange} required />
          </FormGroup>

          <FormGroup className="bg-white mt-1 mb-1">
            <Label for="vehicle">Select a Vehicle</Label>
            <SelectDriver value={driver} onChange={setDriver} />
          </FormGroup>
        </div>
        <FormGroup className="bg-white mt-3 mb-1">
          <Label for="notes">Notes</Label>
          <Input id="notes" name="notes" type="textarea" className="bg-transparent" value={notes} onChange={handleChange} rows={5} style={{ resize: "none" }} />
        </FormGroup>
        <div style={{ width: 170, marginTop: 50 }}>
          <GradientButton text={`${editingId ? "Update Schedule" : "Schedule Trip"}`} disabled={!pickupGeometry || !dropoffGeometry} />
        </div>
      </form>
    </div>
  );
};

export default CreateScehduleModalBody;
