import { Dispatch, ReactNode, SetStateAction } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

type Props = {
  //   right?: ReactNode;
  //   left?: ReactNode;
  //   onFocus?: VoidFunction;
  //   formik?: any;
  label: string;
  placeholder?: string;
  name?: string;
  type?: InputType;
  fileReplacement?: ReactNode;
  required?: boolean;
  leftBorderEnd?: boolean;
  onChange?: Dispatch<SetStateAction<any>>;
  accept?: string;
  value?: string;
  selectOptions?: { [key: string]: any }[];
};

const InputComponent = (props: Props) => {
  const { type, label, placeholder, name, required, onChange, value, selectOptions } = props;

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      {type === "select" ? (
        <Input id={name} name={name} placeholder={placeholder} type={type ? type : "text"} className="bg-white" value={value} onChange={onChange} required={required}>
          {selectOptions?.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Input>
      ) : (
        <Input id={name} name={name} placeholder={placeholder} type={type ? type : "text"} className="bg-white" value={value} onChange={onChange} required={required} />
      )}
    </FormGroup>
  );
};

export default InputComponent;
