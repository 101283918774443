import "chart.js/auto";
import { useState } from "react";

import { Line } from "react-chartjs-2";
import { Card, CardTitle, Col } from "reactstrap";
import { ObjectType, ReactChangeEventType } from "../../../../types/common";
import FilterOptions from "../../../../components/filter/Filter";
import YearsCalender from "../../../../components/filter/yearsCalender";
import { useQuery } from "react-query";
import queryKeys from "../../../../constants/queryKeys";
import { useSchedulesApiService } from "../../../../services/schedulesApiServices";
import { getCurrentMonthAndYear } from "../../../../helpers/dateTimeHelper";

// import DateTypeFilter from "./filters/DateTypeFilter";

const FulfilledAnfUnfulfilled = () => {
  // const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  // const [endDate, setEndDate] = useState(getTodayDate());
  const [status, setStatus] = useState("all");
  const [statusToggled, setStatusToggled] = useState(false);
  const [startDate, setStartDate] = useState(getCurrentMonthAndYear());
  const [endDate, setEndDate] = useState(getCurrentMonthAndYear());
  const [dateType, setDateType] = useState("daily");
  const [toggled, setToggled] = useState(false);
  const { getSchedulesToast } = useSchedulesApiService();
  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });
  const handleToggle = () => {
    setToggled((prevStatus) => !prevStatus);
  };
  const handleDateTypeChange = (e: ReactChangeEventType) => setDateType(e.target.value);

  const handleStatusToggled = () => {
    setStatusToggled((prevStatus) => !prevStatus);
  };

  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: schedulesFulfilledAndUnfulfilled,
  } = useQuery([queryKeys.schedulesFulfilledAndUnfulfilled, startDate, endDate], () => getSchedulesToast({ component: "count-status-graph", start_date: `${startDate.split("-")[0]}-01-01`, end_date: `${endDate.split("-")[0]}-12-31` }), {
    select: (data) => (data?.data?.length > 0 ? data?.data : []),
  });
  const handleStatusChange = (e: ReactChangeEventType) => setStatus(e.target.value);
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: "white",
        },
        beginAtZero: true,
      },
      y: {
        grid: {
          display: false,
          borderColor: "white",
        },
        ticks: {
          min: 0,
          max: 15,
          stepSize: 20,
        },
        beginAtZero: true,
        // steps: 4
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
  };
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Dec"],
    datasets: [
      {
        label: "Fulfilled",
        data: new Array(12).fill(0).map((_, index) => {
          return schedulesFulfilledAndUnfulfilled?.find((item: ObjectType) => item?.group_date === index + 1)?.fulfilled || 0;
        }), // fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        borderWidth: 1,
        borderColor: "#00D2A8",
        pointRadius: 2,
        // hoverBorderJoinStyle: "miter",
      },

      {
        label: "Unfulfilled",
        data: new Array(12).fill(0).map((_, index) => {
          return schedulesFulfilledAndUnfulfilled?.find((item: ObjectType) => item?.group_date === index + 1)?.unfullfiled || 0;
        }),
        // fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#fe4c4e",
        borderWidth: 1,
        pointRadius: 2, // hoverBorderJoinStyle: "miter",
      },
    ],
  };
  const datyTypeOptions = [
    {
      value: "monthly",
      label: "Monthly",
      subOptionComponent: (
        <YearsCalender
          handleToggle={handleToggle}
          month={startDate}
          setYear={(year) => {
            setStartDate(year);
            setEndDate(year);
          }}
        />
      ),
    },
  ];
  return (
    <Col md={12} className="mt-2">
      <Card body>
        <CardTitle className=" justify-content-between">
          <div className="justify-content-between d-flex w-100 mb-3">
            <div className="fw-bold">Fulfilled and Unfulfilled Schedules ~ Monthly </div>
            <FilterOptions direction="left" toggled={toggled} handleToggle={handleToggle} options={datyTypeOptions} selectedOption={dateType} optionChange={handleDateTypeChange} name="fulfilledAndUfulfilled" startDate={startDate} endDate={endDate} />
          </div>
        </CardTitle>
        <div>
          <Line data={data} options={options} />
        </div>
      </Card>
    </Col>
  );
};

export default FulfilledAnfUnfulfilled;
