export default {
  feesSetup: 'feesSetup',
  feesList: 'feesList',
  areas: 'areas',
  areasCount: 'areasCount',
  geofence: 'geofence',
  geofenceCount: 'geofenceCount',

  //Users
  usersCount: 'usersCount',
  userWalletCount: 'userWalletCount',
  riderRegistrationStat: 'riderRegistrationStat',
  riderRegistrationExport: 'riderRegistrationExport',
  driverRegistrationExport: 'driverRegistrationExport',
  userDownloadsByLga: 'userDownloadsByLga',
  ridersRatings: 'ridersRatings',
  ridersLeaderboard: 'ridersLeaderboard',

  //trips
  userTrips: 'userTrips',
  userTripsCount: 'userTripsCount',
  userTripsExport: 'userTripsExport',
  tripsDetails: 'tripsDetails',
  userTripsSummary: 'userTripsSummary',
  userTripsSummaryByLocation: 'userTripsSummaryByLocation',
  userTripsPaymentSummary: 'userTripsPaymentSummary',
  tripsUniqueFeatures: 'tripsUniqueFeatures',
  tripsLga: 'tripsLga',
  tripsCharts: 'tripsCharts',
  tripsPaymentSummary: 'tripsPaymentSummary',
  tripsPaymentTrend: 'tripsPaymentTrend',
  tripsPaymentTypes: 'tripsPaymentTypes',
  tripOperationsPerformance: 'tripOperationsPerformance',
  tripOperationsPerformanceBar: 'tripOperationsPerformanceBar',
  tripOperationsPerformanceCount: 'tripOperationsPerformanceCount',
  vehicleOperationsPerformanceCount: 'vehicleOperationsPerformanceCount',
  vehicleOperationsPerformanceExport: 'vehicleOperationsPerformanceExport',

  //Riders
  allRiders: 'allRiders',
  verifiedRiders: 'verifiedRiders',
  unverifiedRiders: 'unverifiedRiders',
  riderDetails: 'riderDetails',

  allRidersCount: 'allRidersCount',
  verifiedRidersCount: 'verifiedRidersCount',
  unverifiedRidersCount: 'unverifiedRidersCount',

  riderWallet: 'riderWallet',
  riderWalletBalance: 'riderWalletBalance',
  riderWalletCount: 'riderWalletCount',

  riderTripPayment: 'riderTripPayment',
  riderTripPaymentCount: 'riderTripPaymentCount',
  riderTripPaymentExport: 'riderTripPaymentExport',
  riderInOutflow: 'riderInOutflow',

  allBusinessAccsExport: 'allBusinessAccsExport',

  //Drivers
  allDrivers: 'allRiders',
  allDriversCount: 'allDriversCount',
  allDriversExport: 'allDriversExport',
  driverDetails: 'driverDetails',
  driverTripPaymentCount: 'driverTripPaymentCount',
  driverTripPayment: 'driverTripPayment',

  //Partners
  partner: 'partner',
  partnerCount: 'partnerCount',
  partnerWalletDetails: 'partnerWalletDetails',

  allPartners: 'allPartners',
  allPartnersCount: 'allPartnersCount',
  allPartnersExport: 'allPartnersExport',
  verifiedPartners: 'verifiedPartners',
  approvedPartners: 'approvedPartners',
  pendingPartners: 'pendingPartners',

  partnerDrivers: 'partnerDrivers',
  partnerVehicles: 'partnerVehicles',
  partnerVehiclesCount: 'partnerVehiclesCount',

  partnerDetails: 'partnerDetails',
  partnerWallet: 'partnerWallet',
  partnerWalletBalance: 'partnerWalletBalance',
  partnerInOutflow: 'partnerInOutflow',

  partnerPayout: 'partnePayout',
  partnerPayoutCount: 'partnePayoutCount',
  partnerWalletCount: 'partnerWalletCount',

  partnersLeaderboard: 'partnersLeaderboard',
  partnerRegistrationStat: 'partnerRegistrationStat',

  //
  oems: 'oems',
  oemVehicle: 'oemVehicle',
  oemsCount: 'oemsCount',
  roles: 'roles',
  rolesCount: 'rolesCount',
  rolesList: 'rolesList',
  staff: 'staff',
  staffCount: 'staffCount',

  //vehicles
  vehicleList: 'vehicleList',
  vehicles: 'vehicles',
  vehiclesCount: 'vehiclesCount',
  vehiclesExport: 'vehiclesExport',

  vehicleWalletBalance: 'vehicleWalletBalance',
  vehicleWalletDetails: 'vehicleWalletDetails',
  vehicleWallet: 'vehicleWallet',
  vehicleWalletCount: 'vehicleWalletCount',
  vehicleInOutflow: 'vehicleInOutflow',

  vehicleDrivers: 'vehicleDrivers',
  vehicleDriversCount: 'vehicleDriversCount',

  //ratings
  ratings: 'ratings',
  ratingsCount: 'ratingsCount',
  ratingsAverage: 'ratingsAverage',

  //revenue
  driverDebtService: 'driverDebtService',
  driverDebtServiceCount: 'driverDebtServiceCount',
  driverDebtServiceExport: 'driverDebtServiceExport',

  //wallet breakDown
  vehicleWalletBreakDown: 'vehicleWalletBreakDown',
  vehicleWalletAmountBreakDown: 'vehicleWalletAmountBreakDown',
  userWalletBreakDown: 'userWalletBreakDown',
  userWalletBreakDownCount: 'userWalletBreakDownCount',
  driverWalletBreakDown: 'driverWalletBreakDown',
  driverWalletAmountBreakDown: 'driverWalletAmountBreakDown',
  riderWalletBreakDown: 'riderWalletBreakDown',
  riderWalletAmountBreakDown: 'riderWalletAmountBreakDown',
  partnerWalletBreakDown: 'partnerWalletBreakDown',
  partnerWalletAmountBreakDown: 'partnerWalletAmountBreakDown',
  walletExport: 'walletExport',
  userWalletStats:'userWalletStats',

  //vmr
  vmr: 'vmr',
  vmrCount: 'vmrCount',
  vmrCount1: 'vmrCount1',
  responseTime: 'responseTime',

  driverPayout: 'driverDebtService',
  driverPayoutBalance: 'driverPayoutBalance',
  driverPayoutCount: 'driverPayoutCount',
  driverPayoutExport: 'driverPayoutExport',
  driverPayoutStats: 'driverPayoutStats',

  //payout and revenue shares
  payoutCount: 'payoutCount',
  payout: 'payout',
  revenue: 'revenue',
  revenueCount: 'revenueCount',
  revenueShares: 'revenueShares',
  stakeholderPayout: 'stakeholderPayout',
  stakeholderPayoutCount: 'stakeholderPayoutCount',
  stakeholdersPayoutExport: 'stakeholdersPayoutExport',
  stakeholderPayoutStats: 'stakeholderPayoutStats',
  allRevStats: 'allRevStats',
  revStats: 'revStats',
  revenueExport: 'revenueExport',

  //schedules
  schedulesCountToday: 'schedulesCountToday',
  schedulesCountAll: 'schedulesCountAll',
  schedules: 'schedules',
  schedulesCount: 'schedulesCount',
  schedulesExport: 'schedulesExport',
  schedulesDetails: 'schedulesDetails',
  schedulesUnassignedAndAssigned: 'schedulesUnassignedAndAssigned',
  schedulesFulfilledAndUnfulfilled: 'schedulesFulfilledAndUnfulfilled',

  //service Payment
  servicePayment: 'servicePayment',
  servicePaymentStats: 'servicePaymentStats',
  servicePaymentCount: 'servicePaymentCount',
  servicePaymentExport: 'servicePaymentExport',

  //promo
  promo: 'promo',
  promoCount: 'promoCount',
  promoStats: 'promoStats',
  beneficiary: 'beneficiary',
  beneficiaryCount: 'beneficiaryCount',

  //referral
  referral: 'referral',
  referralCount: 'referralCount',
  referralStats: 'referralStats',

  //Business
  businessDetails: 'businessDetails',
  employeesCount: 'employeesCount',
  employees: 'employees',

  //activityLog
  activityLog: 'activityLog',
  activityLogCount: 'activityLogCount',
  activityDetailsLog: 'activityDetailsLog',
  activityDetailsLogCount: 'activityDetailsLogCount',

  // emergency
  emergency: 'emergency',
  emergencyCount: 'emergencyCount',
  emergencyContact: 'emergencyContact',
  emergencyContactCount: 'emergencyContactCount',
  emergencyStats: 'emergencyStats',

  //exports
  userExport: 'userExport',
  userWalletExport: 'userWalletExport',
  partnerWalletExport: 'partnerrWalletExport',
  vehicleWalletExport: 'vehiclerWalletExport',
  tripPerformanceExport: 'tripPerformanceExport',
  userDownloads: 'userDownloads',

  //notifications
  notifications: 'notifications',

  //receivable detail
  receiveableDetail: 'receiveableDetail',
  receiveableDetailCount: 'receiveableDetailCount',

  //revenueDetail
  revenueDetailExport: 'revenueDetailExport',

  //reconciliation
  reconciliationCountStatus: 'reconciliaitionCountStatus',
  exportReconDetail: 'exportReconDetail',
};
