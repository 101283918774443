import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { ObjectType } from '../../types/common';
import { formatByDateType } from '../../helpers/dateTimeHelper';
const NewFilter = ({
  toggled,
  handleToggle,
  options,
  toggleComponent,
  width,
  direction,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onApply,
}: ObjectType) => {
  const [shown, setShown] = useState('');

  return (
    <Dropdown
      direction={direction || 'down'}
      className='d-inline'
      isOpen={toggled}
      toggle={handleToggle}
    >
      <DropdownToggle
        caret={!toggleComponent}
        className='btn-sm p-0 text-muted border-white align-items-center d-flex'
        style={{ backgroundColor: 'white' }}
      >
        {!options && startDate && endDate && (
          <div className='me-2'>
            {formatByDateType(startDate, 'daily')}{' '}
            <span className='fw-bolder'>-</span>{' '}
            {formatByDateType(endDate, 'daily')}{' '}
          </div>
        )}
        {toggleComponent}
      </DropdownToggle>

      <DropdownMenu style={{ width: width ? width : 300, fontSize: 14 }}>
        <div className='mb-3'>
          <small className='fw-bold ms-4 '>Show for</small>
        </div>
        {(startDate !== undefined || endDate !== undefined || endDate) && (
          <div className='px-3'>
            <div className='text-muted '>Date Range</div>
            <div className='d-flex mt-3 mb-2'>
              <div className='w-50 me-1'>
                <FormGroup>
                  <Label for='date'>From</Label>
                  <Input
                    id='date'
                    className='py-0'
                    name='date'
                    type='date'
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className='w-50 ms-1'>
                <FormGroup>
                  <Label for='to'>To</Label>
                  <Input
                    id='to'
                    className='py-0'
                    name='to'
                    type='date'
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        )}

        {options &&
          options.map((item: ObjectType, index: number) => (
            <div
              className='py-3 border-bottom px-3 border-1'
              style={{ borderColor: '#e6eef0' }}
              key={index}
            >
              <div
                className='d-flex align-items-center justify-content-between cursor-pointer'
                onClick={() =>
                  shown !== item.title ? setShown(item.title) : setShown('')
                }
              >
                <div className='text-muted'>{item.title}</div>
                <div>
                  {/* <IoIosArrowDown color="#e6eef0" /> */}
                  <IoIosArrowDown color='grey' />
                </div>
              </div>
              {item.options.map((option: ObjectType) => (
                <div
                  className={`mb-3 mt-3 cursor-pointer ${
                    shown !== item.title && 'd-none'
                  }`}
                  key={option.value}
                >
                  <FormGroup check>
                    <Input
                      id={item.name + option.value}
                      name={option.name}
                      type='radio'
                      value={option.value}
                      onChange={(e) => item.onChange(e.target.value)}
                      checked={item.currentValue === option.value}
                    />
                    <Label
                      check
                      className={`${
                        item.currentValue === option.value && 'gradient-text'
                      }`}
                      for={item.name + option.value}
                    >
                      {option.label}
                    </Label>
                  </FormGroup>
                </div>
              ))}
            </div>
          ))}

        <div className='d-flex justify-0content-between my-4 px-1 '>
          <Button
            className='btn-sm gradient-btn ms-3 mt-2 px-3 cursor-pointer'
            onClick={onApply}
          >
            Apply
          </Button>
          {(startDate || endDate) && (
            <Button
              className='btn-sm btn-primary ms-3 mt-2 px-3 cursor-pointer'
              onClick={() => {
                setStartDate('');
                setEndDate('');
              }}
            >
              Clear Dates
            </Button>
          )}

          {/* <Button className="btn-sm ms-3 mt-2 px-3">Clear</Button> */}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NewFilter;
