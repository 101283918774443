import {
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import GradientButton from '../../../../../components/shared/gradidentButton';
import RedButton from '../../../../../components/shared/redButton';

import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import queryKeys from '../../../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../../../contexts/AppPrefrenceContext';
import { useOemApiService } from '../../../../../services/oemApiServices';
import { ObjectType, ReactChangeEventType } from '../../../../../types/common';
import AboutInfo from './vmrProfileCards/AboutInfo';
import Description from './vmrProfileCards/Description';
import DiagnosticInfo from './vmrProfileCards/DiagnosticInfo';
import DriverInfo from './vmrProfileCards/DriverInfo';
import VehicleInfo from './vmrProfileCards/VehicleInfo';
import ServiceRequestLog from './vmrProfileCards/ServiceRequestLog';

type Props = {
  vmrDetails: {
    [key: string]: any;
  };
  setActiveId: Dispatch<SetStateAction<string>>;
  setVehId: Dispatch<SetStateAction<string>>;
};

const VmrProfile = ({ vmrDetails, setActiveId, setVehId }: Props) => {
  useEffect(() => {
    setBreadCrumbData(['VMR', `VMR's log`, `${vmrDetails?.plate_number}`]);
  }, [vmrDetails]);

  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [rejectionModalOpen, setRejectionModalOpen] = useState(false);
  const [editInfoModalOpen, setEditInfoModalOpen] = useState(false);
  const [serviceId, setServiceId] = useState('');
  const [data, setData] = useState<ObjectType | null>(null);
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const [comment, setComment] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const queryClient = useQueryClient();
  const {
    getOemsToast,
    assignServiceToast,
    updateVmrInfoToast,
    updateVmrRequestStatusToast,
  } = useOemApiService();
  const { centerId, serviceType, urgency, description }: ObjectType =
    data || {};

  const handleQrToggle = () => {
    setQrModalOpen((prev) => !prev);
  };

  const handleChange = (e: ReactChangeEventType) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleInfoToggle = () => {
    setEditInfoModalOpen((prev) => !prev);
  };

  const {  data: oems } = useQuery(
    [queryKeys.oems],
    () => getOemsToast({ component: '' }),
    {
      select: (data) => data?.data || [],
    }
  );

  const assignServiceMutation = useMutation(assignServiceToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        queryClient.invalidateQueries(queryKeys.vmr);
        queryClient.invalidateQueries(queryKeys.vmrCount);
      }
      setAssignModalOpen(false);
    },
  });

  const updateInfoMutation = useMutation(updateVmrInfoToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        queryClient.invalidateQueries(queryKeys.vmr);
        queryClient.invalidateQueries(queryKeys.vmrCount);
      }
      setEditInfoModalOpen(false);
    },
  });

  const handleAssignSubmit = (e: any) => {
    e.preventDefault();
    assignServiceMutation.mutate({
      request_id: vmrDetails?.request_id,
      center_id: centerId,
      // service_type: serviceType,
      oem_comment: comment,
    });
  };

  const handleEditSubmit = (e: any) => {
    e.preventDefault();
    updateInfoMutation.mutate({
      request_id: vmrDetails?.request_id,
      description: description,
      urgency: urgency,
    });
  };

  const handleRejectionSubmit = (e: any) => {
    e.preventDefault();
    rejectRequestMutation.mutate({
      request_id: vmrDetails?._id,
      status: 'rejected',
      reason: rejectionReason,
    });
  };

  const rejectRequestMutation = useMutation(updateVmrRequestStatusToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        queryClient.invalidateQueries(queryKeys.vmr);
        queryClient.invalidateQueries(queryKeys.vmrCount);
        // setActiveTab && setActiveTab("Partner's Log");
      }
      setRejectionModalOpen(false);
    },
  });

  function downloadImage() {
    const imageUrl = vmrDetails?.qr_code;
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = 'qr-code.png';
    downloadLink.click();
  }

  const servType = [
    { label: 'Maintenance', value: 'maintenance' },
    { label: 'Repair', value: 'repair' },
    { label: 'Defect', value: 'defect' },
  ];

  const urgencies = [
    { label: 'Low', value: 'low' },
    { label: 'Medium', value: 'medium' },
    { label: 'High', value: 'high' },
  ];

  const diagnosStatus =
    vmrDetails.status === 'ongoing' ||
    vmrDetails.status === 'completed' ||
    vmrDetails.status === 'awaiting-approval' ||
    vmrDetails.status === 'approved' ||
    vmrDetails.status === 'repair_time_set';

    if (serviceId) {
      return (
        <div>
          <ServiceRequestLog serviceId={serviceId} setServiceId={setServiceId} vmrDetails={vmrDetails} />
        </div>
      );
    }

  return (
    <>
      <div className='px-4'>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <div className='d-flex align-items-center  '>
            <BsArrowLeft
              size={30}
              className='cursor-pointer'
              onClick={() => {
                setActiveId('');
                setVehId('');
              }}
            />
            <div className='fw-bolder ms-3 fs-5'>
              {vmrDetails?.plate_number}
            </div>
          </div>

          {vmrDetails.status === 'pending' && (
            <div className='d-flex align-items-center'>
              <div style={{ width: 150 }}>
                <RedButton
                  className='py-2'
                  text='Reject Request'
                  onClick={() => setRejectionModalOpen(true)}
                />
              </div>
              <div className='ms-3' style={{ width: 150 }}>
                <GradientButton
                  text='Assign Request'
                  className='py-2'
                  onClick={() => {
                    setAssignModalOpen(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <div
            className='mt-4 p-2 rounded border-none'
            style={{
              backgroundColor: 'rgba(4, 167, 167, 0.1)',
              // backgroundImage: 'url("/svgs/stroke.svg")',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          >
            <div className='d-flex align-items-center justify-content-between px-1 '>
              <div className='d-flex align-items-center'>
                <img src='/images/car.png' alt='car' height={90} />

                <div className='ms-3'>
                  <div className='uppercase fw-bold fs-4'>
                    {vmrDetails?.plate_number}
                  </div>
                  <div className='text-grey'>
                    {vmrDetails?.lagosride_vehicle_data?.car_number}
                  </div>
                </div>
              </div>
              <div className='ms-3 text-center cursor-pointer'>
                <img
                  src={vmrDetails?.lagosride_vehicle_data?.qr_code}
                  alt='qr-code'
                  height={100}
                  onClick={handleQrToggle}
                />
                <div
                  style={{ color: '#04a7a7', textDecoration: 'underline' }}
                  onClick={downloadImage}
                >
                  Download
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-3'>
          <AboutInfo vmrDetails={vmrDetails} />
        </div>

        <div className='mt-3'>
          <Description
            vmrDetails={vmrDetails}
            handleIdToggle={handleInfoToggle}
          />
        </div>

        <div className='mt-3'>
          <DriverInfo vmrDetails={vmrDetails} />
        </div>

        <div className='mt-3'>
          <VehicleInfo vmrDetails={vmrDetails} />
        </div>

        {vmrDetails.status === 'rejected' && (
          <div className='mt-3'>
            <Card body className=' p-2'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1'>
                  <div className='flex-grow-1'>
                    <div className=''>
                      <div className='text-danger fw-semibold'>
                        Reason for Rejection
                      </div>
                    </div>
                    <Row className='w-100 mt-2'>
                      <Col className='mt-2' md={6}>
                        <div className='mt-1 fw-semibold'>
                          {vmrDetails?.reason}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}

        {diagnosStatus && (
          <div className='mt-3'>
            <DiagnosticInfo vmrDetails={vmrDetails} />
          </div>
        )}

        <div
          className='fs-6 fw-semibold mt-3 cursor-pointer'
          style={{ textDecoration: 'underline', lineHeight: '1.5' }}
          onClick={() => {
            setVehId(vmrDetails.vehicle_id);
            setServiceId(vmrDetails.vehicle_id);
          }}
        >
          See Service Request History
        </div>

        {/* qr code modal */}
        <Modal
          size='md'
          isOpen={qrModalOpen}
          className='modal-dialog-centered'
          backdrop='static'
          toggle={handleQrToggle}
          style={{ width: 400 }}
        >
          <ModalBody className='p-4 text-center'>
            <div>
              <div className='d-flex justify-content-between'>
                <div className='fw-bold fs-5'>
                  Vehicle {vmrDetails?.car_number_plate} QR Code
                </div>

                <AiOutlineClose size={20} onClick={handleQrToggle} />
              </div>
              <div>
                <img
                  src={vmrDetails?.lagosride_vehicle_data?.qr_code}
                  alt='qr-code'
                  height={350}
                />
              </div>
              <div className='mt-3 d-flex justify-content-end'>
                <div style={{ width: 150 }}>
                  <RedButton
                    text='Close'
                    className='py-2'
                    onClick={handleQrToggle}
                  />
                </div>
                <div className='ms-3' style={{ width: 150 }}>
                  <GradientButton
                    text='Download'
                    className='py-2'
                    onClick={() => {
                      downloadImage();
                      handleQrToggle();
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* rejection modal */}
        <Modal
          size='md'
          isOpen={rejectionModalOpen}
          backdrop='static'
          toggle={() => setRejectionModalOpen(false)}
          style={{ width: 550 }}
        >
          <ModalBody className='p-4'>
            <div className='d-flex justify-content-between'>
              <div className='fw-bold'>
                Reject Request{' '}
                {vmrDetails?.lagosride_vehicle_data?.car_number_plate} ?
              </div>

              <AiOutlineClose
                size={20}
                onClick={() => setRejectionModalOpen(false)}
              />
            </div>
            <div className='mt-1 text-muted'>
              <small>
                This action would reject{' '}
                {vmrDetails?.lagosride_vehicle_data?.car_number_plate} and is
                irreversible.
              </small>
            </div>
            <form onSubmit={handleRejectionSubmit}>
              <FormGroup className='bg-white mt-4'>
                <Input
                  id='deletionReason'
                  name='deletionReason'
                  placeholder='Reason'
                  type='textarea'
                  className='bg-transparent'
                  rows={5}
                  value={rejectionReason}
                  onChange={(e: ReactChangeEventType) =>
                    setRejectionReason(e.target.value)
                  }
                />
              </FormGroup>
              <div className='mt-5 d-flex justify-content-end'>
                <div style={{ width: 150 }}>
                  <RedButton
                    className='py-2'
                    text='Cancel'
                    cancelIcon
                    onClick={() => setRejectionModalOpen(false)}
                  />
                </div>
                <div className='ms-3' style={{ width: 150 }}>
                  <GradientButton
                    text='Reject'
                    className='py-2'
                    type='submit'
                  />
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>

        {/* request assign modal */}
        <Modal
          size='md'
          isOpen={assignModalOpen}
          backdrop='static'
          toggle={() => setAssignModalOpen(false)}
          style={{ width: 550 }}
        >
          <ModalBody className='p-4'>
            <div className='d-flex justify-content-between'>
              <div className='fw-bold'>Assign Request</div>
              <AiOutlineClose
                size={20}
                onClick={() => setAssignModalOpen(false)}
              />
            </div>

            <form onSubmit={handleAssignSubmit}>
              <FormGroup className='bg-white mt-3'>
                <Label for='serviceType'>Select Service Center</Label>
                <Input
                  id='centerId'
                  name='centerId'
                  placeholder=''
                  type='select'
                  className='bg-transparent'
                  value={centerId}
                  onChange={handleChange}
                >
                  {' '}
                  <option value='' hidden>
                    --Select Service Center --
                  </option>
                  {oems?.map((item: ObjectType) => (
                    <option value={item?.auth_id} key={item?.auth_id}>
                      {item?.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className='bg-white mt-3'>
                <Label for='serviceType'>Service Type</Label>
                <Input
                  id='serviceType'
                  name='serviceType'
                  placeholder=''
                  type='select'
                  className='bg-transparent'
                  value={serviceType}
                  onChange={handleChange}
                >
                  {' '}
                  <option value='' hidden>
                    --Select Service Type --
                  </option>
                  {servType.map((item: ObjectType) => (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup className='bg-white mt-4'>
                <Input
                  id='comment'
                  name='comment'
                  placeholder='Comment'
                  type='textarea'
                  className='bg-transparent'
                  rows={5}
                  value={comment}
                  onChange={(e: ReactChangeEventType) =>
                    setComment(e.target.value)
                  }
                />
              </FormGroup>
              <div style={{ width: 120, marginTop: 40 }}>
                <GradientButton className='py-2' text='Submit' />
              </div>
            </form>
          </ModalBody>
        </Modal>

        {/* edit modal */}
        <Modal
          size='md'
          isOpen={editInfoModalOpen}
          toggle={() => setEditInfoModalOpen(false)}
          className='modal-dialog-centered'
          backdrop='static'
          style={{ width: 550 }}
        >
          <ModalBody className='p-4'>
            <div className='d-flex justify-content-between'>
              <div className='fw-bold'>Service Request Information</div>
              <AiOutlineClose
                size={20}
                onClick={() => setEditInfoModalOpen(false)}
              />
            </div>

            <form onSubmit={handleEditSubmit}>
              <FormGroup className='bg-white mt-3'>
                <Label for='urgency'>Urgency</Label>
                <Input
                  id='urgency'
                  name='urgency'
                  placeholder=''
                  type='select'
                  className='bg-transparent'
                  value={urgency}
                  onChange={handleChange}
                >
                  {' '}
                  <option value='' hidden>
                    Low
                  </option>
                  {urgencies?.map((item: ObjectType) => (
                    <option value={item?.value} key={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup className='bg-white mt-4'>
                <Label for='description'>Description</Label>
                <Input
                  id='description'
                  name='description'
                  placeholder=''
                  type='textarea'
                  className='bg-transparent'
                  rows={5}
                  value={description}
                  onChange={handleChange}
                />
              </FormGroup>
              <div className='mt-5 d-flex justify-content-end'>
                <div style={{ width: 150 }}>
                  <RedButton
                    className='py-2'
                    text='Cancel'
                    cancelIcon
                    onClick={() => setEditInfoModalOpen(false)}
                  />
                </div>
                <div className='ms-3' style={{ width: 150 }}>
                  <GradientButton
                    text='Update'
                    className='py-2'
                    type='submit'
                  />
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};
export default VmrProfile;
