import axios from 'axios';
import { api } from '../constants/urls';
import { useToastifyPromises } from '../helpers/toastifyHelper';

// import {AuthActionSuccess, LogoutAction} from "../actions/AuthActions";

export const useOemApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //   1.get oems
  const getOemsReq = ({
    page = 1,
    item_per_page = 20,
    q = '',
    user_type = '',
    component = '',
  }: any) => {
    return axios.get(
      api.oems +
        `/admin/users/?page=${
          q ? '' : page
        }&item_per_page=${item_per_page}&q=${q}&component=${component}&user_type=${user_type}`
    );
  };
  const getOemsToast = (body: any) =>
    toastifyPromises.get({ asyncFunction: getOemsReq(body) });

  //2. create Oems
  const createOemsReq = (body: any) =>
    axios.post(api.oems + `/admin/users`, body);
  const createOemsToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: createOemsReq(body),
      pendingMsg: 'Creating Oem',
      SuccessMsg: 'Oem Creation Successful',
    });

  //3. update Oems
  const updateOemsReq = (body: any) =>
    axios.put(api.oems + `/admin/users/${body?.auth_id}`, body);
  const updateOemToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: updateOemsReq(body),
      pendingMsg: 'Updating Oem',
      SuccessMsg: 'Oem Update Successful',
    });

  //4. delete Oems
  const deleteOemsReq = (body: any) => {
    if (!Array.isArray(body?.auth_id)) {
      return axios.delete(api.oems + `/admin/users/${body?.auth_id}`);
    } else {
      let hasError = false;
      const requests = body?.auth_id?.map((id: string) =>
        axios.delete(api.oems + `/admin/users/${id}`)
      );
      return Promise.all(requests)
        .then((responses) => {
          responses.forEach((response) => {
            if (response?.data?.status === 'error') hasError = true;
          });

          return hasError;
        })
        .then((isError) => {
          return isError
            ? {
                data: {
                  status: 'error',
                  msg: 'At least one request failed',
                },
              }
            : {
                data: {
                  status: 'ok',
                },
              };
        });
    }
  };
  const deleteOemToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: deleteOemsReq(body),
      pendingMsg: 'Deleting Oem',
      SuccessMsg: 'Oem(s) Deleted Successfully',
    });

  //5 get vmr requests
  const getVmrReq = ({
    page = 1,
    item_per_page = 50,
    q = '',
    status = '',
    service_type = '',
    start_date = '',
    end_date = '',
    urgency = '',
    driver_id = '',
    vehicle_id = '',
    oem_vehicle_id = '',
    service_center_id = '',
    maint_status = '',
    component = '',
  }: any) => {
    return axios.get(
      api.oems +
        `/admin/service-requests/?page=${
          q ? '' : page
        }&item_per_page=${item_per_page}&q=${q}&start_date=${start_date}&end_date=${end_date}&oem_vehicle_id=${oem_vehicle_id}&driver_id=${driver_id}&maint_status=${maint_status}&service_center_id=${service_center_id}&status=${status}&service_type=${service_type}&vehicle_id=${vehicle_id}&urgency=${urgency}&component=${component}`
    );
  };
  const getVmrRequestToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getVmrReq(body) });

  //6.get vehicle details
  const getVmrDetailsReq = ({ request_id }: any) => {
    return axios.get(api.oems + `/admin/service-requests/${request_id}`);
  };
  const getVmrDetailsToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getVmrDetailsReq(body) });

  //7 assign servicce request
  const assignServiceReq = (body: any) =>
    axios.post(
      api.oems +
        `/admin/assign-service
    `,
      body
    );
  const assignServiceToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: assignServiceReq(body),
      pendingMsg: 'Assigning service request',
      SuccessMsg: 'Service request assigned successfully',
    });

  //update vmr info
  const updateVmrInfoReq = (body: any) =>
    axios.put(api.oems + `/admin/service-requests/${body?.request_id}`, body);
  const updateVmrInfoToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: updateVmrInfoReq(body),
      pendingMsg: 'Updating Info',
      SuccessMsg: 'Info Update Successful',
    });

  ////  get response time
  const getResponseTimeReq = ({
    distribution_type = '',
    start_date = '',
    end_date = '',
    group_stage = '',
  }: any) => {
    return axios.get(
      api.oems +
        `/admin/distributions/?distribution_type=${distribution_type}&start_date=${start_date}&end_date=${end_date}&group_stage=${group_stage}`
    );
  };
  const getResponseTimeToast = (body: any) =>
    toastifyPromises.get({ asyncFunction: getResponseTimeReq(body) });

  //inititate maintenace
  const initiateMaintenanceReq = (body: any) =>
    axios.post(api.oems + `/admin/initiate-maintenance`, body);
  const initiateMaintenanceToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: initiateMaintenanceReq(body),
      pendingMsg: 'Initiating Maintenance...',
      SuccessMsg: 'Maintenance initiated successfully',
    });

  const updateVmrRequestStatusReq = (body: any) =>
    axios.put(api.oems + `/admin/status/${body?.request_id}`, body);
  const updateVmrRequestStatusToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: updateVmrRequestStatusReq(body),
      pendingMsg: 'Updating service request status',
      SuccessMsg: 'Request status update Successful',
    });

  const getOemsVehicleReq = ({ oem_id = '' }: any) => {
    return axios.get(api.oems + `/vehicles?oem_id=${oem_id}`);
  };
  const getOemsVehicleToast = (body: any) =>
    toastifyPromises.get({ asyncFunction: getOemsVehicleReq(body) });

  return {
    getOemsToast,
    initiateMaintenanceToast,
    createOemsToast,
    assignServiceToast,
    updateVmrInfoToast,
    updateVmrRequestStatusToast,
    updateOemToast,
    deleteOemToast,
    getOemsVehicleToast,
    getVmrRequestToast,
    getResponseTimeToast,
    getVmrDetailsToast,
  };
};
