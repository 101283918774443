import 'chart.js/auto';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { Card, CardTitle } from 'reactstrap';
import FilterOptions from '../../../../components/filter/Filter';
import YearsCalender from '../../../../components/filter/yearsCalender';
import ExportComponent from '../../../../components/shared/exportComponent';
import queryKeys from '../../../../constants/queryKeys';
import { getDataFromEmergencyDataType } from '../../../../helpers/chartsDataHelper';
import {
  getFirstDayOfTheYear,
  getLastDayOfTheYear,
} from '../../../../helpers/dateTimeHelper';
import { useEmergencyApiService } from '../../../../services/emergencyApiServices';
import { ReactChangeEventType } from '../../../../types/common';

const EmergencyTrend = () => {
  const [startDate, setStartDate] = useState(getFirstDayOfTheYear());
  const [dateType, setDateType] = useState('monthly');
  const [endDate, setEndDate] = useState(getLastDayOfTheYear());
  const [toggled, setToggled] = useState(false);
  const { getEmergencyToast } = useEmergencyApiService();
  const handleToggle = () => {
    setToggled((prevStatus) => !prevStatus);
  };

  const handleDateTypeChange = (e: ReactChangeEventType) => {
    setDateType(e.target.value);
  };

  const { data: emergencyStats } = useQuery(
    [queryKeys.emergencyStats, startDate],
    () =>
      getEmergencyToast({
        component: 'count-monthly',
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data || [],
      keepPreviousData: true,
    }
  );

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: 'white',
        },
        beginAtZero: true,
      },
      y: {
        grid: {
          display: false,
          borderColor: 'white',
        },
        ticks: {
          min: 0,
          max: 15,
          stepSize: 5,
        },
        beginAtZero: true,
        // steps: 4
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
  };

  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Resolved',
        data: getDataFromEmergencyDataType({
          type: 'resolved',
          dateType: 'monthly',
          data: emergencyStats,
        }),
        // fill: true,
        // backgroundColor: 'rgba(75,192,192,0.2)',
        borderWidth: 1,
        borderColor: '#05b4b0',
        pointRadius: 2,
        // hoverBorderJoinStyle: "miter",
      },
      {
        label: 'Unresolved',
        data: getDataFromEmergencyDataType({
          dateType: 'monthly',
          type: 'unresolved',
          data: emergencyStats,
        }),
        // fill: true,
        // backgroundColor: 'rgba(75,192,192,0.2)',
        borderWidth: 1,
        borderColor: '#FF3739',
        pointRadius: 2,
        // hoverBorderJoinStyle: "miter",
      },
    ],
  };

  const dateTypeOption = [
    {
      value: 'monthly',
      label: 'Monthly',
      subOptionComponent: (
        <YearsCalender
          handleToggle={handleToggle}
          month={startDate}
          setYear={(year) => {
            setStartDate(year);
            setEndDate(year);
          }}
        />
      ),
    },
  ];

  return (
    <Card body>
      <CardTitle className=' justify-content-between'>
        <div className='justify-content-between d-flex w-100'>
          <div>
            <div className='fw-bold'>Resolved and Unresolved - Monthly </div>
            <div className='mt-2'>
              <FilterOptions
                toggled={toggled}
                handleToggle={handleToggle}
                options={dateTypeOption}
                selectedOption={dateType}
                optionChange={handleDateTypeChange}
                name='promo-usage-chart'
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
          <ExportComponent />
        </div>
      </CardTitle>

      <div>
        <Line data={data} options={options} style={{ maxHeight: '250px' }} />
      </div>
    </Card>
  );
};

export default EmergencyTrend;
