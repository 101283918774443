import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Modal, ModalBody, Table } from 'reactstrap';
import EmptyPage from '../../../components/EmptyPage';
import NewFilter from '../../../components/filter/NewFilter';
import ExportComponent from '../../../components/shared/exportComponent';
import FilterComponent from '../../../components/shared/filterComponent';
import GradientButton from '../../../components/shared/gradidentButton';
import WaitingLoader from '../../../components/shared/waitingLoader';
import SeearchComponent from '../../../components/tablesComponents/searchComponent';
import queryKeys from '../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';
import { usePaginationHelper } from '../../../helpers/paginationHelper';
import { useQueryStringHelper } from '../../../helpers/queryStringHelper';
import { useEmergencyApiService } from '../../../services/emergencyApiServices';
import { ObjectType, ReactChangeEventType } from '../../../types/common';
import EmergencyModalBody from './components/emergencyModalBody';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  activeTab: string;
};
const EmailLog = (props: Props) => {
  const { activeTab, setActiveTab } = props;
  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState<ObjectType | []>([]);
  const [codeType, setCodeType] = useState('');
  const { getPageNoFromQueryString, getQueryStringValue } =
    useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const [keyword, setKeyword] = useState('');
  const queryClient = useQueryClient();
  const { getEmergencyContactToast, deleteEmergencyContactToast } =
    useEmergencyApiService();
  const { setBreadCrumbData, deleteModalConfig } =
    useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData([`${activeTab}`]);
  }, [activeTab]);

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
    codeType,
  });

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };
  const handleModalToggle = () => {
    setModalOpen((prev) => !prev);
  };

  const { isLoading, data: emergency } = useQuery(
    [queryKeys.emergencyContact, filterState],
    () =>
      getEmergencyContactToast({
        type: 'email',
      }),
    {
      select: (data) => data?.data || [],
      // enabled: false,
      keepPreviousData: true,
    }
  );
  // const { data: emergencyCount } = useQuery(
  //   [queryKeys.emergencyContactCount, filterState],
  //   () =>
  //     getEmergencyContactToast({
  //       component: 'count',
  //       type: 'email',
  //     }),
  //   {
  //     select: (data) => data?.data?.total || 0,
  //     // enabled: false,
  //     keepPreviousData: true,
  //   }
  // );

  const handleChange = (e: ReactChangeEventType) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const filterOptions: [] = [];

  const deleteEmergencyMutation = useMutation(deleteEmergencyContactToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        setModalOpen(false);
        queryClient.invalidateQueries(queryKeys.emergencyContact);
        // queryClient.invalidateQueries(queryKeys.emergencyContactCount);
      }
    },
    // onError: (err) => {},
  });

  const onApply = () => {
    setFilterState({ startDate, endDate, codeType });
    handleToggle();
  };

  if (isLoading && !emergency) {
    return <WaitingLoader />;
  }
  return (
    <>
      <div className='d-flex justify-content-between text-nowrap flex-wrap'>
        <div className='d-flex align-items-center'>
          <div className=''>
            <div className='d-flex align-items-center  '>
              <div className='fw-bolder fs-5 capitalize'>Email SOS</div>
            </div>
          </div>
          <div className='ms-4'>
            {<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='me-3'>
            {emergency?.length > 0 && <ExportComponent />}
          </div>

          <div className='me-3'>
            <NewFilter
              direction={'start'}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              options={filterOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />
          </div>
          <div style={{ width: 120 }}>
            <GradientButton
              text='Add New Email'
              className='p-2'
              onClick={() => {
                setModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>

      <div className='mt-2'>
        {emergency?.length === 0 && <EmptyPage text='No Email SOS' />}
        {emergency?.length > 0 && (
          <Table responsive style={{ width: '34%' }}>
            <thead className=''>
              <tr>
                <th className=''>Email</th>
                <th className=''>Action</th>
              </tr>
            </thead>

            <tbody>
              {emergency?.map((item: any, index: number) => (
                <Fragment key={item?.contact}>
                  <tr className='cursor-pointer '>
                    <td>{item?.contact || 'N|A'}</td>
                    <td>
                      <span
                        className='cursor-pointer'
                        onClick={() =>
                          deleteModalConfig({
                            firstText: `Delete ${item.contact}`,
                            secondText: `This action would remove ${item.contact} from the portal and is irreversible.`,
                            callback: () =>
                              deleteEmergencyMutation.mutate({
                                contact: item.contact,
                              }),
                          })
                        }
                      >
                        <img src='/svgs/delete.svg' alt='delete svg' />
                      </span>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        )}
        {/* {emergency?.length > 0 && (
          <div className='d-flex justify-content-between align-items-center mt-4 flex-wrap'>
            <div className='mt-2'>
              <TableDataCounter
                item={emergency}
                currentPage={currentPage}
                totalCount={emergencyCount}
              />
            </div>
            <div className='mt-2'>
              <PaginationComponent
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={emergencyCount}
                onChange={onPaginate}
              />
            </div>
          </div>
        )} */}
      </div>
      <Modal
        size='md'
        centered
        isOpen={modalOpen}
        toggle={handleModalToggle}
        backdrop='static'
        // style={{ width: 400 }}
      >
        <ModalBody className='p-4'>
          <div className='d-flex justify-content-between'>
            <div className='fw-bold'>Add New Email</div>
            <AiOutlineClose size={20} onClick={handleModalToggle} />
          </div>
          <EmergencyModalBody
            handleChange={handleChange}
            handleToggle={handleModalToggle}
            type='email'
            data={data}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default EmailLog;
