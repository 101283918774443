import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import GradientButton from '../../../../components/shared/gradidentButton';
import queryKeys from '../../../../constants/queryKeys';
import { useUsersApiService } from '../../../../services/usersService';
import { ObjectType, ReactSubmitEventType } from '../../../../types/common';

type Props = {
  handleToggle?: VoidFunction;
  handleModalToggle?: VoidFunction;
  handleChange: (e: any) => void;
  editingId?: string;
  data?: ObjectType;
  promoDetails: {
    [key: string]: any;
  };
};

const PromoModalBody = ({
  handleToggle,
  handleChange,
  promoDetails,
  data,
  editingId,
}: Props) => {
  const [edit, setEdit] = useState(false);

  const {
    limit,
    discount,
    codeOwner,
    customCode,
    rideNum,
    discountType,
    description,
    startDate,
    status,
    expiryDate,
    codeType,
  }: ObjectType = data || {};

  const initialCodeType =
    codeType && codeType === 'custom' ? 'Custom Promo' : 'Generic Promo';
  const [activePromo, setActivePromo] = useState(initialCodeType);
  const { createPromoToast, updatePromoToast } = useUsersApiService();
  const queryClient = useQueryClient();

  const createPromoMutation = useMutation(createPromoToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        handleToggle && handleToggle();
        queryClient.invalidateQueries(queryKeys.promo);
        queryClient.invalidateQueries(queryKeys.promoCount);
      }
    },
    // onError: (err) => {},
  });

  const updatePromoMutation = useMutation(updatePromoToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        handleToggle && handleToggle();
        queryClient.invalidateQueries(queryKeys.promo);
        queryClient.invalidateQueries(queryKeys.promoCount);
      }
    },
    // onError: (err) => {},
  });

  const handleSubmit = (e: ReactSubmitEventType) => {
    e.preventDefault();
    editingId
      ? updatePromoMutation.mutate({
          promo_code_id: promoDetails?.promo_id,
          promo_code_owner: codeOwner,
          custom_code: customCode,
          num_of_rides: rideNum,
          users_limit: limit,
          discount_value: discount,
          discount_type: discountType,
          status: status,
          start_date: startDate,
          expiry_date: expiryDate,
          description: description,
          code_type: codeType,
        })
      : createPromoMutation.mutate({
          promo_code_owner: codeOwner,
          custom_code: customCode,
          num_of_rides: rideNum,
          users_limit: limit,
          discount_value: discount,
          discount_type: discountType,
          start_date: startDate,
          expiry_date: expiryDate,
          description: description,
          code_type: activePromo === 'Generic Promo' ? 'generic' : 'custom',
        });
  };

  const discountList = [
    { label: 'Percentage', value: 'percentage' },
    { label: 'Amount', value: 'amount' },
  ];
  const statusList = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 2 },
  ];

  useEffect(() => {
    if (editingId) {
      setEdit(true);
    }
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          className='rounded px-4 py-3 d-flex align-items-center mt-3'
          style={{ backgroundColor: '#EFFCF9' }}
        >
          {['Generic Promo', 'Custom Promo'].map((item) => (
            <div
              className={`me-4 cursor-pointer ${
                item === activePromo
                  ? 'gradient-btn text-white py-2 px-3 rounded'
                  : 'white-btn py-2 px-3 rounded border shadow-sm'
              }`}
              key={item}
              onClick={() => setActivePromo(item)}
            >
              {item}
            </div>
          ))}
        </div>

        <Row className='mt-3'>
          {activePromo !== 'Generic Promo' && (
            <>
              <Col md={6}>
                <FormGroup className='bg-white'>
                  <Label for='codeOwner'>Promo Code Owner</Label>
                  <Input
                    id='codeOwner'
                    name='codeOwner'
                    placeholder=''
                    type='text'
                    className='bg-transparent'
                    value={codeOwner}
                    disabled={edit}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className='bg-white'>
                  <Label for='customCode'>Custom Code</Label>
                  <Input
                    id='customCode'
                    name='customCode'
                    placeholder=''
                    type='text'
                    className='bg-transparent'
                    value={customCode}
                    disabled={edit}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </>
          )}
          {!editingId && (
            <Col md={6}>
              <FormGroup className='bg-white '>
                <Label for='startDate'>Start Date</Label>
                <Input
                  id='startDate'
                  name='startDate'
                  placeholder='start Date'
                  type='date'
                  className='bg-transparent'
                  value={startDate}
                  // min={getTodayDate()}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          )}
          <Col md={6}>
            <FormGroup className='bg-white'>
              <Label for='limit'>User Limit</Label>
              <Input
                id='limit'
                name='limit'
                placeholder=''
                type='text'
                className='bg-transparent'
                value={limit}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className='bg-white'>
              <Label for='expiryDate'>End Date</Label>
              <Input
                id='expiryDate'
                name='expiryDate'
                placeholder='End Date'
                type='date'
                className='bg-transparent'
                value={expiryDate}
                // min={getTodayDate()}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className='bg-white'>
              <Label for='discountType'>Discount Type</Label>
              <Input
                id='discountType'
                name='discountType'
                placeholder='Enter discountType'
                type='select'
                className='bg-transparent'
                value={discountType}
                onChange={handleChange}
              >
                <option value='' hidden>
                  --Select Discount Type --
                </option>
                {discountList.map((item) => (
                  <option value={item.value.toLowerCase()} key={item.value}>
                    {item.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className='bg-white'>
              <Label for='discount'>
                {discountType === 'percentage'
                  ? 'Discount %'
                  : 'Discount Value'}
              </Label>
              <Input
                id='discount'
                name='discount'
                placeholder=''
                type='text'
                className='bg-transparent'
                value={discount}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className='bg-white'>
              <Label for='rideNum'>Number of Rides</Label>
              <Input
                id='rideNum'
                name='rideNum'
                placeholder=''
                type='text'
                className='bg-transparent'
                value={rideNum}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          {editingId && (
            <Col md={6}>
              <FormGroup className='bg-white'>
                <Label for='status'>Status</Label>
                <Input
                  id='status'
                  name='status'
                  placeholder='Enter status'
                  type='select'
                  className='bg-transparent'
                  value={status}
                  onChange={handleChange}
                >
                  <option value='' hidden>
                    --Select Status --
                  </option>
                  {statusList.map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          )}
        </Row>

        <FormGroup className='bg-white mt-3'>
          <Label for='discount'>Description</Label>

          <Input
            id='description'
            name='description'
            placeholder='Description'
            type='textarea'
            className='bg-transparent'
            rows={5}
            value={description}
            onChange={handleChange}
          />
        </FormGroup>
        <div style={{ width: 120, marginTop: 40 }}>
          <GradientButton
            className='py-2'
            text={editingId ? 'Update Promo' : 'Create Promo'}
          />
        </div>
      </form>
    </div>
  );
};

export default PromoModalBody;
