import axios from "axios";
import { api } from "../constants/urls";
import { useToastifyPromises } from "../helpers/toastifyHelper";

// import {AuthActionSuccess, LogoutAction} from "../actions/AuthActions";

export const useUsersApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //   1.get users stats
  const getUsersStatsReq = ({ user_type = "", date_type = "", start_date = "", end_date = "", component = "", request_type = "" }: any) => {
    return axios.get(api.users + `/admin/user-stats?user_type=${user_type}&date_type=${date_type}&start_date=${start_date}&end_date=${end_date}&component=${component}&request_type=${request_type}`);
  };
  const getUserStatsToast = (body?: any) => toastifyPromises.get({ asyncFunction: getUsersStatsReq(body) });

  //   2.update user details
  const addUserReq = (body: any) => axios.post(api.users + `/admin/users`, body);
  const addUserToast = (body?: any) =>
    toastifyPromises.post({
      asyncFunction: addUserReq(body),
      pendingMsg: `Creating ${body?.user_type}`,
      SuccessMsg: `Created successfully`,
    });

  //   3.update user details
  const updateUserReq = (body: any) => axios.put(api.users + `/admin/users/${body?.auth_id}`, body);
  const updateUserToast = (body?: any) =>
    toastifyPromises.put({
      asyncFunction: updateUserReq(body),
      pendingMsg: "updating user",
      SuccessMsg: "User updated successfully",
    });

  // 4. Delete User
  const deleteUserReq = ({ auth_id = "", reason = "", user_type = "" }: any) => axios.delete(api.users + `/admin/users/${auth_id}?reason=${reason}&user_type=${user_type}`);
  const deleteUserToast = (body?: any) =>
    toastifyPromises.delete({
      asyncFunction: deleteUserReq(body),
      pendingMsg: "Deleting Rider",
      SuccessMsg: "Rider deleted successfully",
    });

  //   5.update user status
  const updateUserStatusReq = (body: any) => axios.post(api.users + `/admin/account-status`, body);
  const updateUserStatusToast = (body?: any) =>
    toastifyPromises.post({
      asyncFunction: updateUserStatusReq(body),
      pendingMsg: "Updating Account",
      SuccessMsg: "Account updated successfully",
    });

  //   6. Update ID status
  const idStatusReq = (body: any) => axios.post(api.users + `/admin/id-verification`, body);
  const idStatusToast = (body?: any) =>
    toastifyPromises.post({
      asyncFunction: idStatusReq(body),
      pendingMsg: `Updating ID status`,
      SuccessMsg: `Updated successfully`,
    });

  const getUsersReq = ({ page = 1, item_per_page = 50, q = "", account_status = "", kyc_status = "", userType = "", start_date = "", end_date = "", component = "" }: any) => {
    return axios.get(
      api.users + `/admin/users?user_type=${userType}&page=${q ? "" : page}&item_per_page=${item_per_page}&q=${q}&kyc_status=${kyc_status}&account_status=${account_status}&start_date=${start_date}&end_date=${end_date}&component=${component}`
    );
  };
  const getUsersToast = (body?: any) => toastifyPromises.get({ asyncFunction: getUsersReq(body) });

  const getUserReq = ({ auth_id }: any) => {
    return axios.get(api.users + `/admin/users/${auth_id}`);
  };
  const getUserToast = (auth_id?: any) => toastifyPromises.get({ asyncFunction: getUserReq(auth_id) });

  //Promo
  //Promo
  //Promo
  //Promo
  //Promo
  //getPromoCodes
  const getPromoCodesReq = ({ page = 1, item_per_page = 50, q = "", code_type = "", start_date = "", end_date = "", component = "" }: any) => {
    return axios.get(api.users + `/admin/promo-codes?&page=${q ? "" : page}&item_per_page=${item_per_page}&q=${q}&code_type=${code_type}&start_date=${start_date}&end_date=${end_date}&component=${component}`);
  };
  const getPromoCodesToast = (body?: any) => toastifyPromises.get({ asyncFunction: getPromoCodesReq(body) });

  //getPromoDetails
  const getPromoDetailsReq = ({ promo_id = "", component = "" }: any) => {
    return axios.get(api.users + `/admin/promo-codes/${promo_id}?component=${component}`);
  };
  const getPromoDetailsToast = (body?: any) => toastifyPromises.get({ asyncFunction: getPromoDetailsReq(body) });

  //createPromo
  const createPromoReq = (body: any) => axios.post(api.users + `/admin/promo-codes`, body);
  const createPromoToast = (body?: any) =>
    toastifyPromises.post({
      asyncFunction: createPromoReq(body),
      pendingMsg: `Creating Promo`,
      SuccessMsg: `Created promo successfully`,
    });

  //   3.update promo details
  const updatePromoReq = (body: any) => axios.put(api.users + `/admin/promo-codes/${body?.promo_code_id}`, body);
  const updatePromoToast = (body?: any) =>
    toastifyPromises.put({
      asyncFunction: updatePromoReq(body),
      pendingMsg: "updating promo",
      SuccessMsg: "Promo updated successfully",
    });

  // 4. Delete Promo
  const deletePromoReq = ({ promo_code_id }: any) => axios.delete(api.users + `/admin/promo-codes/${promo_code_id}`);
  const deletePromoToast = (body?: any) =>
    toastifyPromises.delete({
      asyncFunction: deletePromoReq(body),
      pendingMsg: "Deleting Promo",
      SuccessMsg: "Promo deleted successfully",
    });

  //referral
  //referral
  //referral
  //referral
  //referral

  //get referral toast
  const getReferralReq = ({ page = 1, item_per_page = 50, q = "", status = "", start_date = "", end_date = "", component = "" }: any) => {
    return axios.get(api.users + `/admin/referrals/?&page=${q ? "" : page}&item_per_page=${item_per_page}&q=${q}&status=${status}&start_date=${start_date}&end_date=${end_date}&component=${component}`);
  };
  const getReferralToast = (body?: any) => toastifyPromises.get({ asyncFunction: getReferralReq(body) });

  return {
    getUserStatsToast,
    getReferralToast,
    getUsersToast,
    getUserToast,
    addUserToast,
    updateUserToast,
    deleteUserToast,
    updateUserStatusToast,
    idStatusToast,
    getPromoCodesToast,
    getPromoDetailsToast,
    createPromoToast,
    updatePromoToast,
    deletePromoToast,
  };
};
