import axios from 'axios';
import { api } from '../constants/urls';
import { useToastifyPromises } from '../helpers/toastifyHelper';

export const useEmergencyApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //   1.get emergencies
  const getEmergencyReq = ({
    auth_id = '',
    page = 1,
    item_per_page = 50,
    start_date = '',
    status='',
    end_date = '',
    component = '',
    q = '',
  }: any) => {
    return axios.get(
      api.trips +
        `/admin/emergencies/?auth_id=${auth_id}&q=${q}&start_date=${start_date}&end_date=${end_date}&status=${status}&page=${page}&item_per_page=${item_per_page}&component=${component}`
    );
  };
  const getEmergencyToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getEmergencyReq(body) });

  //   2.get emergency contacts
  const getEmergencyContactReq = ({ type = '', component = '',page = 1,
  item_per_page = 50, }: any) => {
    return axios.get(
      api.trips +
        `/admin/emergency-contacts/?type=${type}&page=${page}&item_per_page=${item_per_page}&component=${component}`
    );
  };
  const getEmergencyContactToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getEmergencyContactReq(body) });

  //3. add emergency contact
  const createEmergencyContactReq = (body: any) =>
    axios.post(api.trips + `/admin/emergency-contacts`, body);
  const createEmergencyContactToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: createEmergencyContactReq(body),
      pendingMsg: 'Adding Contact...',
      SuccessMsg: 'Emergency contact added successfully',
    });

  //4. delete emergency conatact
  const deleteEmergencyContactReq = (body: any) => {
    if (!Array.isArray(body?.contact)) {
      return axios.delete(
        api.trips + `/admin/emergency-contacts/${body?.contact}`
      );
    } else {
      let hasError = false;
      const requests = body?.contact?.map((id: string) =>
        axios.delete(api.trips + `/admin/users/${id}`)
      );
      return Promise.all(requests)
        .then((responses) => {
          responses.forEach((response) => {
            if (response?.data?.status === 'error') hasError = true;
          });

          return hasError;
        })
        .then((isError) => {
          return isError
            ? {
                data: {
                  status: 'error',
                  msg: 'At least one request failed',
                },
              }
            : {
                data: {
                  status: 'ok',
                },
              };
        });
    }
  };
  const deleteEmergencyContactToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: deleteEmergencyContactReq(body),
      pendingMsg: 'Deleting Contact',
      SuccessMsg: 'Contact(s) Deleted Successfully',
    });

  //3. update emergency
  const updateEmergencyReq = (body: any) =>
    axios.put(api.trips + `/admin/emergencies/${body?.contact}`, body);
  const updateEmergencyToast = (body: any) =>
    toastifyPromises.post({
      asyncFunction: updateEmergencyReq(body),
      pendingMsg: 'Resolving request...',
      SuccessMsg: 'Request resolved successfully',
    });

  return {
    getEmergencyToast,
    updateEmergencyToast,
    getEmergencyContactToast,
    createEmergencyContactToast,
    deleteEmergencyContactToast,
  };
};
