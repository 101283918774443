import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { Table } from 'reactstrap';
import EmptyPage from '../../../components/EmptyPage';
import NewFilter from '../../../components/filter/NewFilter';
import ExportComponent from '../../../components/shared/exportComponent';
import FilterComponent from '../../../components/shared/filterComponent';
import WaitingLoader from '../../../components/shared/waitingLoader';
import PaginationComponent from '../../../components/tablesComponents/paginationComponent';
import SeearchComponent from '../../../components/tablesComponents/searchComponent';
import TableDataCounter from '../../../components/tablesComponents/tableDataCounter';
import queryKeys from '../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';
import { calculatePostDate } from '../../../helpers/dateTimeHelper';
import { usePaginationHelper } from '../../../helpers/paginationHelper';
import { useQueryStringHelper } from '../../../helpers/queryStringHelper';
import { useEmergencyApiService } from '../../../services/emergencyApiServices';
import { ObjectType, ReactChangeEventType } from '../../../types/common';
import { getStatusFromemergencyType } from '../users/helpers/strings';
import EmergencyDetails from './components/emergencyDetails';
import { getEmergencyStatusColor, getEmergencyStatusText } from './data/data';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setEmergencyType: Dispatch<SetStateAction<string>>;
  activeTab: string;
  emergencyType: string;
};
const EmergencyLog = (props: Props) => {
  const { activeTab, setActiveTab, setEmergencyType, emergencyType } = props;
  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [status, setStatus] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState<ObjectType | []>([]);
  const [emergencyId, setEmergencyId] = useState('');
  const [emergencyDetails, setEmergencyDetails] = useState([]);
  const { getPageNoFromQueryString, getQueryStringValue } =
    useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const [keyword, setKeyword] = useState('');
  const { getEmergencyToast } = useEmergencyApiService();
  const { setBreadCrumbData, deleteModalConfig } =
    useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData([`${activeTab}`]);
  }, [activeTab]);

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
    status,
  });

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const { isLoading, data: emergency } = useQuery(
    [queryKeys.emergency, currentPage, filterState],
    () =>
      getEmergencyToast({
        component: '',
        page: currentPage,
        start_date: startDate,
        end_date: endDate,
        status: status || getStatusFromemergencyType(emergencyType),
      }),
    {
      select: (data) => data?.data || [],
      // enabled: false,
      keepPreviousData: true,
    }
  );
  const { data: emergencyCount } = useQuery(
    [queryKeys.emergencyCount, currentPage, filterState],
    () =>
      getEmergencyToast({
        component: 'count',
        start_date: startDate,
        page: currentPage,
        status: status || getStatusFromemergencyType(emergencyType),
        end_date: endDate,
      }),
    {
      select: (data) => data?.data?.total || 0,
      // enabled: false,
      keepPreviousData: true,
    }
  );

  const handleChange = (e: ReactChangeEventType) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const emergencyTypeOptions = [
    { label: 'All', value: '' },
    { label: 'Resolved', value: '1' },
    { label: 'Unresolved', value: '0' },
  ];
  const filterOptions = [
    {
      title: 'Status',
      options: emergencyTypeOptions,
      currentValue: status,
      onChange: setStatus,
      name: 'emergencyType',
    },
  ];

  const onApply = () => {
    setFilterState({ startDate, endDate, status });
    handleToggle();
  };

  if (isLoading && !emergency) {
    return <WaitingLoader />;
  } else if (emergencyId) {
    return (
      <div>
        <EmergencyDetails
          emergencyId={getQueryStringValue('emergency-id') || emergencyId}
          emergencyDetails={emergencyDetails}
          setEmergencyId={setEmergencyId}
        />
      </div>
    );
  }
  return (
    <>
      <div className='d-flex justify-content-between text-nowrap flex-wrap'>
        <div className='d-flex align-items-center'>
          <div className=''>
            <div className='d-flex align-items-center  '>
              <div className='fw-bolder fs-5 capitalize'>Emergency Log</div>
            </div>
          </div>
          <div className='ms-4'>
            {<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='me-3'>
            {emergency?.length > 0 && <ExportComponent />}
          </div>

          <div className='me-3'>
            <NewFilter
              direction={'start'}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              options={filterOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />
          </div>
        </div>
      </div>

      <div className='mt-2'>
        {emergency?.length === 0 && <EmptyPage text='No Emergency' />}
        {emergency?.length > 0 && (
          <Table responsive className='table-hover'>
            <thead className=''>
              <tr>
              <th className=''>Reporter</th>
              <th className=''>Phone Number</th>
              <th className=''>Email Address</th>
              <th className=''>GPS Location</th>
              <th className=''>Longitude</th>
              <th className=''>Time of Report</th>
              <th className=''>Status</th>
              </tr>
            </thead>

            <tbody>
              {emergency?.map((item: any, index: number) => (
                <Fragment key={item?._id}>
                  <tr
                    className='cursor-pointer'
                    onClick={() => {
                      setEmergencyId(item?._id);
                      setEmergencyDetails(item);
                    }}
                  >
                    <td className='capitalize'>
                      {`${item.user_data?.first_name} ${item.user_data?.last_name}`}
                    </td>
                    <td>{item?.user_data?.phone_number || 'N|A'}</td>
                    <td>{item?.trip_data_all?.driver_data?.email}</td>
                    <td>{item?.latitude.toFixed(4) || 0}</td>
                    <td>{item?.longitude.toFixed(4) || 0}</td>
                    <td>{calculatePostDate(item?.createdAt)}</td>
                    <td
                      className={`fw-bold text-success text-capitalize ${getEmergencyStatusColor(
                        item.status
                      )}`}
                    >
                      {getEmergencyStatusText(item.status)}
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        )}
        {emergency?.length > 0 && (
          <div className='d-flex justify-content-between align-items-center mt-4 flex-wrap'>
            <div className='mt-2'>
              <TableDataCounter
                item={emergency}
                currentPage={currentPage}
                totalCount={emergencyCount}
              />
            </div>
            <div className='mt-2'>
              <PaginationComponent
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={emergencyCount}
                onChange={onPaginate}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EmergencyLog;
