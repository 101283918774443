import axios from "axios";
import { ObjectType } from "../types/common";
import keys from "../constants/keys";

export const getActualAddress = async (lat: string, lng: string) => {
  const res = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + lat + "," + lng + "&key=" + keys.googleKey)
    // const res = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + lat + "," + lng + "&key=" + "AIzaSyCoQZHsRDKByviyQxQ2HVzDiutukQG9tSw")
    // const res = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + lat + "," + lng + "&key=" + `${process.env.GOOGLE_API_KEY}`)

    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson?.results[0]?.formatted_address;
    });

  return res;
};

export const getPlaceDetails = async (placeId: string) => {
  return await axios
    .post(`https://staging-server.lagosride.com/trip-service2/v2/trips/google-service-search`, {
      data: {
        url: `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${keys.googleKey}}`,
        method: "GET",
      },
    })
    .then((response) => {
      return response?.data;
    });
  // return await fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURI(address)}&key=${KEYS.googleMap}&fields=formatted_address&components=country:ng`).then((response) => response.json());
};
export const getAddressPredictions = async (address: string) => {
  return await axios
    .post(`https://staging-server.lagosride.com/trip-service2/v2/trips/google-service-search`, {
      data: {
        url: `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURI(address)}&key=${keys.googleKey}`,
        method: "GET",
      },
    })
    .then((response) => {
      return response?.data?.predictions?.map((item: ObjectType) => ({
        value: item,
        label: `${item?.description}`,
      }));
    });
  // return await fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURI(address)}&key=${KEYS.googleMap}&fields=formatted_address&components=country:ng`).then((response) => response.json());
};
