import { useState, useEffect, useContext } from "react";
import CusCard from "../../../components/shared/cusCard";
import TripFares from "./components/tripFares";
import OneOffPayment from "./components/oneOffPayment";
import { AppPrefrenceContext } from "../../../contexts/AppPrefrenceContext";
import VerificationFee from "./components/verificationFee";
import Others from "./components/others";
import DriverAppSettings from "./components/appSettings/driverAppSettings";
import RiderAppSettings from "./components/appSettings/riderAppSettings";

const AppSetting = () => {
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);

  const tabs = ["Drivers App Settings", "Riders App Settings"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  useEffect(() => {
    setBreadCrumbData(["Setup", "App Setting"]);
  }, []);

  return (
    <CusCard className="p-0" style={{ minHeight: "70vh" }}>
      <div className="d-flex">
        {tabs.map((item) => (
          <div className={`px-3 bg-none cursor-pointer ${activeTab === item && "gradient-btn text-white fw-bold"}`} key={item} style={{ paddingTop: "11px", paddingBottom: "11px" }} onClick={() => setActiveTab(item)}>
            {item}
          </div>
        ))}
      </div>

      <div className="p-3 ">
        {activeTab === "Drivers App Settings" && <DriverAppSettings />}
        {activeTab === "Riders App Settings" && <RiderAppSettings />}
      </div>
    </CusCard>
  );
};

export default AppSetting;
