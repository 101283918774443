import { getTodayDate } from '../../../../helpers/dateTimeHelper';

export const getStartDate = (promoType: string) => {
  if (promoType === 'active') {
    return getTodayDate();
  } else {
    return '';
  }
};
export const getEndDate = (promoType: string) => {
    if (promoType === 'inactive') {
      return getTodayDate();
    } else {
      return '';
    }
  };
