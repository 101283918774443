import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { Table } from 'reactstrap';
import EmptyPage from '../../../components/EmptyPage';
import NewFilter from '../../../components/filter/NewFilter';
import ExportComponent from '../../../components/shared/exportComponent';
import FilterComponent from '../../../components/shared/filterComponent';
import WaitingLoader from '../../../components/shared/waitingLoader';
import PaginationComponent from '../../../components/tablesComponents/paginationComponent';
import SeearchComponent from '../../../components/tablesComponents/searchComponent';
import TableDataCounter from '../../../components/tablesComponents/tableDataCounter';
import queryKeys from '../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';
import { calculatePostDate } from '../../../helpers/dateTimeHelper';
import { usePaginationHelper } from '../../../helpers/paginationHelper';
import { useQueryStringHelper } from '../../../helpers/queryStringHelper';
import { useActivityLogApiService } from '../../../services/activityLogApiServices';
import ActivityDetailsLog from './components/activityDetailsLog';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  activeTab: string;
};
const ActivityLog = (props: Props) => {
  const { activeTab } = props;
  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [activityLogId, setActivityLogId] = useState('');
  const { getPageNoFromQueryString } = useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const [keyword, setKeyword] = useState('');
  const { getActivityLogToast } = useActivityLogApiService();
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData([`Activity Log`, `${activeTab}`]);
  }, [activeTab]);

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const { isLoading, data: activityLog } = useQuery(
    [queryKeys.activityLog, currentPage, filterState],
    () =>
      getActivityLogToast({
        start_date: startDate,
        page: currentPage,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data || [],
      keepPreviousData: true,
    }
  );
  const { data: activityLogCount } = useQuery(
    [queryKeys.activityLogCount, currentPage, filterState],
    () =>
      getActivityLogToast({
        component: 'count',
        page: currentPage,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
    }
  );
  const filterOptions: [] = [];

  const onApply = () => {
    setFilterState({ startDate, endDate });
    handleToggle();
  };

  if (isLoading && !activityLog) {
    return <WaitingLoader />;
  } else if (activityLogId) {
    return (
      <div>
        <ActivityDetailsLog activityLogId={activityLogId} />
      </div>
    );
  }
  return (
    <>
      <div className='d-flex justify-content-between text-nowrap flex-wrap'>
        <div className='d-flex align-items-center'>
          <div className=''>
            <div className='d-flex align-items-center  '>
              <div className='fw-bolder fs-5 capitalize'>Activity Log</div>
            </div>
          </div>
          <div className='ms-4'>
            {<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='me-3'>
            {activityLog?.length > 0 && <ExportComponent />}
          </div>

          <div className='me-3'>
            <NewFilter
              direction={'start'}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              options={filterOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />
          </div>
        </div>
      </div>

      <div className='mt-2'>
        {activityLog?.length === 0 && <EmptyPage text='No Activity' />}
        {activityLog?.length > 0 && (
          <Table responsive className='table-hover'>
            <thead className=''>
              <tr>
                <th className=''>Date and Time</th>
                <th className=''>Admin Name</th>
                <th className=''>Activity Description</th>
              </tr>
            </thead>

            <tbody>
              {activityLog?.map((item: any) => (
                <Fragment key={item?.createdAt}>
                  <tr
                    className='cursor-pointer capitalize'
                    onClick={() => {
                      setActivityLogId(item?.auth_id);
                    }}
                  >
                    <td>{calculatePostDate(item?.createdAt)}</td>
                    <td>
                      {item?.user_data
                        ? `${item?.user_data?.first_name} ${item?.user_data?.last_name}`
                        : ''}
                    </td>
                    <td>{item?.body || 'N|A'}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        )}
        {activityLog?.length > 0 && (
          <div className='d-flex justify-content-between align-items-center mt-4 flex-wrap'>
            <div className='mt-2'>
              <TableDataCounter
                item={activityLog}
                currentPage={currentPage}
                totalCount={activityLogCount}
              />
            </div>
            <div className='mt-2'>
              <PaginationComponent
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={activityLogCount}
                onChange={onPaginate}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityLog;
