import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { AppPrefrenceContext } from "../../../contexts/AppPrefrenceContext";
import OverviewSumaryCards from "./components/overviewSummaryCards";
import PaymentsType from "./components/paymentsType";
import TripPaymentsTrend from "./components/tripPaymentsTrend";

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<string>>;
  setRequestType: Dispatch<SetStateAction<string>>;
};

const TripsPaymentOverview = (props: Props) => {
  const { setActiveTab, setStatus, setRequestType } = props;
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData(["Trips Payment", "Overview"]);
  }, []);

  return (
    <div>
      {/* <h5 className="fw-bold">Rider Overview</h5> */}

      <div className="">
        <OverviewSumaryCards setActiveTab={setActiveTab} setStatus={setStatus} setRequestType={setRequestType} />
      </div>
      <Row className="mt-md-3">
        <Col md={7} className="mt-3">
          <TripPaymentsTrend />
        </Col>
        <Col md={5} className="mt-3">
          <PaymentsType />
        </Col>
      </Row>
    </div>
  );
};

export default TripsPaymentOverview;
