import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Modal, ModalBody, Table } from 'reactstrap';
import EmptyPage from '../../../components/EmptyPage';
import NewFilter from '../../../components/filter/NewFilter';
import ExportComponent from '../../../components/shared/exportComponent';
import FilterComponent from '../../../components/shared/filterComponent';
import WaitingLoader from '../../../components/shared/waitingLoader';
import PaginationComponent from '../../../components/tablesComponents/paginationComponent';
import SeearchComponent from '../../../components/tablesComponents/searchComponent';
import TableDataCounter from '../../../components/tablesComponents/tableDataCounter';
import queryKeys from '../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';
import { calculatePostDate } from '../../../helpers/dateTimeHelper';
import { usePaginationHelper } from '../../../helpers/paginationHelper';
import { useQueryStringHelper } from '../../../helpers/queryStringHelper';
import { useWalletApiService } from '../../../services/walletApiServices';
import {
  paymentMethodOptions,
  paymentStatusOptions,
  requestTypeOptions,
} from '../old-finance/revenue/data';
import TripPaymentDetails from './components/tripPaymentDetails';
import {
  getTripPaymentStatus,
  getTripPaymentStatusColor,
} from '../users/helpers/strings';
import GradientButton from '../../../components/shared/gradidentButton';
import RedButton from '../../../components/shared/redButton';
import { AiOutlineClose } from 'react-icons/ai';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  status: string;
  requestType: string;
};
const TripsPaymentLog = (props: Props) => {
  const { status: statusFromProps, requestType: requestTypeFromProps } = props;
  const {
    getPageNoFromQueryString,
    getQueryStringValue,
    attachQueryStringToUrl,
  } = useQueryStringHelper();

  const [toggled, setToggled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const [keyword, setKeyword] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [statusId, setStatusId] = useState('');
  const { getTripPaymentToast, updatePaymentStatusToast } =
    useWalletApiService();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [status, setStatus] = useState(statusFromProps);
  const [requestType, setRequestType] = useState(requestTypeFromProps);
  const queryClient = useQueryClient();

  const { setBreadCrumbData } = useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData(['Trips Payment', 'Payment Log']);
    const id = getQueryStringValue('payment-id');
    if (id) setPaymentId(id);
  }, []);

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
    status,
    requestType,
  });

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const { isLoading, data: tripPayment } = useQuery(
    [queryKeys.riderTripPayment, currentPage, keyword, filterState],
    () =>
      getTripPaymentToast({
        // user_type: "driver",
        wallet_type: 'user-wallet',
        start_date: startDate,
        end_date: endDate,
        page: currentPage,
        status,
        request_type: requestType,
        q: keyword,
      }),
    {
      select: (data) => data?.data || [],
      keepPreviousData: true,
    }
  );

  const { data: riderTripPaymentCount } = useQuery(
    [queryKeys.riderTripPaymentCount, currentPage, keyword, filterState],
    () =>
      getTripPaymentToast({
        // user_type: "driver",
        start_date: startDate,
        end_date: endDate,
        page: currentPage,
        status,
        q: keyword,
        request_type: requestType,
        component: 'count',
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
    }
  );

  const { refetch: exportTripPayments } = useQuery(
    [queryKeys.riderTripPaymentExport],
    () =>
      getTripPaymentToast({
        start_date: startDate,
        end_date: endDate,
        page: currentPage,
        status,
        q: keyword,
        request_type: requestType,
        component: 'export',
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
      enabled: false,
    }
  );

  const filterOptions = [
    {
      title: 'Payment Method',
      options: paymentMethodOptions,
      currentValue: paymentMethod,
      onChange: setPaymentMethod,
      name: 'payMethod',
    },
    {
      title: 'Status',
      options: paymentStatusOptions,
      currentValue: status,
      onChange: setStatus,
      name: 'payStatus',
    },
    {
      title: 'Request Type',
      options: requestTypeOptions,
      currentValue: requestType,
      onChange: setRequestType,
      name: 'requestType',
    },
  ];

  const onApply = () => {
    setFilterState({ startDate, endDate, status, requestType });
    handleToggle();
  };

  const updatePaymentMutation = useMutation(updatePaymentStatusToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        queryClient.invalidateQueries(queryKeys.riderTripPayment);
        queryClient.invalidateQueries(queryKeys.riderTripPaymentCount);
      }
      setIsModalOpen(false);
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updatePaymentMutation.mutate({
      payment_id: statusId,
    });
  };

  if (isLoading && !tripPayment) {
    return <WaitingLoader />;
  } else if (paymentId) {
    return (
      <TripPaymentDetails
        // driverDetails={driverDetails}
        tripPayment={tripPayment}
        paymentId={paymentId}
        setPaymentId={setPaymentId}
      />
    );
  }

  return (
    <>
      <div className='d-flex justify-content-between text-nowrap flex-wrap'>
        <div className='d-flex align-items-center'>
          {/* <div className="fw-bold">{getRiderType(riderType)} Riders</div> */}
          <div className=''>
            <div className='d-flex align-items-center  '>
              <div className='fw-bolder fs-5'>{`Trip Payment Log`}</div>
            </div>
          </div>
          <div className='ms-4'>
            {<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='me-3'>
            <NewFilter
              direction={'start'}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              options={filterOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />
          </div>
          <div className=''>
            {tripPayment?.length > 0 && (
              <ExportComponent onClick={exportTripPayments} />
            )}
          </div>
        </div>
      </div>

      <div className='mt-2'>
        {tripPayment?.length === 0 && <EmptyPage text='No Trip Payments' />}
        {tripPayment?.length > 0 && (
          <Table responsive className='table-hover'>
            <thead className=''>
              <tr>
                <th className=''>Date & Time</th>
                <th className=''>Trip ref.</th>
                <th className=''>Rider Name</th>
                <th className=''>Payment/Charge Method</th>
                <th className=''>Amount/Actual</th>
                <th className=''>Status</th>
                <th className=''>Action</th>
              </tr>
            </thead>

            <tbody>
              {tripPayment?.map((item: any) => (
                <Fragment key={item.trip_id}>
                  <tr
                    className='cursor-pointer'
                    onClick={() => {
                      setPaymentId(item.payment_id);
                      attachQueryStringToUrl('payment-id', item.payment_id);
                    }}
                  >
                    <td>{calculatePostDate(item.createdAt)}</td>
                    <td>{item.trip_ref}</td>
                    <td>
                      {item.first_name} {item.last_name}
                    </td>
                    <td>
                      <small
                        className='capitalize fw-bold'
                        style={{
                          color:
                            item.payment_method === 'card' ? '#003dc4' : '',
                        }}
                      >
                        {item.payment_method} / {item.charge_method}
                      </small>
                    </td>

                    <td>
                      ₦{item.amount.toLocaleString()} / ₦
                      {item.actual_amount.toLocaleString()}
                    </td>

                    <td
                      className={`fw-bold text-capitalize ${getTripPaymentStatusColor(
                        item?.status
                      )}`}
                    >
                      {getTripPaymentStatus(item?.status)}
                    </td>
                    {item?.status === 1 &&
                      item?.payment_method === 'transfer' && (
                        <td>
                          <div onClick={(e: any) => e.stopPropagation()}>
                            <GradientButton
                              text={'Approve'}
                              className='p-1'
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setStatusId(item.payment_id);
                                setIsModalOpen(!isModalOpen);
                              }}
                            />
                          </div>
                        </td>
                      )}
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        )}
        {tripPayment?.length > 0 && (
          <div className='d-flex justify-content-between align-items-center mt-4 flex-wrap'>
            <div className='mt-2'>
              <TableDataCounter
                item={tripPayment}
                currentPage={currentPage}
                totalCount={riderTripPaymentCount}
              />
            </div>
            <div className='mt-2'>
              <PaginationComponent
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={riderTripPaymentCount}
                onChange={onPaginate}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        size='md'
        isOpen={isModalOpen}
        toggle={handleToggle}
        className='modal-dialog-centered'
        style={{ width: '400' }}
        backdrop='static'
      >
        <ModalBody className='p-4'>
          <div className='d-flex justify-content-between'>
            <div className='fw-bold'>Approve this payment?</div>
            <AiOutlineClose
              size={20}
              className='cursor-pointer'
              onClick={() => setIsModalOpen(!isModalOpen)}
            />
          </div>
          <div className='mt-1 text-muted'>
            <small>
              This action would approve payment and can not be undone.{' '}
            </small>
          </div>
          <div className='mt-5 d-flex justify-content-end'>
            <div style={{ width: 150 }}>
              <RedButton
                className='py-2'
                text='Cancel'
                cancelIcon
                onClick={() => setIsModalOpen(!isModalOpen)}
              />
            </div>
            <div className='ms-3' style={{ width: 150 }}>
              <GradientButton
                text='Approve'
                className='py-2'
                type='submit'
                onClick={handleSubmit}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TripsPaymentLog;
