import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { Card, CardTitle } from 'reactstrap';
import queryKeys from '../../../../constants/queryKeys';
import { getDataFromReferralDataType } from '../../../../helpers/chartsDataHelper';
import { useUsersApiService } from '../../../../services/usersService';

const PromoTrend = () => {
  const { getReferralToast } = useUsersApiService();

  const { data: referralStats } = useQuery(
    [queryKeys.referralStats],
    () =>
      getReferralToast({
        component: 'count-monthly',
        // start_date: startDate,
      }),
    {
      select: (data) => data?.data || [],
      // enabled: false,
      keepPreviousData: true,
    }
  );

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: 'white',
        },
        beginAtZero: true,
      },
      y: {
        grid: {
          display: false,
          borderColor: 'white',
        },
        ticks: {
          min: 0,
          max: 15,
          stepSize: 5,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
  };

  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Total',
        data: getDataFromReferralDataType({
          dateType: 'monthly',
          type: 'total',
          data: referralStats,
        }),
        borderWidth: 1,
        borderColor: '#002ed0',
        pointRadius: 2,
      },
      {
        label: 'Pending',
        data: getDataFromReferralDataType({
          dateType: 'monthly',
          type: 'pending',
          data: referralStats,
        }),
        borderWidth: 1,
        borderColor: '#fe9603',
        pointRadius: 2,
      },
      {
        label: 'Confirmed',
        data: getDataFromReferralDataType({
          dateType: 'monthly',
          type: 'confirmed',
          data: referralStats,
        }),
        borderWidth: 1,
        borderColor: '#05b4b0',
        pointRadius: 2,
      },
    ],
  };

  const year = new Date().getFullYear();
  return (
    <Card body>
      <CardTitle className=' justify-content-between'>
        <div className='justify-content-between d-flex w-100'>
          <div className='fw-bold'>Total, Confirmed and Pending - Monthly </div>
          <div>{year}</div>
        </div>
      </CardTitle>
      <div>
        <Line data={data} options={options} style={{ maxHeight: '250px' }} />
      </div>
    </Card>
  );
};

export default PromoTrend;
