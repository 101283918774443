import AsyncSelect from "react-select/async";
import axios from "axios";
import React, { useState } from "react";
import { ObjectType } from "../../../../types/common";
import { api } from "../../../../constants/urls";

type Props = {
  onChange: any;
  value?: any;
};
const SelectDriver = ({ onChange, value }: Props) => {
  //    const [debouncer] = useDebounce();

  const itemChange = (item: string) => {
    onChange(item);
    return item;
  };

  const searchVehicle = async (data: string) => {
    try {
      const res = await axios.get(`${api.users}/admin/users?user_type=driver&q=${data}&account_status=4`);
      return res?.data?.data?.map((item: ObjectType) => ({
        value: item,
        label: `${item.first_name}  ${item.last_name} `,
      }));
    } catch (err) {}
  };

  let timeOutSearch: any = React.useRef(); //for holding timeout when request is initialize
  const promiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      clearTimeout(timeOutSearch.current); //clear any pending timeout before creating a new one
      timeOutSearch.current = setTimeout(() => {
        resolve(searchVehicle(inputValue));
      }, 1000);
    });

  return (
    <div>
      <AsyncSelect value={value} cacheOptions defaultOptions loadOptions={promiseOptions as any} onChange={itemChange as any} placeholder="Search by name, email or phone number " required />
    </div>
  );
};

export default SelectDriver;
