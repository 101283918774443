import {
  Dispatch,
  SetStateAction,
  useContext,
  useState
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { Card, Col, Modal, ModalBody, Row } from 'reactstrap';
import RedButton from '../../../../components/shared/redButton';
import queryKeys from '../../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../../contexts/AppPrefrenceContext';
import { calculatePostDay, calculatePostedDate } from '../../../../helpers/dateTimeHelper';
import { useQueryStringHelper } from '../../../../helpers/queryStringHelper';
import { useUsersApiService } from '../../../../services/usersService';
import { ObjectType, ReactChangeEventType } from '../../../../types/common';
import BeneficiaryLog from './benficiaryLog';
import PromoModalBody from './promoModalBody';

type Props = {
  promoId: string;
  promoDetails: ObjectType;
  setPromoId: Dispatch<SetStateAction<string>>;
};

const promoDetails = (props: Props) => {
  const { removeAllQueryStringsFromURL } = useQueryStringHelper();
  const { deleteModalConfig } = useContext(AppPrefrenceContext);
  const { promoDetails, setPromoId } = props;
  const [benId, setBenId] = useState('');
  const queryClient = useQueryClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [editingId, setEditingId] = useState('');
  const [data, setData] = useState<ObjectType | []>([]);
  const { deletePromoToast } = useUsersApiService();

  const handleModalToggle = () => {
    setModalOpen((prev) => !prev);
  };

  const deletePromoMutation = useMutation(deletePromoToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        setPromoId('');
        queryClient.invalidateQueries(queryKeys.promo);
        queryClient.invalidateQueries(queryKeys.promoCount);
      }
    },
    // onError: (err) => {},
  });

  const handleChange = (e: ReactChangeEventType) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleEdit = (data: ObjectType) => {
    setEditingId(data.promo_id);
    setData({
      codeOwner: data?.promo_code_owner,
      customCode: data?.code,
      rideNum: data?.num_of_rides,
      limit: data?.users_limit,
      discount: data?.discount_value, 
      status: data?.status,
      discountType: data?.discount_type,
      startDate: calculatePostedDate(data?.start_date),
      expiryDate: calculatePostedDate(data?.expiry_date),
      description: data?.description,
      codeType: data?.code_type,
    });
    handleModalToggle && handleModalToggle();
  };

  if (benId) {
    return (
      <div>
        <BeneficiaryLog benId={benId} setBenId={setBenId} />
      </div>
    );
  }

  return (
    <div style={{ minHeight: '100vh' }}>
      <div className='d-flex justify-content-between align-items-center text-nowrap flex-wrap'>
        <div className='d-flex align-items-center '>
          <BsArrowLeft
            size={30}
            className='cursor-pointer'
            onClick={() => {
              setPromoId('');
              removeAllQueryStringsFromURL();
            }}
          />
          <div className='fw-bolder ms-3 fs-5'>{promoDetails?.code} </div>
        </div>
        <div className='d-flex align-items-center'>
          <div
            className='me-3 d-flex align-items-center cursor-pointer px-2 py-1 rounded gap-1'
            onClick={() => handleEdit(promoDetails)}
          >
            <div>
              <MdEdit size={15} />
            </div>{' '}
            <div>Edit</div>
          </div>

          <div style={{ width: 120 }}>
            <RedButton
              className='py-2'
              text='Delete Promo'
              onClick={() =>
                deleteModalConfig({
                  firstText: `Delete ${promoDetails?.code}`,
                  secondText: `This action would remove ${promoDetails?.code} from the portal and is irreversible.`,
                  callback: () =>
                    deletePromoMutation.mutate({
                      promo_code_id: promoDetails?.promo_id,
                    }),
                })
              }
            />
          </div>
        </div>
      </div>

      <div
        className='mt-3 p-4 rounded border-none'
        style={{
          backgroundColor: '#f5fbfd',
        }}
      >
        <h5 className='fw-bold'>{promoDetails?.code}</h5>
        <div className='text-grey fs-6'>Promo Code</div>
      </div>

      <div>
        <Card body className='mt-3 p-2'>
          <div className='d-flex justify-content-between capitalize'>
            <div className='d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1'>
              <div className='flex-grow-1'>
                <div className=''>
                  <div className='text-blue fw-semibold'>About</div>
                </div>
                <Row className='w-100'>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Promo Code Owner</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {promoDetails?.promo_code_owner || 'N|A'}
                    </div>
                  </Col>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Code Type</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {promoDetails?.code_type}
                    </div>
                  </Col>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Creation Date</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {calculatePostDay(promoDetails?.createdAt)}
                    </div>
                  </Col>
                  <Col className='mt-2 offset-md-1' md={2}>
                    <div>
                      <small className='text-grey'>Discount Type</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {promoDetails?.discount_type}
                    </div>
                  </Col>
                  <Col className='mt-2 offset-md-1' md={2}>
                    <div>
                      <small className='text-grey'>Discount Value</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {promoDetails?.discount_value}
                    </div>
                  </Col>
                </Row>

                <Row className='w-100 mt-3'>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Expiry Date</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {calculatePostDay(promoDetails?.expiry_date)}
                    </div>
                  </Col>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>Number of Rides</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {promoDetails?.num_of_rides}
                    </div>
                  </Col>
                  <Col className='mt-2' md={2}>
                    <div>
                      <small className='text-grey'>User Limit</small>
                    </div>
                    <div className='mt-1 fw-semibold'>
                      {promoDetails?.users_limit}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Card>

        <div
          className='fs-6 fw-semibold my-3 cursor-pointer p-4'
          style={{ textDecoration: 'underline', lineHeight: '1.5' }}
          onClick={() => {
            setBenId(promoDetails.promo_id);
          }}
        >
          View Beneficiaries
        </div>
      </div>
      <Modal
        size='lg'
        isOpen={modalOpen}
        toggle={handleModalToggle}
        backdrop='static'
        // style={{ width: 400 }}
      >
        <ModalBody className='p-4'>
          <div className='d-flex justify-content-between'>
            <div className='fw-bold'>Edit Promo</div>
            <AiOutlineClose size={20} onClick={handleModalToggle} />
          </div>
          <PromoModalBody
            handleToggle={handleModalToggle}
            promoDetails={promoDetails}
            editingId={editingId}
            handleChange={handleChange}
            data={data}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default promoDetails;
