import { useContext, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useMutation } from "react-query";
import { Button, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";
import CusCard from "../../../components/shared/cusCard";
import { AuthContext } from "../../../contexts/AuthContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { useRoleApiService } from "../../../services/rolesApiServices";

type Props = {};


const Profile = (props: Props) => {
  const { postLoading } = useContext(LoadingContext);
  const { updateStaffAdminToast } = useRoleApiService();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [currentPassView, setCurrentPassView] = useState("password");
  const [newPassView, setNewPassView] = useState("password");
  const { userData } = useContext(AuthContext);

  const cardItems = [
    { label: "First Name", value: userData.first_name },
    { label: "Last Name", value: userData.last_name },
    { label: "Phone Number", value: userData.phone_number },
    { label: "Email Address", value: userData.email },
    { label: "Gender", value: userData.gender },
    { label: "Role", value: userData.user_type },
    { label: "Password", value: "**********" },
  ];

  const resetPasswordMutation = useMutation(updateStaffAdminToast, {
    onSuccess: async (res) => {
      const resData = res.data.status;
      if (resData !== "error") {
        setNewPass("");
        setModalOpen(false);
      }
    },
  });

  const handlePasswordReset = (e: { preventDefault: () => void }) => {
    const body = { old_password: currentPass, new_password: newPass, auth_id: userData.auth_id };
    e.preventDefault();
    resetPasswordMutation.mutate(body);
  };





  return (
    <CusCard className="p-3" style={{ minHeight: "70vh" }}>
      <h5 className="fw-bold">User Profile</h5>
      <div className="rounded bg-secondary overflow-hidden me-2 d-flex align-items-center justify-content-center" style={{ width: 243, height: 243 }}>
        <img src={`${userData.avatar || "/images/profile.png"}`} height="100%" width="100%s" />
      </div>

      <div className="mt-4" style={{ maxWidth: 700 }}>
        {cardItems.map((item) => (
          <div key={item.label} className="border rounded p-2 px-3 mt-2 d-flex justify-content-between align-items-center">
            <div>
              <div>{item.label}</div>
              <h6 className="fw-semibold mt-2 capitalize">{item.value}</h6>
            </div>

            {item.label === "Password" && (
              <div className="cursor-pointer p-2 fw-semibold" style={{ backgroundColor: "#04A7A71A" }} onClick={() => setModalOpen((prev) => !prev)}>
                Update
              </div>
            )}
          </div>
        ))}
      </div>

      <Modal body className="p-5" centered isOpen={modalOpen} toggle={() => setModalOpen(false)} backdrop="static">
        <div className="d-flex justify-content-between mt-3 mx-3">
          <h5 className="text-center  fw-bolder">Change Password</h5>
          <IoMdClose className="cursor-pointer" onClick={() => setModalOpen((prev) => !prev)} />
        </div>
        <ModalBody style={{ height: 300 }}>
          <form onSubmit={handlePasswordReset}>
            <FormGroup>
              <Label for="current-pass">Old Password</Label>
              <InputGroup style={{ backgroundColor: "white" }}>
                <Input name="current-pass" id="current-pass" type={currentPassView as InputType} placeholder="Enter Old Password" className="border-end-0 bg-transparent" value={currentPass} onChange={(e) => setCurrentPass(e.target.value)} />
                <InputGroupText className="cursor-pointer bg-transparent border-start-0" onClick={() => (currentPassView === "password" ? setCurrentPassView("text") : setCurrentPassView("password"))}>
                  <span data-testid="password-peep">{currentPassView === "password" ? <AiOutlineEye color="#04ADB2" /> : <AiOutlineEyeInvisible color="#04ADB2" />}</span>
                </InputGroupText>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="new-pass">New Password</Label>
              <InputGroup style={{ backgroundColor: "white" }}>
                <Input name="new-pass" id="new-pass" type={newPassView as InputType} placeholder="Enter New Password" className="border-end-0 bg-transparent" value={newPass} onChange={(e) => setNewPass(e.target.value)} />
                <InputGroupText className="cursor-pointer bg-transparent border-start-0" onClick={() => (newPassView === "password" ? setNewPassView("text") : setNewPassView("password"))}>
                  <span data-testid="password-peep">{newPassView === "password" ? <AiOutlineEye color="#04ADB2" /> : <AiOutlineEyeInvisible color="#04ADB2" />}</span>
                </InputGroupText>
              </InputGroup>
            </FormGroup>
            <Button disabled={currentPass === "" || newPass === "" || postLoading} className="w-100 mt-3 mb-5 gradient-btn fw-bold">
              {postLoading ? "LOADING..." : "SUBMIT"}
            </Button>
          </form>
        </ModalBody>
      </Modal>
    </CusCard>
  );
};

export default Profile;
