
export const NairaSuccess = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.574219" width="22" height="22" rx="4" fill="url(#paint0_linear_636_9004)" fillOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.60425 11.5736C5.60425 8.59519 8.02171 6.17773 11.0001 6.17773C13.9785 6.17773 16.3959 8.59519 16.3959 11.5736C16.3959 14.5519 13.9785 16.9694 11.0001 16.9694C8.02171 16.9694 5.60425 14.5519 5.60425 11.5736ZM11.0001 5.30273C7.53846 5.30273 4.72925 8.11194 4.72925 11.5736C4.72925 15.0352 7.53846 17.8444 11.0001 17.8444C14.4617 17.8444 17.2709 15.0352 17.2709 11.5736C17.2709 8.11194 14.4617 5.30273 11.0001 5.30273ZM9.46643 9.25092C9.35621 9.25092 9.2542 9.2846 9.17664 9.36404C9.104 9.43906 9.07401 9.53656 9.07401 9.63964V10.4878H9.05959C8.85633 10.4878 8.67722 10.6445 8.67722 10.8549C8.67722 11.0653 8.85633 11.2219 9.05959 11.2219H9.07401V11.9639H9.05959C8.85633 11.9639 8.67722 12.1205 8.67722 12.3309C8.67722 12.5413 8.85633 12.698 9.05959 12.698H9.07401V13.5079C9.07401 13.6114 9.10364 13.7078 9.1723 13.7851L9.17626 13.7892C9.25043 13.8658 9.34899 13.8966 9.45337 13.8966C9.55768 13.8966 9.65617 13.8658 9.73032 13.7894C9.80523 13.7125 9.83926 13.6147 9.83926 13.5079V12.698H11.4828L12.3149 13.743L12.319 13.7477C12.4016 13.8418 12.5079 13.8966 12.6331 13.8966C12.7393 13.8966 12.8411 13.8672 12.9165 13.7894C12.9915 13.7125 13.0255 13.6147 13.0255 13.5079V12.6887C13.1912 12.6522 13.3229 12.5113 13.3229 12.3309C13.3229 12.1505 13.1912 12.0097 13.0255 11.9731V11.2127C13.1912 11.1761 13.3229 11.0353 13.3229 10.8549C13.3229 10.6745 13.1912 10.5336 13.0255 10.4971V9.63964C13.0255 9.5375 12.9964 9.44122 12.9252 9.36649C12.8521 9.28543 12.7531 9.25092 12.6462 9.25092C12.538 9.25092 12.4387 9.2864 12.3625 9.36443C12.2899 9.43945 12.2603 9.53656 12.2603 9.63964V10.4878H10.6517L9.79131 9.40464L9.7871 9.39983C9.70297 9.30393 9.59384 9.25092 9.46643 9.25092ZM9.83926 11.9639V11.2219H10.3072L10.8982 11.9639L9.83926 11.9639ZM11.8244 11.9639L11.2349 11.2219H12.2603V11.9639H11.8244Z"
      fill="url(#paint1_linear_636_9004)"
    />
    <defs>
      <linearGradient id="paint0_linear_636_9004" x1="0.366667" y1="0.574219" x2="21.7393" y2="22.3066" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D2A8" />
        <stop offset="0.970378" stopColor="#0693B8" />
      </linearGradient>
      <linearGradient id="paint1_linear_636_9004" x1="4.93828" y1="5.30273" x2="17.1223" y2="17.6919" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D2A8" />
        <stop offset="0.970378" stopColor="#0693B8" />
      </linearGradient>
    </defs>
  </svg>
);

export const NairaUndecided = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.324219" width="22" height="22" rx="4" fill="#FE9603" fillOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.604 11.3236C5.604 8.34519 8.02146 5.92773 10.9998 5.92773C13.9782 5.92773 16.3957 8.34519 16.3957 11.3236C16.3957 14.3019 13.9782 16.7194 10.9998 16.7194C8.02146 16.7194 5.604 14.3019 5.604 11.3236ZM10.9998 5.05273C7.53821 5.05273 4.729 7.86194 4.729 11.3236C4.729 14.7852 7.53821 17.5944 10.9998 17.5944C14.4615 17.5944 17.2707 14.7852 17.2707 11.3236C17.2707 7.86194 14.4615 5.05273 10.9998 5.05273ZM9.46619 9.00092C9.35596 9.00092 9.25396 9.0346 9.1764 9.11404C9.10376 9.18906 9.07377 9.28656 9.07377 9.38964V10.2378H9.05935C8.85608 10.2378 8.67697 10.3945 8.67697 10.6049C8.67697 10.8153 8.85608 10.9719 9.05935 10.9719H9.07377V11.7139H9.05935C8.85608 11.7139 8.67697 11.8705 8.67697 12.0809C8.67697 12.2913 8.85608 12.448 9.05935 12.448H9.07377V13.2579C9.07377 13.3614 9.1034 13.4578 9.17205 13.5351L9.17602 13.5392C9.25018 13.6158 9.34874 13.6466 9.45313 13.6466C9.55744 13.6466 9.65593 13.6158 9.73008 13.5394C9.80499 13.4625 9.83902 13.3647 9.83902 13.2579V12.448H11.4825L12.3146 13.493L12.3187 13.4977C12.4013 13.5918 12.5077 13.6466 12.6329 13.6466C12.7391 13.6466 12.8409 13.6172 12.9163 13.5394C12.9912 13.4625 13.0253 13.3647 13.0253 13.2579V12.4387C13.191 12.4022 13.3226 12.2613 13.3226 12.0809C13.3226 11.9005 13.191 11.7597 13.0253 11.7231V10.9627C13.191 10.9261 13.3226 10.7853 13.3226 10.6049C13.3226 10.4245 13.191 10.2836 13.0253 10.2471V9.38964C13.0253 9.2875 12.9962 9.19122 12.925 9.11649C12.8519 9.03543 12.7529 9.00092 12.6459 9.00092C12.5377 9.00092 12.4385 9.0364 12.3623 9.11443C12.2896 9.18945 12.26 9.28656 12.26 9.38964V10.2378H10.6515L9.79107 9.15464L9.78685 9.14983C9.70273 9.05393 9.59359 9.00092 9.46619 9.00092ZM9.83902 11.7139V10.9719H10.307L10.8979 11.7139L9.83902 11.7139ZM11.8242 11.7139L11.2347 10.9719H12.26V11.7139H11.8242Z"
      fill="#FE9603"
    />
  </svg>
);

export const NairaFailed = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="4" fill="#FF3739" fillOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.604 10.9993C5.604 8.02097 8.02146 5.60352 10.9998 5.60352C13.9782 5.60352 16.3957 8.02097 16.3957 10.9993C16.3957 13.9777 13.9782 16.3952 10.9998 16.3952C8.02146 16.3952 5.604 13.9777 5.604 10.9993ZM10.9998 4.72852C7.53821 4.72852 4.729 7.53772 4.729 10.9993C4.729 14.461 7.53821 17.2702 10.9998 17.2702C14.4615 17.2702 17.2707 14.461 17.2707 10.9993C17.2707 7.53772 14.4615 4.72852 10.9998 4.72852ZM9.46619 8.6767C9.35596 8.6767 9.25396 8.71038 9.1764 8.78982C9.10376 8.86484 9.07377 8.96234 9.07377 9.06542V9.91363H9.05935C8.85608 9.91363 8.67697 10.0703 8.67697 10.2807C8.67697 10.491 8.85608 10.6477 9.05935 10.6477H9.07377V11.3897H9.05935C8.85608 11.3897 8.67697 11.5463 8.67697 11.7567C8.67697 11.9671 8.85608 12.1237 9.05935 12.1237H9.07377V12.9336C9.07377 13.0372 9.1034 13.1335 9.17205 13.2109L9.17602 13.215C9.25018 13.2916 9.34874 13.3224 9.45313 13.3224C9.55744 13.3224 9.65593 13.2916 9.73008 13.2151C9.80499 13.1383 9.83902 13.0405 9.83902 12.9336V12.1237H11.4825L12.3146 13.1688L12.3187 13.1734C12.4013 13.2676 12.5077 13.3224 12.6329 13.3224C12.7391 13.3224 12.8409 13.293 12.9163 13.2152C12.9912 13.1383 13.0253 13.0405 13.0253 12.9336V12.1145C13.191 12.078 13.3226 11.9371 13.3226 11.7567C13.3226 11.5763 13.191 11.4354 13.0253 11.3989V10.6384C13.191 10.6019 13.3226 10.461 13.3226 10.2807C13.3226 10.1003 13.191 9.95938 13.0253 9.92288V9.06542C13.0253 8.96328 12.9962 8.867 12.925 8.79227C12.8519 8.71121 12.7529 8.6767 12.6459 8.6767C12.5377 8.6767 12.4385 8.71218 12.3623 8.79021C12.2896 8.86523 12.26 8.96234 12.26 9.06542V9.91363H10.6515L9.79107 8.83042L9.78685 8.82561C9.70273 8.72971 9.59359 8.6767 9.46619 8.6767ZM9.83902 11.3897V10.6477H10.307L10.8979 11.3897L9.83902 11.3897ZM11.8242 11.3897L11.2347 10.6477H12.26V11.3897H11.8242Z"
      fill="#FF3739"
    />
  </svg>
);

export const NairaPurple = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#003DC4" fillOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8335 12.0007C5.8335 8.59679 8.5963 5.83398 12.0002 5.83398C15.404 5.83398 18.1668 8.59679 18.1668 12.0007C18.1668 15.4045 15.404 18.1673 12.0002 18.1673C8.5963 18.1673 5.8335 15.4045 5.8335 12.0007ZM12.0002 4.83398C8.04402 4.83398 4.8335 8.04451 4.8335 12.0007C4.8335 15.9568 8.04402 19.1673 12.0002 19.1673C15.9563 19.1673 19.1668 15.9568 19.1668 12.0007C19.1668 8.04451 15.9563 4.83398 12.0002 4.83398ZM10.2474 9.34619C10.1214 9.34619 10.0049 9.38468 9.91623 9.47548C9.83322 9.56121 9.79894 9.67264 9.79894 9.79045V10.7598H9.78246C9.55016 10.7598 9.34546 10.9389 9.34546 11.1793C9.34546 11.4197 9.55016 11.5988 9.78246 11.5988H9.79894V12.4467H9.78246C9.55016 12.4467 9.34546 12.6258 9.34546 12.8662C9.34546 13.1066 9.55016 13.2857 9.78246 13.2857H9.79894V14.2113C9.79894 14.3296 9.83281 14.4397 9.91127 14.5281L9.9158 14.5328C10.0006 14.6203 10.1132 14.6555 10.2325 14.6555C10.3517 14.6555 10.4643 14.6204 10.549 14.533C10.6346 14.4452 10.6735 14.3334 10.6735 14.2113V13.2857H12.5518L13.5028 14.48L13.5075 14.4853C13.6018 14.5929 13.7234 14.6555 13.8665 14.6555C13.9879 14.6555 14.1042 14.6219 14.1904 14.533C14.276 14.4452 14.315 14.3334 14.315 14.2113V13.2751C14.5043 13.2334 14.6548 13.0724 14.6548 12.8662C14.6548 12.66 14.5043 12.499 14.315 12.4573V11.5882C14.5043 11.5465 14.6548 11.3854 14.6548 11.1793C14.6548 10.9731 14.5043 10.8121 14.315 10.7704V9.79045C14.315 9.67371 14.2817 9.56368 14.2003 9.47827C14.1168 9.38564 14.0036 9.34619 13.8814 9.34619C13.7578 9.34619 13.6443 9.38674 13.5572 9.47592C13.4742 9.56166 13.4404 9.67264 13.4404 9.79045V10.7598H11.602L10.6187 9.52187L10.6139 9.51638C10.5178 9.40678 10.393 9.34619 10.2474 9.34619ZM10.6735 12.4467V11.5988H11.2084L11.8837 12.4467L10.6735 12.4467ZM12.9422 12.4467L12.2685 11.5988H13.4404V12.4467H12.9422Z"
      fill="#003DC4"
    />
  </svg>
);
