import "chart.js/auto";
import { useState } from "react";

import { Line } from "react-chartjs-2";
import { Card, CardTitle } from "reactstrap";
import { ReactChangeEventType } from "../../../../types/common";
// import DateTypeFilter from "./filters/DateTypeFilter";

const VmrAacChart = () => {
  // const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  // const [endDate, setEndDate] = useState(getTodayDate());
  const [dateType, setDateType] = useState("all_time");
  const [status, setStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [toggled, setToggled] = useState(false);
  const [statusToggled, setStatusToggled] = useState(false);
  const handleToggle = () => {
    setToggled((prevStatus) => !prevStatus);
  };
  const handleDateTypeChange = (e: ReactChangeEventType) => setDateType(e.target.value);

  const handleStatusToggled = () => {
    setStatusToggled((prevStatus) => !prevStatus);
  };
  const handleStatusChange = (e: ReactChangeEventType) => setStatus(e.target.value);
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: "white",
        },
        beginAtZero: true,
      },
      y: {
        grid: {
          display: false,
          borderColor: "white",
        },
        ticks: {
          min: 0,
          max: 15,
          stepSize: 5,
        },
        beginAtZero: true,
        // steps: 4
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
  };
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Driver Payout",
        data: [50, 10, 20, 60, 50, 130, 110, 20, 60, 70, 100, 130],
        // fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        borderWidth: 1,
        borderColor: "#44affc",
        pointRadius: 0,
        // hoverBorderJoinStyle: "miter",
      },
      {
        label: "Partner Payout",
        data: [80, 30, 20, 90, 120, 50, 20, 60, 90, 130, 80, 40],
        // fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        borderWidth: 1,
        borderColor: "#05b4b0",
        pointRadius: 0,
        // hoverBorderJoinStyle: "miter",
      },
      {
        label: "Driver Payout",
        data: [30, 60, 20, 60, 40, 120, 100, 20, 60, 70, 110, 120],
        // fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        borderWidth: 1,
        borderColor: "#44affc",
        pointRadius: 0,
        // hoverBorderJoinStyle: "miter",
      },
    ],
  };
  return (
    <Card body>
      <CardTitle className=" justify-content-between">
        <div className="justify-content-between d-flex w-100">
          <div className="fw-bold">Awaiting Approval, Approved and  Completed - Monthly</div>
          <span>{/* <FilterOptions toggled={statusToggled} handleToggle={handleStatusToggled} options={statusesOptions} selectedOption={status} optionChange={handleStatusChange} name="status" /> */}</span>
        </div>
      </CardTitle>
      <div>
        <Line data={data} options={options} />
      </div>
    </Card>
  );
};

export default VmrAacChart;
