import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { Table } from 'reactstrap';
import EmptyPage from '../../../../../../components/EmptyPage';
import NewFilter from '../../../../../../components/filter/NewFilter';
import ExportComponent from '../../../../../../components/shared/exportComponent';
import FilterComponent from '../../../../../../components/shared/filterComponent';
import WaitingLoader from '../../../../../../components/shared/waitingLoader';
import PaginationComponent from '../../../../../../components/tablesComponents/paginationComponent';
import TableDataCounter from '../../../../../../components/tablesComponents/tableDataCounter';
import queryKeys from '../../../../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../../../../contexts/AppPrefrenceContext';
import { formatTime } from '../../../../../../helpers/dateTimeHelper';
import { usePaginationHelper } from '../../../../../../helpers/paginationHelper';
import { useQueryStringHelper } from '../../../../../../helpers/queryStringHelper';
import { useOemApiService } from '../../../../../../services/oemApiServices';

type Props = {
  setServiceId: Dispatch<SetStateAction<string>>;
  serviceId: string;
  vmrDetails: {
    [key: string]: any;
  };
};
const ServiceRequestLog = (props: Props) => {
  const { serviceId, setServiceId, vmrDetails } = props;
  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { getPageNoFromQueryString } = useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData(['VMR', `VMR's log`, `${vmrDetails?.plate_number}`]);
  }, [vmrDetails]);

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const { getVmrRequestToast, getVmrDetailsToast } = useOemApiService();

  const { isLoading, data: vmr } = useQuery(
    [queryKeys.vmr, currentPage, filterState, startDate, endDate],
    () =>
      getVmrRequestToast({
        page: currentPage,
        vehicle_id: serviceId,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data || [],
      keepPreviousData: true,
    }
  );

  const { data: vmrCount } = useQuery(
    [queryKeys.vmrCount, startDate, endDate, filterState],
    () =>
      getVmrRequestToast({
        vehicle_id: serviceId,
        start_date: startDate,
        end_date: endDate,
        component: 'count',
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
    }
  );

  const filterOptions: any = [];

  const onApply = () => {
    setFilterState({ startDate, endDate,});
    handleToggle();
  };

  if (isLoading && !vmr) {
    return <WaitingLoader />;
  }
  return (
    <>
      {!isLoading && (
        <>
          <div className='d-flex justify-content-between text-nowrap flex-wrap'>
            <div className='d-flex align-items-center'>
              <div className='fw-bold fs-5 d-flex align-items-center'>
                <BsArrowLeft
                  size={30}
                  className='cursor-pointer'
                  onClick={() => {
                    setServiceId('');
                  }}
                />
                <div className='ms-3'>Service Request History</div>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <div className='me-3'>
                {vmr?.length > 0 && <ExportComponent />}
              </div>
              <div className='me-3'>
                <NewFilter
                  direction={'start'}
                  toggleComponent={<FilterComponent />}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  options={filterOptions}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onApply={onApply}
                />
              </div>
            </div>
          </div>
          <div className='mt-2 '>
            {vmr?.length === 0 && <EmptyPage text='No Vmr' />}
            {vmr?.length > 0 && (
              <Table responsive className='table-hover'>
                <thead className=''>
                  <tr>
                    <th className=''>Date and Time</th>
                    <th className=''>Request Type</th>
                    <th className=''>Cov. By Warranty</th>
                    <th className=''>Dri Name</th>
                    <th className=''>Plate No.</th>
                    <th className=''>Urgency</th>
                    <th className=''>Status</th>
                    <th className=''></th>
                  </tr>
                </thead>
                <tbody className='capitalize'>
                  {vmr.map((item: any) => (
                    <tr
                      className='cursor-pointer'
                      key={item.request_id}
                      //   onClick={() => {
                      //     attachQueryStringToUrl('vmr-id', item.request_id);
                      //     setActiveId(item.request_id);
                      //     setMode(2);
                      //   }}
                    >
                      <td>{formatTime(item.createdAt).fullDateTime}</td>
                      <td>{item?.service_type || 'N|A'}</td>
                      <td>{item?.covered_by_warranty ? 'yes' : 'no'}</td>
                      <td>{item?.driver_name || 'N|A'}</td>
                      <td>{item?.plate_number || 'N|A'}</td>
                      <td>{item?.urgency || 'N|A'}</td>
                      <td
                        className={
                          item?.status === 'completed' ||
                          item?.status === 'ongoing'
                            ? 'fw-semibold text-custom-green'
                            : item?.status === 'pending'
                            ? 'fw-bold text-warning'
                            : item?.status === 'rejected'
                            ? 'fw-bold text-danger'
                            : item?.status === 'awaiting-approval' ||
                              item?.status === 'approved'
                            ? 'fw-bold text-deep-purple'
                            : item?.status === 'accepted'
                            ? 'fw-bold text-blue'
                            : 'fw-bold text-deep-blue'
                        }
                      >
                        {item?.status === 'completed'
                          ? 'Completed'
                          : item?.status === 'ongoing'
                          ? 'ongoing'
                          : item?.status === 'pending'
                          ? 'pending'
                          : item?.status === 'accepted'
                          ? 'accepted'
                          : item?.status === 'rejected'
                          ? 'rejected'
                          : item?.status === 'awaiting-approval'
                          ? 'awaiting-approval'
                          : item?.status === 'approved'
                          ? 'approved'
                          : 'operation time set'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {vmr?.length > 0 && (
              <div className='d-flex justify-content-between align-items-center mt-4 flex-wrap'>
                <div className='mt-2'>
                  <TableDataCounter
                    item={vmr}
                    currentPage={currentPage}
                    totalCount={vmrCount}
                  />
                </div>
                <div className='mt-2'>
                  <PaginationComponent
                    activePage={currentPage}
                    itemsCountPerPage={50}
                    totalItemsCount={vmrCount}
                    onChange={onPaginate}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ServiceRequestLog;
