import { useState } from 'react';
import { useNavigate } from 'react-router';
import CusCard from '../../../components/shared/cusCard';
import ReferralLog from './referralLog';
import ReferralOverview from './referralOverview';


const Referral = () => {
  const tabs = ['Overview', "Referral's Log",];
  const [referralType, setReferralType] = useState('total');
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate();

  return (
    <CusCard className='p-0' style={{ minHeight: '70vh' }}>
      <div className='d-flex'>
        {tabs.map((item) => (
          <div
            className={`px-3 bg-none cursor-pointer ${
              activeTab === item && 'gradient-btn text-white fw-bold'
            } ${item === 'Profile' && activeTab !== 'Profile' && 'd-none'}`}
            key={item}
            style={{ paddingTop: '11px', paddingBottom: '11px' }}
            onClick={() => {
              item !== 'Profile' && navigate('/referrals');
              setActiveTab(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className='p-3 p-sticky' style={{ top: 0 }}>
        {activeTab === 'Overview' && (
          <div>
            <ReferralOverview
              setActiveTab={setActiveTab}
              setReferralType={setReferralType}
            />
          </div>
        )}

        {activeTab === "Referral's Log" && (
          <ReferralLog
            setActiveTab={setActiveTab}
            activeTab={activeTab}
              setReferralType={setReferralType}
           referralType={referralType}
          />
        )}
       
      </div>
    </CusCard>
  );
};

export default Referral;
