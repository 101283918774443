import { Card, Col, Row } from 'reactstrap';
import {
  calculatePostDate
} from '../../../../helpers/dateTimeHelper';
import { ObjectType } from '../../../../types/common';

type Props = {
  emergencyDetails: ObjectType;
};

const Location = (props: Props) => {
  const { emergencyDetails } = props;

  return (
    <div>
      <Card body className='mt-3 p-2'>
        <div className='d-flex justify-content-between capitalize'>
          <div className='d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1'>
            <div className='flex-grow-1'>
              <div className=''>
                <div className='text-blue fw-semibold'>Location</div>
              </div>
              <Row className='w-100'>
                <Col className='mt-2' md={2}>
                  <div>
                    <small className='text-grey'>Pickup Location</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {emergencyDetails?.trip_data_all?.rider?.start_address}
                  </div>
                </Col>
                <Col className='mt-2' md={2}>
                  <div>
                    <small className='text-grey'>DropOff Location</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {emergencyDetails?.trip_data_all?.rider?.end_address}
                  </div>
                </Col>
                <Col className='mt-2' md={2}>
                  <div>
                    <small className='text-grey'>Latitude</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {emergencyDetails?.latitude}
                  </div>
                </Col>
                <Col className='mt-2 offset-md-1' md={2}>
                  <div>
                    <small className='text-grey'>Longitude</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {emergencyDetails?.longitude}
                  </div>
                </Col>
                <Col className='mt-2 offset-md-1' md={2}>
                  <div>
                    <small className='text-grey'>Date and Time</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {calculatePostDate(emergencyDetails?.createdAt)}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Location;
