import { IoMdSettings } from "react-icons/io";

export const setupLinks = {
  title: "Setup",
  icon: <IoMdSettings size={20} />,
  link: "setup",
  subNavs: [
    {
      title: "ID Verification",
      link: "/setup/id-verification",
    },
    {
      title: "App Setting",
      link: "/setup/app-setting",
    },

    {
      title: "Fees",
      link: "/setup/fees",
    },
    {
      title: "Areas",
      link: "/setup/areas",
    },
    {
      title: "Geo-Fencing",
      link: "/setup/geo-fencing",
    },
    {
      title: "Staff Admin",
      link: "/setup/staff-admin",
    },
    {
      title: "Roles",
      link: "/setup/roles",
    },
    {
      title: "OEMs",
      link: "/setup/oems",
    },
    {
      title: "Revenue split",
      link: "/setup/revenue-split",
    },
  ],
};
