import { io as IO, Socket } from "socket.io-client";
import { useContext, useRef } from "react";
import { AuthContext } from "../contexts/AuthContext";
// import { runSocketReply } from "../hooks/socket-reply";

let ioRef: Socket;
export const useSocket = () => {
  const { userData } = useContext(AuthContext);

  const urls = userData?.data_mode === "live" ? "https://lrapi-nginx.lagosride.com" : "https://staging-server.lagosride.com";
  const path = userData?.data_mode === "live" ? "/socket-engine" : "/socket-engine2";

  let socketReference = useRef<Socket | null>(null);
  const token = localStorage.getItem("access_token");

  //create the connection
  const createConnection = async () => {
    if (socketReference.current?.connected || !token) return;

    //create the connection required
    return new Promise((resolve, reject) => {
      const socketUrl = `${urls}?token=${token}&user_type=driver`;
      let socket = IO(socketUrl, {
        transports: ["websocket"],
        path,
      });
      //on connect listener
      socket.on("connect", () => {
        console.log("socket connected");
        socketReference.current = socket;
        resolve(true);
      });
      //on error listener
      socket.on("connect_error", (error) => {
        console.log("connect error...: ", error);
        resolve(false);
      });
      socket.on("disconnect", () => {
        console.log("socket disconnected");
        resolve(false);
      });
      //on message listener
      //   socket.on("msg-driver", (payload) => {
      //     runSocketReply(payload);
      //   });
    });
  };

  const sendSocketRequest = (payload: any) => {
    //if still connected, send the request
    if (socketReference.current && socketReference.current?.connected === true) {
      socketReference.current.emit("msg-driver", payload);
    }
  };

  return { createConnection, sendSocketRequest, socketReference };
};
