import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Row, Table } from 'reactstrap';
import { AppPrefrenceContext } from '../../../../../contexts/AppPrefrenceContext';
import { useQueryStringHelper } from '../../../../../helpers/queryStringHelper';
import FilterOptions from '../../../../../components/filter/Filter';
import ExportComponent from '../../../../../components/shared/exportComponent';
import CusCard from '../../../../../components/shared/cusCard';

const DriverWatchlistTable = () => {
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const [dateType, setDateType] = useState('This week');
  const [toggled, setToggled] = useState(false);
  const handleToggle = () => {
    setToggled(!toggled);
  };
  const handleDateTypeChange = (e: any) => setDateType(e.target.value);
  const dateTypeOption = [
    { value: 'This week', label: 'This week' },
    { value: 'This month', label: 'This month' },
    { value: 'Last 6 months', label: 'Last 6 months' },
  ];
  useEffect(() => {
    setBreadCrumbData(['Operation', 'Driver Watchlist']);
  }, []);
  const { getIdFromQueryString } = useQueryStringHelper();
  const tabs = ['Driver Watchlist'];
  const [driverType, setDriverType] = useState('total');
  const [activeId, setActiveId] = useState('');
  const [activeTab, setActiveTab] = useState(
    getIdFromQueryString('user-id') ? 'Profile' : tabs[0]
  );
  const navigate = useNavigate();

  return (
    <div>
      <div className='p-3'>
        <div className='d-flex justify-content-between text-nowrap flex-wrap'>
          <div className='d-flex align-items-center'>
            <div className=''>
              <div className='d-flex align-items-center  '>
                <div className='fw-bolder fs-5'>{`Driver Watchlist`}</div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <div className='me-3'>
              <ExportComponent />
            </div>
            <FilterOptions
              toggled={toggled}
              handleToggle={handleToggle}
              options={dateTypeOption}
              selectedOption={dateType}
              optionChange={handleDateTypeChange}
              name='projection'
            />
            {/* <div className="me-3">
              <NewFilter
                direction={"start"}
                toggleComponent={<FilterComponent />}
                toggled={toggled}
                handleToggle={handleToggle}
                options={filterOptions}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                onApply={onApply}
              />
            </div> */}
          </div>
        </div>
        <div className='mt-2'>
          {/* {tripPayment?.length === 0 && <EmptyPage text="No Trip Payments" />}
      {tripPayment?.length > 0 && ( */}
          <Table responsive className='table-hover'>
            <thead className=''>
              <tr>
                <th className=''>Name</th>
                <th className=''>Phone Number</th>
                <th className=''> Service Perf. </th>
                <th className=''> Compliance Perf. </th>
                <th className=''> Overall Perf. </th>
                <th className=''> Leader </th>
              </tr>
            </thead>

            <tbody>
              {Array.from({ length: 7 }).map((item: any, index: number) => (
                <tr className='cursor-pointer'>
                  {/* <td>{calculatePostDate(item.createdAt)}</td> */}
                  <td className=''>Tayo Akanji</td>
                  <td className=''>08164034289</td>
                  <td className=''>12%</td>
                  <td className=''>13%</td>
                  <td className=''>25%</td>
                  <td className='text-danger'>200th</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* )} */}
          {/* {tripPayment?.length > 0 && (
        <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
          <div className="mt-2">
            <TableDataCounter item={tripPayment} currentPage={currentPage} totalCount={riderTripPaymentCount} />
          </div>
          <div className="mt-2">
            <PaginationComponent activePage={currentPage} itemsCountPerPage={20} totalItemsCount={riderTripPaymentCount} onChange={onPaginate} />
          </div>
        </div>
      )} */}
        </div>
      </div>
    </div>
  );
};

export default DriverWatchlistTable;
