import { createContext, useState } from 'react';

export const AppPrefrenceContext = createContext<any>({});

const AppPrefrenceContextProvider = ({ children }: any) => {
  const [expanded, setExpanded] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [actionButton, setActionButton] = useState('');
  const [deleteModalTexts, setDeleteModalTexts] = useState({
    first: '',
    second: '',
  });
  const [deleteCallBack, setDeleteCallBack] = useState();
  const [breadcrumbData, setBreadCrumbData] = useState(null);

  const [type, setType] = useState('');

  const deleteModalConfig = ({
    firstText = '',
    secondText = '',
    type = '',
    actionButton = '',
    callback,
  }: any) => {
    setDeleteModalOpen((prev) => !prev);
    setActionButton(actionButton);
    setDeleteModalTexts({ first: firstText, second: secondText });
    setDeleteCallBack(() => callback);
    setType(type);
  };
  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <AppPrefrenceContext.Provider
      value={{
        expanded,
        setExpanded,
        toggle,
        deleteModalOpen,
        deleteModalConfig,
        deleteModalTexts,
        deleteCallBack,
        setDeleteModalOpen,
        breadcrumbData,
        setBreadCrumbData,
        type,
        actionButton,
      }}
    >
      {children}
    </AppPrefrenceContext.Provider>
  );
};

export default AppPrefrenceContextProvider;
