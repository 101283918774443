import { useMutation, useQueryClient } from 'react-query';
import { FormGroup, Input, Label } from 'reactstrap';
import GradientButton from '../../../../components/shared/gradidentButton';
import queryKeys from '../../../../constants/queryKeys';
import { useEmergencyApiService } from '../../../../services/emergencyApiServices';
import { ObjectType, ReactSubmitEventType } from '../../../../types/common';

type Props = {
  handleToggle?: VoidFunction;
  handleModalToggle?: VoidFunction;
  handleChange: (e: any) => void;
  editingId?: string;
  type: string;
  data?: ObjectType;
};

const EmergencyModalBody = ({
  handleToggle,
  handleChange,
  // handleModalToggle,
  data,
  // editingId,
  type,
}: Props) => {

  const { email, phone }: ObjectType = data || {};

  const { createEmergencyContactToast } =
    useEmergencyApiService();
  const queryClient = useQueryClient();

  const createEmergencyMutation = useMutation(createEmergencyContactToast, {
    onSuccess: async (res) => {
      if (res.data.status !== 'error') {
        handleToggle && handleToggle();
        queryClient.invalidateQueries(queryKeys.emergencyContact);
        queryClient.invalidateQueries(queryKeys.emergencyContactCount);
      }
    },
    // onError: (err) => {},
  });

  const handleSubmit = (e: ReactSubmitEventType) => {
    e.preventDefault();
    createEmergencyMutation.mutate({
      contact: type === 'email' ? email : phone,
      type: type,
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {type !== 'phone_number' && (
          <FormGroup className='bg-white mt-3'>
            <Label for='email'>Email</Label>

            <Input
              id='email'
              name='email'
              type='email'
              className='bg-transparent'
              rows={5}
              value={email}
              onChange={handleChange}
            />
          </FormGroup>
        )}
        {type === 'phone_number' && (
          <FormGroup className='bg-white mt-3'>
            <Label for='phone'>Phone Number</Label>

            <Input
              id='phone'
              name='phone'
              type='text'
              className='bg-transparent'
              rows={5}
              value={phone}
              onChange={handleChange}
            />
          </FormGroup>
        )}
        <div style={{ width: 120, marginTop: 40 }}>
          <GradientButton
            className='py-2'
            text={type === 'email' ? 'Add email ' : 'Add Number'}
          />
        </div>
      </form>
    </div>
  );
};

export default EmergencyModalBody;
