import { useState } from 'react';
import { useQuery } from 'react-query';
import { Card, CardTitle } from 'reactstrap';
import NewFilter from '../../../../components/filter/NewFilter';
import ExportComponent from '../../../../components/shared/exportComponent';
import FilterComponent from '../../../../components/shared/filterComponent';
import queryKeys from '../../../../constants/queryKeys';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../../../helpers/dateTimeHelper';
import { useTripsApiService } from '../../../../services/tripsApiService';

const UniqueFeatures = () => {
  const { getTripsToast } = useTripsApiService();

  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const [toggled, setToggled] = useState(false);
  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });
  const handleToggle = () => {
    setToggled(!toggled);
  };

  const onApply = () => {
    setFilterState({ startDate, endDate });
    handleToggle();
  };

  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: driverTripsFeatures,
  } = useQuery(
    [queryKeys.tripsUniqueFeatures, filterState],
    () =>
      getTripsToast({
        component: 'count-feature',
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data,
    }
  );

  const getPercentValue = (value: number) =>
    (value / (driverTripsFeatures?.total || 0)) * 100;

  const ratingsStat = [
    {
      label: 'Business',
      progress: `${getPercentValue(driverTripsFeatures?.total_biz || 0)}%`,
      figure: driverTripsFeatures?.total_biz || 0,
    },
    {
      label: 'Schedules',
      progress: `${getPercentValue(driverTripsFeatures?.total_schedule || 0)}%`,
      figure: driverTripsFeatures?.total_schedule || 0,
    },
    {
      label: 'On Demand',
      progress: `${getPercentValue(driverTripsFeatures?.total_regular || 0)}%`,
      figure: driverTripsFeatures?.total_regular || 0,
    },
  ];

  return (
    <Card body className='d-flex'>
      <CardTitle className=' justify-content-between'>
        <div className='justify-content-between d-flex w-100'>
          {/* <h6 className="fw-bolder">Unique Feature</h6> */}
          <div></div>
          <div className='d-flex'>
            <NewFilter
              direction={'start'}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />
            <ExportComponent />
          </div>
        </div>
        <div className='justify-content-between d-flex w-100 mt-1'>
          <h3 className='fw-bolder'>
            {(driverTripsFeatures?.total || 0).toLocaleString()} trips
          </h3>
        </div>
      </CardTitle>

      <div className='mt-2 mb-1'>
        {ratingsStat.map((item) => (
          <div key={item.label} className='mb-1'>
            <div className='text-nowrap mb-1'>{item.label}</div>
            <div className='d-flex mb-3'>
              <div
                className='progress '
                style={{
                  height: '25px',
                  width: '85%',
                  backgroundColor: '#f5fbfb',
                }}
              >
                <div
                  className='progress-bar'
                  role='progressbar'
                  aria-label='Basic example'
                  style={{ width: item.progress, backgroundColor: '#04A7A7' }}
                  aria-valuenow={parseInt(item.progress, 10)}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              <div
                className='d-flex justify-content-around fw-bold'
                style={{ width: '15%' }}
              >
                <div className='flex-grow-1 text-end'>
                  {' '}
                  {item.figure.toLocaleString()}
                </div>
                {/* <div className="flex-grow-1 text-end"> {item.progress}</div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default UniqueFeatures;
