import { IoMdClose } from "react-icons/io";
import { GrFormClose } from "react-icons/gr";
import { Button } from "reactstrap";
import { CgClose } from "react-icons/cg";

type Props = {
  text: string;
  onClick?: any;
  forwardArrow?: boolean;
  disabled?: boolean;
  size?: string;
  className?: string;
  cancelIcon?: boolean;
};

const RedButton = (props: Props) => {
  const { text, onClick, disabled, cancelIcon,className } = props;
  return (
    // <Button
    //   size={"sm"}
    //   className={`w-100  border-0 font-krub-semibold text-nowrap rounded fw-bold text-danger py-2 `}
    //   onClick={onClick}
    //   // disabled={disabled}
    //   style={{ padding: "13px 18px", fontSize: 16, backgroundColor: "#ffebeb" }}
    // >
    //   {text}
    // </Button>

    <Button size={"sm"} className={`w-100  border-0 font-krub-semibold text-nowrap rounded fw-bold text-danger ${className && className}`} onClick={onClick} disabled={disabled} style={{ padding: "13px 18px", fontSize: 14, backgroundColor: "#ffebeb" }}>
      {cancelIcon && (
        <span>
          <CgClose className="me-1 mb-1" size={20} />
        </span>
      )}{" "}
      {text}
    </Button>
  );
};

export default RedButton;
