import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { ObjectType } from '../../../../../../types/common';

type Props = {
  vmrDetails: {
    [key: string]: any;
  };
};

const VehicleInfo = (props: Props) => {
  const { vmrDetails } = props;
  const [data, setData] = useState<ObjectType>([]);
  useEffect(() => {
    setData({
      phoneNumber: vmrDetails?.phone_number,
      emailAddress: vmrDetails?.email,
    });
  }, [vmrDetails]);

  return (
    <div>
      <Card body className=' p-2'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1'>
            <div className='flex-grow-1'>
              <div className=''>
                <div className='text-blue fw-semibold'>Vehicle</div>
              </div>
              <Row className='w-100 mt-2'>
                <Col className='mt-2'>
                  <div>
                    <small className='text-grey'>Mileage (Km)</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {vmrDetails?.mileage_raised?.toLocaleString()}
                  </div>
                </Col>
                <Col className='mt-2'>
                  <div>
                    <small className='text-grey'>Measure Taken</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {
                      vmrDetails?.diagnostics_data?.diagnostics_act
                    }
                  </div>
                </Col>
                <Col className='mt-2'>
                  <div>
                    <small className='text-grey'>
                      Preferred Service Center
                    </small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {vmrDetails?.prefered_center_name}
                  </div>
                </Col>
                <Col className='mt-2'>
                  <div>
                    <small className='text-grey'>Service Center Assigned</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {vmrDetails?.service_center_name}
                  </div>
                </Col>

                <Col className='mt-2'>
                  <div>
                    <small className='text-grey'>No. of Service</small>
                  </div>
                  <div className='mt-1 fw-semibold'>
                    {vmrDetails?.car_number}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default VehicleInfo;
