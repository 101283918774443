import { Dispatch, SetStateAction, useState } from 'react';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { MdArrowForwardIos } from 'react-icons/md';
import { TiLocation } from 'react-icons/ti';
import { useQuery } from 'react-query';
import { Card, CardTitle, Modal, ModalBody } from 'reactstrap';
import NewFilter from '../../../../components/filter/NewFilter';
import ExportComponent from '../../../../components/shared/exportComponent';
import FilterComponent from '../../../../components/shared/filterComponent';
import queryKeys from '../../../../constants/queryKeys';
import { useTripsApiService } from '../../../../services/tripsApiService';
import { ObjectType } from '../../../../types/common';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../../../helpers/dateTimeHelper';
type Props = {
  setTripsType: Dispatch<SetStateAction<string>>;
  setTripsStatus: Dispatch<SetStateAction<string>>;
  setActiveTab: Dispatch<SetStateAction<string>>;
  setLocation: Dispatch<SetStateAction<{ area: string; lga: string }>>;
  location: { area: string; lga: string };
};
const TripSegmentByLga = (props: Props) => {
  const { setTripsType, setTripsStatus, setActiveTab, setLocation, location } =
    props;
  const { getTripsToast } = useTripsApiService();
  const [order, setOrder] = useState('Descending');
  const [mode, setMode] = useState('LGA');
  const [modalOpen, setModalOpen] = useState(false);
  const [breakdownModal, setBreakdownModal] = useState(false);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const [toggled, setToggled] = useState(false);
  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });

  const handleToggle = () => {
    setToggled(!toggled);
  };
  const onApply = () => {
    setFilterState({ startDate, endDate });
    handleToggle();
  };

  const { data: driverTripsFeatures } = useQuery(
    [queryKeys.tripsLga, filterState],
    () =>
      getTripsToast({
        component: 'count-lga',
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data,
    }
  );

  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: tripsBreakdownByLocation,
    refetch: fetchTripsBreakdownByLocation,
  } = useQuery(
    [queryKeys.userTripsSummaryByLocation, filterState, location],
    () =>
      getTripsToast({
        component: 'count-status',
        start_date: startDate,
        end_date: endDate,
        request_lga: location.lga,
        request_area: location.area,
      }),
    {
      select: (data) => data?.data,
      // enabled: false,
    }
  );

  const tripsBreakdown = [
    {
      title: 'Total Trips',
      value: Math.floor(
        tripsBreakdownByLocation?.total_count || 0
      ).toLocaleString(),
      iconCardColor: '#d5e7f7',
    },
    {
      title: 'Completed',
      value: Math.floor(
        tripsBreakdownByLocation?.completed_count || 0
      ).toLocaleString(),
      tag: 'completed',
      tagType: 'status',
    },
    {
      title: 'Driver not found',
      value: Math.floor(
        tripsBreakdownByLocation?.driver_not_found_count || 0
      ).toLocaleString(),
      tag: 'driver-not-found',
      tagType: 'type',
    },
    {
      title: 'Canceled By Riders',
      value: Math.floor(
        tripsBreakdownByLocation?.rider_cancel_count || 0
      ).toLocaleString(),
      tag: 'cancel-rider',
      tagType: 'status',
    },
    {
      title: 'Canceled By Drivers',
      value: Math.floor(
        tripsBreakdownByLocation?.driver_cancel_count || 0
      ).toLocaleString(),
      tag: 'cancel-driver',
      tagType: 'status',
    },
  ];

  const lgas = driverTripsFeatures?.map((item: ObjectType) => ({
    name: item?.lga,
    number: item?.data?.reduce(
      (partialSum: number, a: ObjectType) => partialSum + a?.total,
      0
    ),
  }));

  const areas = driverTripsFeatures
    ?.map((item: ObjectType) => {
      return item?.data?.map((innerItem: ObjectType) => ({
        name: innerItem?.area_name,
        number: innerItem?.total,
      }));
    })
    .flat();

  const readData = mode === 'LGA' ? lgas : areas;

  const updateMode = (mode: string) => {
    setMode(mode);
    mode === 'LGA' ? setLocation({ area: '', lga: '' }) : { area: '', lga: '' };
  };
  
  const filterOptions: [] = [];


  const UiComponent = ({ modal }: { modal: boolean }) => {
    const truncated = modal ? readData : readData?.slice(0, 5);
    return (
      <div className='d-flex flex-column h-100'>
        <CardTitle className='justify-content-between'>
          <div className='justify-content-between d-flex w-100'>
            <div>
              <div className='fw-bold'>Trips by LGA/Area</div>
              <div className='fw-normal mt-1 d-flex align-items-center'>
                <div>
                  <small
                    className='cursor-pointer'
                    onClick={() =>
                      setOrder(
                        order === 'Descending' ? 'Ascending' : 'Descending'
                      )
                    }
                  >
                    <span>
                      <CgArrowsExchangeAltV
                        color='rgba(0, 208, 168, 0.8)'
                        size={17}
                      />
                    </span>
                    <span>{order}</span>
                  </small>
                </div>
                <div className='ms-4 d-flex fw-bold'>
                  <small
                    className={`p-1 px-2  ${
                      mode === 'LGA' && 'gradient-btn text-white'
                    }`}
                    style={{
                      fontSize: 12,
                      backgroundColor: 'rgba(245, 251, 253, 1)',
                      borderRadius: 4,
                    }}
                    onClick={() => updateMode('LGA')}
                  >
                    LGA
                  </small>
                  <small
                    className={`ms-2 p-1 px-2  ${
                      mode === 'Area' && 'gradient-btn text-white'
                    }`}
                    style={{
                      fontSize: 12,
                      backgroundColor: 'rgba(245, 251, 253, 1)',
                      borderRadius: 4,
                    }}
                    onClick={() => updateMode('Area')}
                  >
                    Area
                  </small>
                </div>
              </div>
            </div>
            <div className='d-flex'>
              {!modal && (
                <NewFilter
                  direction={'start'}
                  toggleComponent={<FilterComponent />}
                  toggled={toggled}
                  handleToggle={handleToggle}
                  startDate={startDate}
                  endDate={endDate}
              options={filterOptions}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onApply={onApply}
                />
              )}
              <ExportComponent />
            </div>
          </div>
          <div className='justify-content-between d-flex w-100'></div>
        </CardTitle>

        {/* <div className={`${!modal && "chart-container"} d-flex flex-column justify-content-between`}> */}
        <div
          className={`flex-grow-1 d-flex flex-column justify-content-between`}
        >
          <div>
            {truncated?.map((item: ObjectType) => (
              <div
                key={item?.name}
                className='d-flex align-items-center justify-content-between fw-medium'
                style={{ marginTop: 10 }}
                onClick={() => {
                  setBreakdownModal(true);
                  mode === 'LGA'
                    ? setLocation({ area: '', lga: item.name })
                    : setLocation({ area: item.name, lga: '' });
                  fetchTripsBreakdownByLocation();
                }}
              >
                <div>
                  <TiLocation color='rgba(34, 87, 101, 1)' />
                  <small className='ms-3'>{item.name.toUpperCase()}</small>
                </div>
                <div>
                  <small className='ms-3'>{item.number.toLocaleString()}</small>
                  <span className='ms-1'>
                    <MdArrowForwardIos
                      color='rgba(113, 147, 156, 1)'
                      size={8}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div>
            <div className='d-flex justify-content-between fw-bold mt-2 px-1'>
              <small>Total</small>
              <small>
                {readData
                  ?.reduce(
                    (partialSum: number, a: ObjectType) =>
                      partialSum + a?.number,
                    0
                  )
                  .toLocaleString()}
              </small>
            </div>
            {readData?.length > 5 && (
              <div
                className='my-1 px-1 rounded d-inline-block px-2 py-1 fw-bold cursor-pointer'
                style={{ backgroundColor: 'rgba(245, 251, 253, 1)' }}
              >
                <small onClick={() => setModalOpen((prev) => !prev)}>
                  {modal ? 'See less' : 'See more'}
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card className='h-100' body>
        <UiComponent modal={false} />
      </Card>
      <Modal
        body
        className='p-5'
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        backdrop='static'
      >
        <ModalBody>
          <UiComponent modal={true} />
        </ModalBody>
      </Modal>
      <Modal
        body
        isOpen={breakdownModal}
        toggle={() => setBreakdownModal(false)}
        size='md'
        centered
      >
        <ModalBody>
          <div className='d-flex justify-content-between'>
            <div
              onClick={() => setBreakdownModal(false)}
              className='fw-semibold text-capitalize'
            >
              Trip Segment In {location.area || location.lga + ' LGA'}
            </div>
            <ExportComponent />
          </div>

          <div className='mt-3'>
            {tripsBreakdown.map((item) => (
              <div
                key={item.title}
                className='mb-3 d-flex justify-content-between border px-3 py-2 rounded cursor-pointer'
                onClick={() => {
                  if (item.title === 'Total Trips') {
                    setTripsStatus('');
                    setTripsType('');
                  } else if (item.tagType === 'status') {
                    setTripsStatus(item.tag);
                    setTripsType('');
                  } else if (item.tagType === 'type') {
                    setTripsStatus('');
                    setTripsType(item.tag);
                  }
                  // i am exempting "driver not found" because it shouldn't be part of the trips log.. so it shouldnt link to the log.
                  // item.title !== "Driver not found" &&
                  setActiveTab("Trip's Log");
                }}
              >
                <div>{item.title}</div>

                <div>
                  <small className='ms-3'>{item.value}</small>
                  <span className='ms-1'>
                    <MdArrowForwardIos
                      className='ms-1'
                      color='rgba(113, 147, 156, 1)'
                      size={8}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TripSegmentByLga;
