import { SetStateAction } from "react";
import { FaUser } from "react-icons/fa";
import { MdOutlineChevronRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { ObjectType } from "../../../../../../../../types/common";

type Props = {
  viewedTrip?: { [key: string]: any } | null;
  focusedUser: ObjectType | null;
  setfocusedUser: React.Dispatch<SetStateAction<ObjectType | null>>;
};

const TripRiderDetails = (props: Props) => {
  const { viewedTrip, focusedUser, setfocusedUser } = props;
  return (
    <div>
      {viewedTrip?.riders?.map((item: ObjectType, index: number) => (
        <Card body className=" p-2 mt-3 cursor-pointer" onClick={() => setfocusedUser(item.rider_id)}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center px-3 pb-3 mt-1 flex-grow-1">
              <div className="flex-grow-1">
                {/* <div className="text-blue fw-semibold">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="26" height="26" rx="5" fill="#F5FBFD" />
                    <path
                      d="M11.2502 7.16699C9.72189 7.16699 8.47939 8.40949 8.47939 9.93783C8.47939 11.437 9.65189 12.6503 11.1802 12.7028C11.2269 12.697 11.2736 12.697 11.3086 12.7028H11.3494C12.0665 12.6789 12.7462 12.377 13.2448 11.861C13.7434 11.345 14.0218 10.6553 14.0211 9.93783C14.0211 8.40949 12.7786 7.16699 11.2502 7.16699ZM14.2136 14.2539C12.5861 13.1689 9.93189 13.1689 8.29272 14.2539C7.55189 14.7497 7.14355 15.4206 7.14355 16.1381C7.14355 16.8556 7.55189 17.5206 8.28689 18.0106C9.10355 18.5589 10.1769 18.8331 11.2502 18.8331C12.3236 18.8331 13.3969 18.5589 14.2136 18.0106C14.9486 17.5147 15.3569 16.8497 15.3569 16.1264C15.3511 15.4089 14.9486 14.7439 14.2136 14.2539ZM17.6611 10.2808C17.7544 11.4125 16.9494 12.4042 15.8352 12.5383H15.8061C15.7711 12.5383 15.7361 12.5383 15.7069 12.55C15.1411 12.5792 14.6219 12.3983 14.2311 12.0658C14.8319 11.5292 15.1761 10.7242 15.1061 9.84916C15.066 9.39416 14.9115 8.95669 14.6569 8.57749C14.9625 8.42879 15.2998 8.35668 15.6395 8.36739C15.9793 8.37809 16.3113 8.47129 16.607 8.63895C16.9027 8.8066 17.1532 9.04367 17.3368 9.32972C17.5204 9.61576 17.6317 9.94218 17.6611 10.2808Z"
                      fill="#2FB5D9"
                    />
                    <path/
                      d="M18.8263 15.6771C18.7797 16.2429 18.418 16.7329 17.8113 17.0654C17.228 17.3862 16.493 17.5379 15.7638 17.5204C16.1838 17.1412 16.4288 16.6687 16.4755 16.1671C16.5338 15.4437 16.1897 14.7496 15.5013 14.1954C15.1105 13.8862 14.6555 13.6412 14.1597 13.4604C15.4488 13.0871 17.0705 13.3379 18.068 14.1429C18.6047 14.5746 18.8788 15.1171 18.8263 15.6771Z"
                      fill="#2FB5D9"
                    />
                  </svg>
                  <span className="ms-1 mt-1">Riders Details</span>
                </div> */}

                <Row className={`w-100 mb-2 position-relative ${item.rider_id === focusedUser && "py-2"}`}>
                  <Col className="mt-2" md={2}>
                    <div>
                      <small className="text-grey">Rider Name</small>
                    </div>
                    <div className="mt-1 fw-semibold d-flex align-items-center">
                      <div className="rounded-circle bg-secondary overflow-hidden me-2 d-flex align-items-center justify-content-center" style={{ width: 20, height: 20 }}>
                        {item?.avatar ? <img src={item?.avatar} alt="avatar" /> : <FaUser color="whitesmoke" />}
                      </div>
                      <div>
                        <Link to={`/user/riders?user-id=${item.rider_id}`}>{item?.name}</Link>
                      </div>
                    </div>
                  </Col>
                  <Col className="mt-2 offset-md-1" md={2}>
                    <div>
                      <small className="text-grey">Rider Number</small>
                    </div>
                    <div className="mt-1 fw-semibold">{item?.phone_number}</div>
                  </Col>
                  <div className="d-inline position-absolute" style={{ right: 0, top: "45%", width: 20 }}>
                    <MdOutlineChevronRight size={25} className="text-success" />
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default TripRiderDetails;
