import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Table } from "reactstrap";
import EmptyPage from "../../../components/EmptyPage";
import GradientButton from "../../../components/shared/gradidentButton";
import WaitingLoader from "../../../components/shared/waitingLoader";
import PaginationComponent from "../../../components/tablesComponents/paginationComponent";
import TableDataCounter from "../../../components/tablesComponents/tableDataCounter";
import queryKeys from "../../../constants/queryKeys";
import { AppPrefrenceContext } from "../../../contexts/AppPrefrenceContext";
import { calculatePostDay, formatTime } from "../../../helpers/dateTimeHelper";
import { usePaginationHelper } from "../../../helpers/paginationHelper";
import { useQueryStringHelper } from "../../../helpers/queryStringHelper";
import { useMessagesApiService } from "../../../services/messagesApiServicr";
import { promoCodeType } from "../old-finance/revenue/data";

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setPromoType: Dispatch<SetStateAction<string>>;
  activeTab: string;
  promoType: string;
};
const NotiificationLog = (props: Props) => {
  const { activeTab, setActiveTab, setPromoType, promoType } = props;
  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [codeType, setCodeType] = useState("");
  const { getPageNoFromQueryString, getQueryStringValue } = useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const { getNotificationsToast } = useMessagesApiService();
  const { setBreadCrumbData, deleteModalConfig } = useContext(AppPrefrenceContext);

  useEffect(() => {
    setBreadCrumbData(["Notification", `${activeTab}`]);
  }, [,]);

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
    codeType,
  });

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const { isLoading, data: notifications } = useQuery(
    [queryKeys.notifications, codeType, filterState, startDate, endDate],
    () =>
      getNotificationsToast({
        component: "",
        start_date: startDate,
        end_date: endDate,
        page: currentPage,
      }),
    {
      select: (data) => data?.data || [],
      keepPreviousData: true,
    }
  );
  const { data: notificationsCount } = useQuery(
    [queryKeys.promoCount, codeType, filterState, startDate, endDate],
    () =>
      getNotificationsToast({
        component: "count",
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data?.total || 0,
      // enabled: false,
      keepPreviousData: true,
    }
  );

  const filterOptions = [
    {
      title: "Promo Type",
      options: promoCodeType,
      currentValue: codeType,
      onChange: setCodeType,
      name: "promoType",
    },
  ];

  if (isLoading && !notifications) {
    return <WaitingLoader />;
  }

  return (
    <>
      <div className="d-flex justify-content-between text-nowrap flex-wrap">
        <div className="d-flex align-items-center">
          <div className="">
            <div className="d-flex align-items-center  ">
              <div className="fw-bolder fs-5 capitalize">{activeTab}</div>
            </div>
          </div>
          {/* <div className="ms-4">{<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}</div> */}
        </div>
        <div className="d-flex align-items-center">
          {/* <div className='me-3'>{promo?.length > 0 && <ExportComponent />}</div> */}
          {/* 
          <div className='me-3'>
            <NewFilter
              direction={'start'}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              options={filterOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />
          </div> */}
          <div style={{ width: 150 }}>
            <GradientButton text="Send Notification" className="p-2" onClick={() => setActiveTab("Overview")} />
          </div>
        </div>
      </div>

      <div className="mt-2">
        {notifications?.length === 0 && <EmptyPage text="No Promo" />}
        {notifications?.length > 0 && (
          <Table responsive className="table-hover">
            <thead className="">
              <tr>
                <th className="">Date and Time</th>
                <th className="">Title</th>
                <th className="">Selected Recipient</th>
              </tr>
            </thead>

            <tbody>
              {notifications?.map((item: any, index: number) => (
                <Fragment key={item?._id}>
                  <tr className="cursor-pointer">
                    <td>{formatTime(item?.createdAt).fullDateTime}</td>
                    <td>{item.subject}</td>
                    <td>
                      {item?.user_data.length < 1 ? (
                        <span className="text-capitalize">{item.recipient}</span>
                      ) : item?.user_data.length === 1 ? (
                        `${item?.user_data[0]?.first_name} ${item?.user_data[0]?.last_name}`
                      ) : (
                        <div>
                          {item?.user_data[0]?.first_name} {item?.user_data[0]?.last_name} and <span className="fw-bold">{item?.user_data.length - 1} others</span>
                        </div>
                      )}
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        )}
        {notifications?.length > 0 && (
          <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
            <div className="mt-2">
              <TableDataCounter item={notifications} currentPage={currentPage} totalCount={notificationsCount} />
            </div>
            <div className="mt-2">
              <PaginationComponent activePage={currentPage} itemsCountPerPage={50} totalItemsCount={notificationsCount} onChange={onPaginate} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NotiificationLog;
