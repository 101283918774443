import { Dispatch, SetStateAction } from 'react';
import { FaCarAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import queryKeys from '../../../../constants/queryKeys';
import { useOemApiService } from '../../../../services/oemApiServices';
import { getTodayDate } from '../../../../helpers/dateTimeHelper';

type Props = {
  setVmrType: Dispatch<SetStateAction<string>>;
  setActiveTab: Dispatch<SetStateAction<string>>;
  mode?: string;
};

const OverviewSumaryCards = (props: Props) => {
  const { setVmrType, setActiveTab, mode } = props;
  const { getVmrRequestToast } = useOemApiService();

  const serviceTypes = ['', 'maintenance', 'repair', 'defect'];

  const queries = serviceTypes.map((serviceType) => {
    const queryKey = [queryKeys.vmrCount, serviceType];

    return {
      tag: serviceType,
      query: useQuery(
        queryKey,
        () =>
          getVmrRequestToast({
            component: 'count',
            service_type: serviceType,
            // status: status,
          }),
        {
          select: (data) => data?.data || [],
        }
      ),
    };
  });

  const { data: vmrCount } = useQuery(
    [queryKeys.vmrCount],
    () =>
      getVmrRequestToast({
        component: 'count-status',
        start_date: '2022-05-01',
        end_date: getTodayDate(),
      }),
    {
      select: (data) => data?.data[0] || [],
    }
  );

  const vmrReqValue = (tag: string) =>
    queries?.find((q) => q.tag === tag)?.query.data?.total || 0;

  const items =
    mode === 'status'
      ? [
          {
            title: 'Pending',
            value: (vmrCount?.total_pending || 0).toLocaleString(),
            cardBgColor: '#D0A30016',
            icon: <FaCarAlt color='#FE9603' />,
            iconCardColor: '#FDF0C1',
            caretColor: '#FE9603',
            tag: 'pending',
          },
          {
            title: 'Accepted',
            value: (vmrCount?.total_accepted || 0).toLocaleString(),
            cardBgColor: '#0079D016',
            icon: <FaCarAlt color='#002ED0' />,
            iconCardColor: '#002DE016',
            tag: 'accepted',
          },
          {
            title: 'Ongoing',
            value: (vmrCount?.total_ongoing || 0).toLocaleString(),
            icon: <FaCarAlt color='#00D2A8' />,
            cardBgColor: '#00D0A816',
            iconCardColor: '#00D0A816',
            tag: 'ongoing',
          },
          {
            title: 'Operations Time Set',
            value: (vmrCount?.total_repair_time_set || 0).toLocaleString(),
            cardBgColor: '#0079D016',
            icon: <FaCarAlt color='#002ED0' />,
            iconCardColor: '#002DE016',
            tag: 'repair_time_set',
          },
          {
            title: 'Completed',
            value: (vmrCount?.total_completed || 0).toLocaleString(),
            cardBgColor: '#00D0A816',
            icon: <FaCarAlt color='#00D2A8' />,
            iconCardColor: '#00D0A816',
            tag: 'completed',
          },
          {
            title: 'Rejected',
            value: (vmrCount?.total_rejected || 0).toLocaleString(),
            cardBgColor: '#FF373916',
            icon: <FaCarAlt color='#FF3739' />,
            iconCardColor: '#FF373933',
            tag: 'rejected',
          },

          {
            title: 'Awaiting Approval',
            value: (vmrCount?.total_awaiting_approval || 0).toLocaleString(),
            cardBgColor: '#6212B116',
            icon: <FaCarAlt color='#6212B1' />,
            iconCardColor: '#6212B116',
            caretColor: '#6212B1',
            tag: 'awaiting-approval',
          },
          {
            title: 'Approved',
            value: (vmrCount?.total_approved || 0).toLocaleString(),
            cardBgColor: '#6212B116',
            icon: <FaCarAlt color='#6212B1' />,
            iconCardColor: '#6212B116',
            caretColor: '#6212B1',
            tag: 'approved',
          },
        ]
      : [
          {
            title: 'Total Requests',
            value: vmrReqValue('').toLocaleString(),
            cardBgColor: '#0079D016',
            icon: <FaCarAlt color='#002ED0' />,
            iconCardColor: '#002DE016',
            tag: 'total',
          },
          {
            title: 'Maintenance',
            value: vmrReqValue('maintenance').toLocaleString(),
            icon: <FaCarAlt color='#00D2A8' />,
            cardBgColor: '#00D0A816',
            iconCardColor: '#00D0A816',
            tag: 'maintenance',
          },
          {
            title: 'Repairs',
            value: vmrReqValue('repair').toLocaleString(),
            cardBgColor: '#0079D016',
            icon: <FaCarAlt color='#002ED0' />,
            iconCardColor: '#002DE016',
            tag: 'repair',
          },
          {
            title: 'Defect',
            value: vmrReqValue('defect').toLocaleString(),
            cardBgColor: '#00D0A816',
            icon: <FaCarAlt color='#00D2A8' />,
            iconCardColor: '#00D0A816',
            tag: 'defect',
          },
        ];
  return (
    <Row>
      {items.map((item) => (
        <Col key={item.title} md={3} className='mt-4'>
          <div
            className='rounded py-3 ps-3 d-flex align-items-center justify-content-between cursor-pointer'
            style={{ backgroundColor: item.cardBgColor, height: 92 }}
            onClick={() => {
              setVmrType(item.tag);
              setActiveTab("Vmr's Log");
            }}
          >
            <div className='d-flex align-items-center'>
              <div
                className='p-2 rounded'
                style={{ background: item.iconCardColor }}
              >
                {item.icon}
              </div>
              <div className='ms-3'>
                <div style={{ color: '#71939C', fontSize: 15 }}>
                  {' '}
                  {item.title}
                </div>
                <h5 className='text-blue-bleck fw-bold mt-2'>{item.value}</h5>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default OverviewSumaryCards;
