import { Dispatch, SetStateAction, useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";

type Props = {
  keyword: string;
  setKeyword: Dispatch<SetStateAction<string>>;
};
let debounceTimer: any;
const SeearchComponent = ({ keyword, setKeyword }: Props) => {
  const [inputValue, setInputValue] = useState(keyword);
  // Function to handle input change (debounced)
  const handleInputChange = (event: any) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    debounceSearch(newValue); // Call the debounced function
  };

  // Debounced function
  const debounceSearch = (value: any) => {
    clearTimeout(debounceTimer); // Clear previous timer
    debounceTimer = setTimeout(() => {
      setKeyword(value);
    }, 1000); // Debounce time (in milliseconds)
  };

  return (
    <div>
      <InputGroup className="text-nowrap w-100 rounded py-1" style={{ background: "#F5FBFD" }}>
        <InputGroupText className={`border-0 bg-transparent`}>
          <img alt="icon" src="/svgs/search.svg" />
        </InputGroupText>
        <Input className="border-0 fs-6 bg-transparent ps-0 focus-no-outline" type="text" value={inputValue} placeholder={"Search"} onChange={handleInputChange} />
      </InputGroup>
    </div>
  );
};

export default SeearchComponent;
