import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./assets/scss/custom.scss";
import "./assets/scss/index.scss";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);