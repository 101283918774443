import { useState } from "react";
import { useNavigate } from "react-router";
import CusCard from "../../../components/shared/cusCard";
import { useQueryStringHelper } from "../../../helpers/queryStringHelper";
import NotificationOverview from "./notificationOverview";
import NotiificationLog from "./notiificationLog";

// import TripsPaymentOverview from "./tripsPaymentOverview";
// import TripsPaymentLog from "./tripsPaymentLog";

const Notifications = () => {
  const { getIdFromQueryString } = useQueryStringHelper();
  const tabs = ["Overview", "Notifications Log"];
  const [promoType, setPromoType] = useState("total");
  const [activeId, setActiveId] = useState("");
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate();

  return (
    <CusCard className="p-0" style={{ minHeight: "70vh" }}>
      <div className="d-flex">
        {tabs.map((item) => (
          <div
            className={`px-3 bg-none cursor-pointer ${activeTab === item && "gradient-btn text-white fw-bold"} ${item === "Profile" && activeTab !== "Profile" && "d-none"}`}
            key={item}
            style={{ paddingTop: "11px", paddingBottom: "11px" }}
            onClick={() => {
              item !== "Profile" && navigate("/notification");
              setActiveTab(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="p-3 p-sticky" style={{ top: 0 }}>
        {activeTab === "Overview" && (
          <div>
            <NotificationOverview setActiveTab={setActiveTab} setPromoType={setPromoType} />
          </div>
        )}
        {activeTab === "Notifications Log" && <NotiificationLog setActiveTab={setActiveTab} activeTab={activeTab} setPromoType={setPromoType} promoType={promoType} />}
      </div>
    </CusCard>
  );
};

export default Notifications;
