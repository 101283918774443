import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CusCard from "../../../components/shared/cusCard";
import { useQueryStringHelper } from "../../../helpers/queryStringHelper";
import SchedulesLog from "./schedulesLog";
import SchedulesOverview from "./schedulesOverview";
import { getTodayDate } from "../../../helpers/dateTimeHelper";

// import TripsPaymentOverview from "./tripsPaymentOverview";
// import TripsPaymentLog from "./tripsPaymentLog";

const Schedules = () => {
  const { getQueryStringValue } = useQueryStringHelper();
  const tabs = ["Overview", "Schedules Log"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (getQueryStringValue("schedule-id")) setActiveTab("Schedules Log");
  }, []);

  const setDate = (clear: boolean) => {
    if (clear) {
      setStartDate("");
      setEndDate("");
    } else {
      setStartDate(getTodayDate());
      setEndDate(getTodayDate());
    }
  };

  return (
    <CusCard className="p-0" style={{ minHeight: "70vh" }}>
      <div className="d-flex">
        {tabs.map((item) => (
          <div
            className={`px-3 bg-none cursor-pointer ${activeTab === item && "gradient-btn text-white fw-bold"} ${item === "Profile" && activeTab !== "Profile" && "d-none"}`}
            key={item}
            style={{ paddingTop: "11px", paddingBottom: "11px" }}
            onClick={() => {
              item !== "Schedules Log" && navigate("/schedules");
              setActiveTab(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="p-3 p-sticky" style={{ top: 0 }}>
        {activeTab === "Overview" && (
          <div>
            <SchedulesOverview setActiveTab={setActiveTab} setDate={setDate} setStatus={setStatus} />
          </div>
        )}
        {activeTab === "Schedules Log" && <SchedulesLog status={status} startDate={startDate} endDate={endDate} setActiveTab={setActiveTab} />}
      </div>
    </CusCard>
  );
};

export default Schedules;
