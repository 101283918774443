import { Dispatch, SetStateAction, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useQuery } from "react-query";
import queryKeys from "../../../../../../../constants/queryKeys";
import { useQueryStringHelper } from "../../../../../../../helpers/queryStringHelper";
import { useSchedulesApiService } from "../../../../../../../services/schedulesApiServices";
import { useTripsApiService } from "../../../../../../../services/tripsApiService";
import { ObjectType } from "../../../../../../../types/common";
import DestinationUpdate from "./driverDetailsCard/destinationUpdate";
import DriverAndVehicle from "./driverDetailsCard/driverAndVehicle";
import LocationAndDistanceDetails from "./driverDetailsCard/locationAndDistanceDetails";
import RiderTripDetail from "./driverDetailsCard/riderTripDetails";
import Time from "./driverDetailsCard/time";
import TripFare from "./driverDetailsCard/tripFare";
import TripRiderDetails from "./driverDetailsCard/tripRiderDetails";

type Props = {
  tripId: string;
  // viewedTrip: ObjectType | null;
  setTripId: Dispatch<SetStateAction<string>>;
  scheduleStatus?: string;
  ride_status?: string;
  fromTripsModule?: boolean;
};

const DriverTripsDetails = (props: Props) => {
  const { removeAllQueryStringsFromURL } = useQueryStringHelper();

  const { tripId, setTripId, scheduleStatus, ride_status, fromTripsModule } = props;
  const [focusedUser, setFocusedUser] = useState<ObjectType | null>(null);
  const { getTripsDetailsToast } = useTripsApiService();
  const { getSchedulesDetailsToast } = useSchedulesApiService();

  // { label: "Pending", value: "0" },
  // { label: "Fullfiled", value: "1" },
  // { label: "Unfullfiled", value: "2" },
  // { label: "Assigned", value: "3" },
  // { label: "Unassigned", value: "4" },

  const callSchedulesAPI: boolean = scheduleStatus && scheduleStatus.toString() != "1" ? true : false;

  const {
    isLoading,
    // isError,
    // error,
    data: tripDetails,
    // refetch: refetchTripsCount,
  } = useQuery(
    [queryKeys.tripsDetails],
    () =>
      getTripsDetailsToast({
        trip_id: tripId,
        ride_status,
        // getQueryStringValue("trip-id")
      }),
    {
      // select: (data) => data?.data[0]?.total || 0,
      select: (data) => data?.data[0],
      keepPreviousData: true,
    }
  );

  const {
    isLoading: schedulesLoading,
    // isError,
    // error,
    data: scheduleObject,
    // refetch: refetchTripsCount,
  } = useQuery(
    [queryKeys.schedulesDetails],
    () =>
      getSchedulesDetailsToast({
        schedules_id: tripId,
        status: scheduleStatus,
        // getQueryStringValue("trip-id")
      }),
    {
      // select: (data) => data?.data[0]?.total || 0,
      select: (data) => data?.data[0],
      keepPreviousData: true,
      enabled: callSchedulesAPI,
    }
  );

  const viewedTrip = !callSchedulesAPI ? tripDetails : scheduleObject;
  const focusedUserObject = viewedTrip?.riders?.find((item: ObjectType) => item.rider_id === focusedUser);

  // const focusedUserHasProperty = (key: string) => focusedUserObject.;

  return (
    <div>
      <div className="d-flex justify-content-between text-nowrap flex-wrap">
        <div className="d-flex align-items-center">
          {/* <div className="fw-bold">{getRiderType(riderType)} Riders</div> */}
          <div>
            {focusedUser ? (
              <div className="d-flex align-items-center  ">
                <BsArrowLeft size={30} className="" onClick={() => setFocusedUser(null)} />
                <div className="fw-bolder ms-3 fs-5">{focusedUserObject?.name}</div>
              </div>
            ) : (
              <div className="d-flex align-items-center  ">
                <BsArrowLeft
                  size={30}
                  className=""
                  onClick={() => {
                    setTripId("");
                    fromTripsModule && removeAllQueryStringsFromURL();
                  }}
                />
                <div className="fw-bolder ms-3 fs-5">{scheduleStatus && "Schedule"} Trip Details</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-3">
        <RiderTripDetail viewedTrip={viewedTrip} focusedUser={focusedUser} callSchedulesAPI={callSchedulesAPI} />
      </div>
      {!focusedUser && (
        <>
          <div className="mt-3">{viewedTrip?.driver_data && <DriverAndVehicle viewedTrip={viewedTrip} callSchedulesAPI={callSchedulesAPI} />}</div>

          <div className="">
            <TripRiderDetails viewedTrip={viewedTrip} focusedUser={focusedUser} setfocusedUser={setFocusedUser} />
          </div>
        </>
      )}

      {focusedUser && (
        <>
          <div className="mt-3">
            <TripFare focusedUser={focusedUser} viewedTrip={viewedTrip} />
          </div>

          <div className="mt-3">{!callSchedulesAPI && <Time viewedTrip={viewedTrip} focusedUser={focusedUser} />}</div>
          <div className="mt-3">
            <LocationAndDistanceDetails focusedUser={focusedUser} viewedTrip={viewedTrip} />
          </div>

          {(focusedUserObject?.previous_destination || focusedUserObject?.cancel_reason) && (
            <div className="mt-3">
              <DestinationUpdate focusedUser={focusedUser} viewedTrip={viewedTrip} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DriverTripsDetails;
