import { BsGridFill } from "react-icons/bs";
import { FaCarAlt } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { IoVolumeMedium } from "react-icons/io5";
import { financeLink } from "../sidebarRoutes/financeLinks";
import { operationsLinks } from "../sidebarRoutes/operationsLinks";
import { setupLinks } from "../sidebarRoutes/setupLinks";
import { usersLink } from "../sidebarRoutes/usersLinks";
import { BiSolidEditAlt } from "react-icons/bi";
// import { performanceLinks } from "../sidebarRoutes/performanceLinks";

const UseMenuItems = () => {
  const list = [
    { title: "Dashboard", icon: <BsGridFill size={20} />, link: "/" },
    setupLinks,
    usersLink,
    { title: "Vehicle", icon: <FaCarAlt size={20} />, link: "/vehicle" },
    {
      title: "All Trips",
      icon: <img alt="icon" src="/svgs/icons/tripIcon.svg" />,
      link: "/trips",
    },
    {
      title: "Trips Payment",
      icon: <img alt="icon" src="/svgs/icons/trips-payment.svg" />,
      link: "/trips-payment",
    },
    {
      title: "Schedules",
      icon: <FaCarAlt size={20} />,
      link: "/schedules",
    },
    financeLink,
    operationsLinks,

    {
      title: "VMR",
      icon: <img alt="icon" src="/svgs/icons/vmr_svg.svg" />,
      link: "/vmr",
    },
    {
      title: "Referrals",
      icon: <img alt="icon" src="/svgs/Referals.svg" />,
      link: "/referrals",
    },
    {
      title: "Promo",
      icon: <IoVolumeMedium size={20} />,
      link: "/promo",
    },
    {
      title: "Emergency",
      icon: <img alt="icon" src="/svgs/icons/emergency.svg" />,
      link: "/emergency",
    },
    {
      title: "Notification",
      icon: <IoMdNotifications size={20} />,
      link: "/notification",
    },
    {
      title: "Activity Log",
      icon: <BiSolidEditAlt size={20} />,
      link: "/activity-log",
    },

    // performanceLinks,
    // oldFinanceLinks,
  ];

  return list;
};

export default UseMenuItems;
