type Props = {
  item: any[];
  currentPage: number;
  totalCount: number;
};


const TableDataCounter = (props: Props) => {
  const { item, currentPage, totalCount } = props;
  return (
    <div>
      <span className="fw-bold">
        <span className="text-muted fw-normal">Displaying</span> {(currentPage - 1) * 50 + 1} - {(currentPage - 1) * 50 + item?.length}
        <span className="text-muted fw-normal"> out of </span> {totalCount?.toLocaleString()}
      </span>
    </div>
  );
};
export default TableDataCounter;
